import { initializeApp, FirebaseApp, FirebaseError } from "firebase/app";
import { initializeFirestore, FirestoreSettings } from "firebase/firestore";
import { getAnalytics, isSupported } from "firebase/analytics";

import axios from "axios";
import axiosRetry from "axios-retry";

import Vue from "vue";
import { getAuth } from "firebase/auth";

const retryDelay = (retryNumber = 0) => {
  const minDelayMs = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return minDelayMs + randomMs;
};

axiosRetry(axios, { retries: 2, retryDelay });

/**
 * Initiates the default Firebase client.
 * This should connect to the Firebase client in either:
 * chat-central-dev, chat-central-acc or chat-central-prod
 */

type FirebaseConfig = {
  apiKey: string;
  appId: string;
  authDomain: string;
  measurementId: string;
  messagingSenderId: string;
  projectId: string;
  storageBucket: string;
};

type FirebaseTenant = {
  tenantId: string;
};

const endpoint = process.env.VUE_APP_API_BASE_ENDPOINT;

export async function initDefaultFirebaseApp(): Promise<FirebaseApp> {
  const defaultFirebaseEndpoint = `${endpoint}/firebase/default`;
  const configResponse = await axios.get<FirebaseConfig>(defaultFirebaseEndpoint);

  const tenantFirebaseEndpoint = `${endpoint}/firebase/tenant/${window.location.host}`;
  const tenantResponse = await axios.get<FirebaseTenant>(tenantFirebaseEndpoint);

  const firebaseApp = initializeApp(configResponse.data);
  getAuth(firebaseApp).tenantId = tenantResponse.data?.tenantId || null;

  if (process.env.VUE_APP_E2E_WINDOW_STORE === "true") {
    (window as any).__auth__ = getAuth(firebaseApp);
  }

  if (isSupported()) {
    Vue.prototype.$analytics = getAnalytics();
  }
  const firestoreSettings: FirestoreSettings = { experimentalForceLongPolling: true };
  initializeFirestore(firebaseApp, firestoreSettings);

  return firebaseApp;
}

export function isFirebaseError(arg: unknown): arg is FirebaseError {
  return arg !== null && typeof arg === "object" && typeof (arg as FirebaseError).code === "string";
}
