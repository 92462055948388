import { MessageDocument } from "@/types";
import { DocumentData } from "firebase/firestore";

/**
 * Returns a Message Document with the content/attachment field set to the modified or masked content
 * if the abuseModification or maskedContent field is set.
 *
 * @param  {string} id
 * @param  {DocumentData} documentData
 *
 * @returns MessageDocument
 */
export function getMaskedMessageDocument(id: string, documentData: DocumentData): MessageDocument {
  const document = {
    ...documentData,
    id,
  } as MessageDocument;

  const abuseModificationData =
    documentData.abuse_modification_data && JSON.parse(documentData.abuse_modification_data);
  const isModified =
    abuseModificationData?.modified_content != null || abuseModificationData?.modified_attachment != null;
  const content = isModified
    ? abuseModificationData.modified_content
    : documentData.maskedContent ?? documentData.content;
  const attachment = isModified ? abuseModificationData.modified_attachment : documentData.attachment;

  return {
    ...document,
    content,
    attachment,
  };
}
