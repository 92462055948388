import { render, staticRenderFns } from "./NotificationSnackbar.vue?vue&type=template&id=3cc1f56a&scoped=true&"
import script from "./NotificationSnackbar.vue?vue&type=script&setup=true&lang=ts&"
export * from "./NotificationSnackbar.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc1f56a",
  null
  
)

export default component.exports