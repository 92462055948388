import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import axios from "axios";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_ENDPOINT,
});
http.interceptors.request.use(async (config: any) => {
  // If request is different than any of the URLS in urlsExcludedForBearerHeader
  // then send Authorization header with token from firebase
  const urlsExcludedForBearerHeader = ["/login", "/firebase/projects/central", /^\/tenant\/.*\/info$/];
  const isBearerExcluded = urlsExcludedForBearerHeader.some((url) => config.url.match(url));
  if (!isBearerExcluded) {
    const firebaseApp = getApp();
    config.headers.Authorization = `Bearer ${await getAuth(firebaseApp).currentUser.getIdToken()}`;
  }
  return config;
});

export default http;
