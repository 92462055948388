export function rangeCMToFeet(cm: string): string {
  if (!cm) return '';
  const splitRange = cm.split("-");

  const cmAsNumberFirst = splitRange[0];
  const cmAsNumberSecond = splitRange[1];

  return `${cmToFeet(cmAsNumberFirst)} - ${cmToFeet(cmAsNumberSecond)} - ${cm} cm`
}

export function cmToFeet(cm: any): string {
  const cmAsNumber: number = typeof cm === "string" ? parseInt(cm) : cm;
  if (!cmAsNumber) {
    return "";
  }

  const realFeet = (cmAsNumber * 0.393700787) / 12.0;
  const feet = Math.floor(realFeet);
  const inches = Math.round((realFeet - feet) * 12);
  return `${feet}ft ${inches ? inches+'in' : ''}`
}
