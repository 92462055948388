import Vue from "vue";
import VueRouter, { RouteConfig, NavigationGuard } from "vue-router";
type Next = Parameters<NavigationGuard>[2];

import LoginLayout from "@/views/layouts/LoginLayout.vue";
import MainLayout from "@/views/layouts/MainLayout.vue";
import RoleAccess from "@/plugins/RoleAccess";
import { User } from "firebase/auth";
import VueMeta from "vue-meta";
import { Roles } from "@/types";
import pinia from "@/stores";
import { useAuthenticatorStore } from "@/stores/authenticator";

if (!process || process.env.NODE_ENV !== "test") {
  // to allow mock $router in tests
  Vue.use(VueRouter);
}
Vue.use(VueMeta);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    beforeEnter: (_to, _from, next) => {
      redirectToHomePath(next);
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: LoginLayout },
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/mfa",
    name: "Multi-Factor Authentication",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
    },
    component: () => import("@/views/MultiFactorAuthentication.vue"),
  },
  {
    path: "/totp",
    name: "TOTP Authentication",
    meta: {
      layout: LoginLayout,
    },
    component: () => import("@/views/google-totp/TotpLogin.vue"),
  },
  {
    path: "/totp-register",
    name: "Register TOTP",
    meta: {
      layout: LoginLayout,
      requiresAuth: true,
    },
    component: () => import("@/views/google-totp/TotpRegister.vue"),
  },
  {
    path: "/totp-settle-down",
    name: "Totp Settle Down",
    meta: {
      layout: LoginLayout,
      requiresAuth: true,
    },
    component: () => import("@/views/google-totp/TotpSettleDown.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Admin, Roles.Poker],
    },
    component: () => import("@/views/Calendar.vue"),
  },
  {
    path: "/chat",
    name: "Chat",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
    },
    component: () => import("@/views/Chat.vue"),
    redirect: RoleAccess.hasRole(Roles.Admin) ? "/chat/list" : "/chat/lobby",
    children: [
      {
        path: "lobby",
        name: "Chat Lobby",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
        },
        component: () => import("@/views/chat/ChatLobby.vue"),
      },
      {
        path: "list",
        name: "Unclaimed Chats List",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/chat/UnclaimedList.vue"),
      },
      {
        path: "claimed",
        name: "Claimed Chat",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
        },
        component: () => import("@/views/chat/ClaimedChat.vue"),
      },
    ],
  },
  {
    path: "/pokes",
    name: "pokes",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Admin, Roles.Poker],
    },
    component: () => import("@/views/Poke.vue"),
    redirect: RoleAccess.hasRole(Roles.Admin) ? "/pokes/schedule" : "/pokes/new",
    children: [
      {
        path: "schedule",
        name: "schedule",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Poker],
        },
        component: () => import("@/views/poke/Schedule.vue"),
      },
      {
        path: "new",
        name: "new-poke",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Poker],
        },
        component: () => import("@/views/poke/Poke.vue"),
      },
      {
        path: "queue",
        name: "queue-poke",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/poke/Queue.vue"),
      },
      {
        path: ":geo/:pokeId",
        name: "show-poke",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Poker],
        },
        component: () => import("@/views/poke/Schedule.vue"),
      },
      {
        path: ":geo/:pokeId/edit",
        name: "edit-poke",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Poker],
        },
        component: () => import("@/views/poke/Poke.vue"),
      },
    ],
  },
  {
    path: "/announcements",
    name: "Announcements edit list",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Admin, Roles.Manager],
    },
    component: () => import("@/views/AnnouncementsEditList.vue"),
  },
  {
    path: "/statistics",
    name: "statistics",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Admin, Roles.Manager, Roles.Poker, Roles.AbuseChecker],
    },
    component: () => import("@/views/Statistics.vue"),
    redirect: RoleAccess.hasRole(Roles.Manager)
      ? "/statistics/chat"
      : RoleAccess.hasRole(Roles.Poker)
      ? "/statistics/pokers"
      : RoleAccess.hasRole(Roles.AbuseChecker)
      ? "/statistics/live-conversations"
      : "/statistics/operators",
    children: [
      {
        path: "operators",
        name: "statistics-operators",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/statistics/Operators.vue"),
      },
      {
        path: "queues",
        name: "statistics-queues",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Manager],
        },
        component: () => import("@/views/statistics/Queues.vue"),
      },
      {
        path: "scores",
        name: "statistics-scores",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/statistics/OperatorScores.vue"),
      },
      {
        path: "messages",
        name: "statistics-messages",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Manager],
        },
        component: () => import("@/views/statistics/Messages.vue"),
      },
      {
        path: "chat",
        name: "statistics-chat-quality",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Manager],
        },
        component: () => import("@/views/statistics/ChatInsights.vue"),
        children: [
          {
            path: ":geo/:id",
            name: "statistics-chat-quality-details",
            meta: {
              layout: MainLayout,
              requiresAuth: true,
              requiresRole: [Roles.Admin, Roles.Manager],
            },
            component: () => import("@/views/statistics/ChatInsightDetail.vue"),
          },
        ],
      },
      {
        path: "live-conversations",
        name: "statistics-live-conversation",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Manager, Roles.AbuseChecker],
        },
        component: () => import("@/views/statistics/LiveConversations.vue"),
        children: [
          {
            path: ":geo/:id",
            name: "statistics-live-conversations-details",
            meta: {
              layout: MainLayout,
              requiresAuth: true,
              requiresRole: [Roles.Admin, Roles.Manager, Roles.AbuseChecker],
            },
            component: () => import("@/views/statistics/ChatInsightDetail.vue"),
          },
        ],
      },
      {
        path: "pokes",
        name: "statistics-poke-quality",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/statistics/PokeInsights.vue"),
      },
      {
        path: "pokers",
        name: "statistics-poker-quality",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Manager, Roles.Poker],
        },
        component: () => import("@/views/statistics/PokerInsights.vue"),
      },
    ],
  },
  {
    path: "/personal-stats",
    name: "personalStatistics",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Admin, Roles.Operator],
    },
    component: () => import("@/views/PersonalStatistics.vue"),
  },
  {
    path: "/personal-settings",
    name: "personalSettings",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
    },
    component: () => import("@/views/PersonalSettings.vue"),
  },
  {
    path: "/abuse",
    redirect: RoleAccess.hasRole(Roles.AbuseChecker)
      ? "/abuse/check"
      : RoleAccess.hasRole(Roles.Manager)
      ? "/abuse/reported-messages"
      : "/abuse/list",
    name: "Abuse",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Admin, Roles.AbuseChecker, Roles.Manager],
    },
    component: () => import("@/views/Abuse.vue"),
    children: [
      {
        path: "check",
        name: "abuse-message-check",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.AbuseChecker, Roles.Developer],
        },
        component: () => import("@/views/abuse/AbuseMessageCheck.vue"),
      },
      {
        path: "list",
        name: "abuse-list",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/abuse/AbuseList.vue"),
      },
      {
        path: "words",
        name: "abuse-words-list",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/abuse/AbuseWordsList.vue"),
      },
      {
        path: "history",
        name: "abuse-messages-history",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/abuse/AbuseMessagesHistory.vue"),
      },
      {
        path: "remediation",
        name: "abuse-remediation",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin],
        },
        component: () => import("@/views/abuse/AbuseRemediation.vue"),
      },
      {
        path: "reported-messages",
        name: "reported-messages",
        meta: {
          layout: MainLayout,
          requiresAuth: true,
          requiresRole: [Roles.Admin, Roles.Manager],
        },
        component: () => import("@/views/abuse/ReportedMessages.vue"),
        children: [
          {
            path: ":geo/:id",
            name: "abuse-reported-messages-details",
            meta: {
              layout: MainLayout,
              requiresAuth: true,
              requiresRole: [Roles.Admin, Roles.Manager],
            },
            component: () => import("@/views/statistics/ChatInsightDetail.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/trigger",
    name: "Triggers",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Admin],
    },
    component: () => import("@/views/Trigger.vue"),
  },
  {
    path: "/debug",
    name: "Debug",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Developer],
    },
    component: () => import("@/views/Debug.vue"),
  },
  {
    path: "/uikit",
    name: "UIKit",
    meta: {
      layout: MainLayout,
      requiresAuth: true,
      requiresRole: [Roles.Developer],
    },
    component: () => import("@/views/UIKit.vue"),
    children: [
      {
        path: "menu",
        name: "Main Menu UI kit",
        component: () => import("@/views/uikit/MainMenu.vue"),
      },
      {
        path: "poke",
        name: "Poke UI kit",
        component: () => import("@/views/uikit/PokeResult.vue"),
      },
      {
        path: "avatars",
        name: "Default profile avatars",
        component: () => import("@/views/uikit/ProfileAvatars.vue"),
      },
      {
        path: "icons",
        name: "All icons",
        component: () => import("@/views/uikit/ProfileIcons.vue"),
      },
      {
        path: "announcements",
        name: "Announcements for operators",
        component: () => import("@/views/uikit/Announcements.vue"),
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "unclaimed",
        name: "Unclaimed chats list",
        component: () => import("@/views/uikit/UnclaimedChats.vue"),
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "chat",
        name: "Chat page",
        component: () => import("@/views/uikit/Chat.vue"),
        meta: {
          layout: MainLayout,
        },
      },
      {
        path: "error",
        name: "Error reporting",
        component: () => import("@/views/uikit/ErrorReporting.vue"),
        meta: {
          layout: MainLayout,
        },
      },
    ],
  },
  {
    path: "*",
    name: "NotFound",
    meta: { layout: MainLayout },
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function redirectToHomePath(next: Next): void {
  const authStore = useAuthenticatorStore(pinia);

  if (!authStore.isUserSessionAuthorized) {
    next("/login");
    return;
  }

  if (RoleAccess.hasRole(Roles.Admin)) {
    next("/chat");
    return;
  }

  if (RoleAccess.hasRole(Roles.Poker)) {
    next("/pokes");
    return;
  }

  if (RoleAccess.hasRole(Roles.Manager)) {
    next("/statistics");
    return;
  }

  if (RoleAccess.hasRole(Roles.Operator)) {
    next("/chat/lobby");
    return;
  }

  if (RoleAccess.hasRole(Roles.AbuseChecker)) {
    next("/abuse/check");
    return;
  }

  next();
}

// Change the favicon based on the route metadata
const handleFaviconChange = (faviconPath = "/favicons/favicon.ico") => {
  const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.setAttribute("href", faviconPath);
};

router.beforeEach(async (to, from, next) => {
  handleFaviconChange(to.meta.favicon);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresRole = to.meta.requiresRole;
  const authStore = useAuthenticatorStore(pinia);

  const currentUser: User = await authStore.getCurrentUser();
  const forceMfa = authStore.forceMfa;
  const hasMfaEnabled = authStore.hasMfaEnabled;

  if (to.path === "/") {
    redirectToHomePath(next);
    return;
  }

  if (!requiresAuth) {
    next();
    return;
  }

  if (!currentUser) {
    const noRedirectPaths = ["/totp", "/totp-register"];
    const redirect = noRedirectPaths.includes(to.path) ? {} : { redirect: to.fullPath };
    next({ path: "/login", query: redirect });
    return;
  }

  const isTotpEnabled = authStore.user.claims?.firebase.sign_in_second_factor === "totp";
  const isForcedTotp = authStore.user.claims?.forcedTotp === true;
  if (!isTotpEnabled && isForcedTotp) {
    return to.path === "/totp-register" ? next() : next("/totp-register");
  }

  if (to.path === "/login") {
    const { redirect } = to.query;
    const redirectPath = (redirect as string) || "/";
    next(redirectPath);
    return;
  }

  if (!hasMfaEnabled && forceMfa && !isForcedTotp && to.path !== "/mfa") {
    next("/mfa");
    return;
  }

  if (requiresRole && !RoleAccess.hasAnyRole(requiresRole)) {
    next("/");
    return;
  }

  next();
});

export default router;
