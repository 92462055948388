import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "@/router";
import { PiniaVuePlugin } from "pinia";
import pinia from "@/stores";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useSocketStore } from "./stores/socket";
import vuetify from "@/plugins/vuetify";
import Hotjar from "vue-hotjar";
import { HOTJAR_ID } from "@/types/constants";

import Flag from "vue-flagpack";

import "@/plugins/firebase_plugin";
import makePlural from "@/plugins/makePlural";
import "@/directives";

import Gravatar from "vue-gravatar";
import Lightbox from "vue-easy-lightbox";
import VueSanitize from "vue-sanitize";

import { initDefaultFirebaseApp } from "@/plugins/firebase_plugin";

const connectStoreToWindow = process.env.VUE_APP_E2E_WINDOW_STORE === "true";
const devToolsEnabled = process.env.VUE_APP_DEVTOOLS_ENABLED === "true";
const hotjarEnabled = process.env.VUE_APP_HOTJAR_ENABLED === "true";

Vue.config.productionTip = false;
Vue.config.devtools = devToolsEnabled;

Vue.use(Lightbox);

Vue.use(Flag, {
  name: "Flag",
});

Vue.use(Hotjar, {
  id: HOTJAR_ID,
  isProduction: hotjarEnabled,
  snippetVersion: 6,
});

Vue.component("v-gravatar", Gravatar);
Vue.use(makePlural);
Vue.use(VueSanitize);
Vue.use(PiniaVuePlugin);

const authStore = useAuthenticatorStore(pinia);

if (!process || process.env.NODE_ENV !== "test") {
  // to allow mock $router in tests
  Vue.use(VueRouter);
}

async function initApp() {
  useSocketStore().bindEvents();
  await initDefaultFirebaseApp();
  await authStore.authenticate();
  new Vue({ router, vuetify, pinia, render: (h) => h(App) }).$mount("#app");
}

if (connectStoreToWindow) {
  // @ts-ignore: for E2E tests
  window.__pinia__ = pinia;
}

initApp();
