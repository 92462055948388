import vuetifyColors from "vuetify/lib/util/colors";
import { PokeDocument } from "@/types";
import {
  ABUSE_STATUS_SAFE,
  ABUSE_STATUS_PENDING,
  ABUSE_STATUS_ABUSE,
  ABUSE_STATUS_EXPIRED,
  CUSTOMER_SENDER_TYPE,
} from "@/types/constants";

export function colorBySenderType(senderType: string): string {
  return senderType === CUSTOMER_SENDER_TYPE ? "blue darken-2" : "amber accent-4";
}

export function colorClassesBySenderType(senderType: string): string {
  return senderType === CUSTOMER_SENDER_TYPE ? "blue--text text--darken-2" : "amber--text text--accent-4";
}

export function borderStyleContentBySenderType(senderType: "customer" | "agent"): string {
  return `border-left: 2px solid ${
    senderType === CUSTOMER_SENDER_TYPE ? vuetifyColors.blue.darken2 : vuetifyColors.amber.darken4
  }`;
}

export function getAbuseStatusColor(poke: PokeDocument): string {
  return {
    [ABUSE_STATUS_SAFE]: "green",
    [ABUSE_STATUS_PENDING]: "blue",
    [ABUSE_STATUS_ABUSE]: "red",
    [ABUSE_STATUS_EXPIRED]: "grey",
  }[poke.abuse?.status || ABUSE_STATUS_SAFE];
}
