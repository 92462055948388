import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import { debounce } from "ts-debounce";
import { PiniaDebounce } from "@pinia/plugin-debounce";
import { useAbuseStore } from "./abuse";
import { useAnnouncementsStore } from "./announcements";
import { useAttachmentsStore } from "./attachments";
import { useAuthenticatorStore } from "./authenticator";
import { useChatStore } from "./chat";
import { useConfigStore } from "./config";
import { useDebugStore } from "./debug";
import { useGeoStore } from "./geo";
import { useNotificationStore } from "./notifications";
import { usePokeQueueStore } from "./pokeQueue";
import { usePokeScheduleStore } from "./pokeSchedule";
import { usePokeWizardStore } from "./pokeWizard";
import { useProfileStore } from "./profile";
import { useReportedMessages } from "./reportedMessages";
import { useSettingsStore } from "./settings";
import { useSocketStore } from "./socket";
import { useStatisticsStore } from "./statistics";
import { useSystemStore } from "./system";

Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(PiniaDebounce(debounce));

export default pinia;

export function resetAllStores() {
  [
    useAbuseStore,
    useAnnouncementsStore,
    useAttachmentsStore,
    useAuthenticatorStore,
    useChatStore,
    useConfigStore,
    useDebugStore,
    useGeoStore,
    useNotificationStore,
    usePokeQueueStore,
    usePokeScheduleStore,
    usePokeWizardStore,
    useProfileStore,
    useReportedMessages,
    useSettingsStore,
    useSocketStore,
    useStatisticsStore,
    useSystemStore,
  ].forEach((useStore) => {
    useStore().$reset();
  });
}
