import Vue from "vue";
import { DirectiveBinding } from "vue/types/options";
import RoleAccess from "../plugins/RoleAccess";

function isValueMatchingRole(value: string): boolean {
  if (value.includes("|")) {
    return RoleAccess.hasAnyRole(value.split("|"));
  }

  return RoleAccess.hasRole(value);
}

Vue.directive("role", {
  bind: (el: HTMLElement, binding: DirectiveBinding) => {
    const value = binding.value as string;

    if (isValueMatchingRole(value)) {
      return;
    }

    el.style.setProperty("display", "none", "important");
  },
});
