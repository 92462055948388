
import { defineComponent, PropType } from "vue";
import { mapState } from "pinia";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import { ChatDocument, ConcerningType, LogCategoryName, Log, RadioItem, NotificationTypes } from "@/types";
import { colorBySenderType, colorClassesBySenderType } from "@/helpers/colors";
import { logsCategories } from "@/types/categories";
import { MAX_ALLOWED_COMMENT_SIZE, OTHER_CATEGORY, CUSTOMER_SENDER_TYPE } from "@/types/constants";
import _capitalize from "lodash/capitalize";
import debounce from "debounce";
import { useNotificationStore } from "@/stores/notifications";
import { useChatStore } from "@/stores/chat";
import { useProfileStore } from "@/stores/profile";
import { useStatisticsStore } from "@/stores/statistics";

export default defineComponent({
  name: "LogBookDialog",
  props: {
    concerning: { type: String as PropType<ConcerningType>, required: true },
    value: { type: Boolean, default: false },
    logToEdit: { type: Object as PropType<Log>, default: null },
  },
  data: function () {
    return {
      isFormValid: false,
      logsCategoriesArray: Object.keys(logsCategories).map((key) => ({ ...logsCategories[key], value: key })),
      logCategory: "",
      logGroup: OTHER_CATEGORY,
      logGroupValue: null as { group: string; title: string },
      logValue: "",
      categoryDropdownRules: [(value: string) => !!value || "You have to select a category"],
      logGroupDropdownRules: [(value: string) => !!value || "You have to select a value"],
      isSaveButtonEnabled: true,
    };
  },
  emits: ["input"],
  computed: {
    ...mapState(useChatStore, ["claimedChat"]),
    ...mapState(useStatisticsStore, ["activeChat"]),
    ...mapState(useProfileStore, ["activeCustomerProfile", "activeEntertainmentProfile"]),
    chat(): ChatDocument {
      return this.claimedChat || this.activeChat;
    },
    isEdit(): boolean {
      return !!this.logToEdit;
    },
    groupsList(): RadioItem[] {
      if (!logsCategories[this.logCategory]?.groups) {
        return [];
      }
      const OTHER_ITEM = { label: _capitalize(OTHER_CATEGORY), value: OTHER_CATEGORY };
      return [...logsCategories[this.logCategory].groups, OTHER_ITEM];
    },
    predefinedValuesTitle(): string {
      return logsCategories[this.logCategory as LogCategoryName]?.title || this.logGroup;
    },
    predefinedValues(): object[] {
      return logsCategories[this.logCategory as LogCategoryName]?.predefinedValues || [];
    },
    hasAutocompleteSelect(): boolean {
      return this.logGroup !== OTHER_CATEGORY && this.predefinedValues?.length > 0;
    },
    sideColor(): string {
      return colorBySenderType(this.concerning);
    },
    logCategoryColor(): string {
      return logsCategories[this.logCategory].color;
    },
    dialogTitle(): string {
      return ` ${this.isEdit ? "Edit" : "New"} ${
        this.concerning === CUSTOMER_SENDER_TYPE ? "customer" : "profile"
      } logbook`;
    },
    commentTextareaRules(): ((value: string) => string | boolean)[] {
      const allowedToHaveEmptyCommentCategories = ["hobbies", "sex"];
      return [
        (value: string) =>
          (allowedToHaveEmptyCommentCategories.includes(this.logCategory) && this.logGroup !== OTHER_CATEGORY) ||
          value.length > 0 ||
          "Comment should contain some information",
        (value: string) =>
          value.length <= MAX_ALLOWED_COMMENT_SIZE ||
          `Comment should contain less than ${MAX_ALLOWED_COMMENT_SIZE} characters`,
      ];
    },
  },
  watch: {
    logCategory() {
      (this.$refs.newLogbookForm as any)?.validate();
      if (!this.isEdit) {
        this.logGroup = this.groupsList[0]?.value;
      }
      this.logGroupValue = null;
    },
    logGroup() {
      (this.$refs.newLogbookForm as any)?.validate();
    },
    logGroupValue() {
      if (!this.logGroupValue) {
        return;
      }
      this.logGroup = this.logGroupValue?.group;
    },
    async logToEdit() {
      if (!this.isEdit) {
        this.resetForm();
        return;
      }

      this.logCategory = this.logToEdit.category;
      this.logGroup =
        this.groupsList.find((group) => group.value === this.logToEdit.content.group)?.value || OTHER_CATEGORY;
      await this.$nextTick();
      this.logGroupValue = { group: this.logGroup, title: this.logToEdit.content.groupValue };
      this.logValue = this.logToEdit.content?.value || "";
    },
  },
  mounted() {
    !this.isEdit && this.resetForm();
  },
  methods: {
    colorClassesBySenderType,
    cancel(): void {
      this.resetForm();
      this.$emit("input", false);
    },
    resetForm(): void {
      this.logCategory = "";
      this.logGroup = "";
      this.logGroupValue = null;
      this.logValue = "";
    },
    resetAutocompleteField(): void {
      this.logGroupValue = null;
    },
    saveLog: debounce(async function (this: any): Promise<void> {
      if (!(this.$refs.newLogbookForm as any)?.validate()) {
        return;
      }
      this.isSaveButtonEnabled = false;
      try {
        await firebaseRepoManager.dispatch("log", this.chat.locale, "saveLog", {
          chatId: this.chat.id,
          log: {
            id: this.logToEdit?.id || null,
            concerning: this.concerning,
            category: this.logCategory,
            content: {
              group: this.logGroup || null,
              groupValue: this.logGroupValue?.title || null,
              value: this.logValue.trim(),
            },
          },
        });
        this.resetForm();
        this.$emit("input", false);
      } catch (error) {
        useNotificationStore().addNotification({
          message: `Failed to save logbook entry.`,
          type: NotificationTypes.Error,
        });
      }
      this.isSaveButtonEnabled = true;
    }, 500),
  },
});
