import { defineStore } from "pinia";
import { AttachmentsState, DomainConfiguration, NotificationTypes, Profile } from "@/types";
import ProfileRepository from "@/repositories/profileRepository";
import { useNotificationStore } from "./notifications";
import { usePokeWizardStore } from "./pokeWizard";
import { areAttachmentsEqual } from "@/helpers/attachment";

export const useAttachmentsStore = defineStore("attachments", {
  state: (): AttachmentsState => ({
    attachments: [],
  }),
  getters: {},
  actions: {
    async loadAttachments(domainConfiguration: DomainConfiguration, profile: Profile) {
      try {
        const profileRepository = new ProfileRepository(domainConfiguration.domain);
        this.attachments = await profileRepository.getAttachments(profile);
      } catch (error) {
        useNotificationStore().addNotification({
          message: `Failed to retrieve attachments for profile with id: ${profile?.id}.`,
          type: NotificationTypes.Error,
        });
      }
      if (
        !this.attachments.some((attachment) =>
          areAttachmentsEqual(attachment, usePokeWizardStore().mainPokeMessage.attachment),
        )
      )
        usePokeWizardStore().resetMainAttachment();
    },
    clearAttachments() {
      this.attachments = [];
    },
  },
});
