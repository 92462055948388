
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { CUSTOMER_SENDER_TYPE, PROFILE_SENDER_TYPE } from "@/types/constants";
import LogbookPanel from "@/components/profile/LogbookPanel.vue";
import { useChatStore } from "@/stores/chat";

export default defineComponent({
  name: "ChatFloatingPanel",
  components: {
    LogbookPanel,
  },
  data() {
    return {
      CUSTOMER_SENDER_TYPE,
      PROFILE_SENDER_TYPE,
      isLogbookDialogOpen: false,
    };
  },
  computed: {
    ...mapState(useChatStore, ["countLogbooks"]),
    isSmallDevice(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isExtraSmallDevice(): boolean {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
});
