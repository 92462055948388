import { ChatDocument, NonRealtimeRepository, NotificationTypes, LatestNormalChatsPayload } from "@/types";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  query,
  collection,
  doc,
  getDocs,
  CollectionReference,
  orderBy,
  limit,
  startAfter,
  getDoc,
  where,
} from "firebase/firestore";
import { getMaskedMessageDocument } from "@/helpers/messageAbuseRemediate";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { useNotificationStore } from "@/stores/notifications";
import { useStatisticsStore } from "@/stores/statistics";
import { useChatStore } from "@/stores/chat";

const LIMIT_MESSAGES_PER_REQUEST = 15;
export class QualityRepository implements NonRealtimeRepository {
  private _chatsCollectionRef: CollectionReference<ChatDocument>;

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    this._chatsCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getChatsCollectionRef();
  }

  public async dispatch(action: "getSingleChat", params: any): Promise<void> {
    if (this[action]) {
      return this[action](params);
    }
  }

  private createMessageRef(chatId: string) {
    const docRef = doc(this._chatsCollectionRef, chatId);
    return query(collection(docRef, "messages"), orderBy("createdAt", "desc"));
  }

  public async getMessages({ chatId }: { chatId: string }) {
    const messagesQuery = query(this.createMessageRef(chatId), limit(LIMIT_MESSAGES_PER_REQUEST));

    const snapshot = await getDocs(messagesQuery);

    snapshot.docs.forEach((doc) => {
      const messageDocument = getMaskedMessageDocument(doc.id, doc.data());
      useStatisticsStore().pushChatMessage(messageDocument);
    });
  }

  public async getNextMessages({ chatId, lastMessage }: { chatId: string; lastMessage: { createdAt: any } }) {
    const nextMessageQuery = query(
      this.createMessageRef(chatId),
      startAfter(lastMessage.createdAt),
      limit(LIMIT_MESSAGES_PER_REQUEST),
    );

    const snapshot = await getDocs(nextMessageQuery);

    snapshot.docs.forEach((doc) => {
      const messageDocument = getMaskedMessageDocument(doc.id, doc.data());
      useStatisticsStore().pushChatMessage(messageDocument);
    });

    useChatStore().hasBeginningMessage = snapshot.docs.length < LIMIT_MESSAGES_PER_REQUEST;
  }

  public async getSingleChat(id: Branded<string, "ChatId">): Promise<void> {
    const chatDocument = await getDoc(doc(this._chatsCollectionRef, id));
    if (!chatDocument.exists()) {
      return;
    }
    useStatisticsStore().activeChat = { ...chatDocument.data(), id };
  }

  /**
   *
   * @param LatestNormalChatsPayload
   *
   * @returns
   */
  public async getLatestNormalChats(payload: LatestNormalChatsPayload): Promise<ChatDocument[]> {
    try {
      let q = query(
        this._chatsCollectionRef,
        orderBy("lastMessage.createdAt", "desc"),
        where("lastMessage.type", "==", "normal"),
      );

      if (payload.selectedDomains.length > 0) {
        q = query(q, where("domain", "in", payload.selectedDomains));
      }

      q = query(q, limit(payload.maxAmountOfChats));

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return [];
      }

      return snapshot.docs.map((documentSnapshot) => {
        const chatDocument = {
          id: documentSnapshot.id,
          ...documentSnapshot.data(),
        } as ChatDocument;

        chatDocument.lastMessage = getMaskedMessageDocument(chatDocument.lastMessage.id, chatDocument.lastMessage);

        return chatDocument;
      });
    } catch (error) {
      useNotificationStore().addNotification({
        message: error.toString(),
        type: NotificationTypes.Error,
      });
      return [];
    }
  }
}
