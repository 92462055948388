import { calculateAge } from "@/helpers/utility";
import { Profile } from "@/types";

const cdnUrl = process.env.VUE_APP_CDN_BASE_URL;

/**
 * Search for an attribute in the profileApiResponseObject.
 * @param profileApiResponseObject - The API response object.
 * @param attributeName - The name of the attribute to search for.
 * @returns {string} - The value of the attribute, or an empty string if not found.
 */
function findAttribute(profileApiResponseObject: any, attributeName: string): string {
  const attribute = profileApiResponseObject.attributes.find((attr: any) => attr.name === attributeName);
  return attribute ? attribute.value : "";
}

/**
 * Search for a tag category in the profileApiResponseObject.
 * @param profileApiResponseObject - The API response object.
 * @param categoryName - The name of the category to search for.
 * @returns {string | undefined} - The first tag name in the category if found, or undefined if not found.
 */
function findTagCategory(profileApiResponseObject: any, categoryName: string): string | undefined {
  const category = profileApiResponseObject.tag_categories.find((category: any) => category.name === categoryName);
  return category?.tags[0]?.name;
}

/**
 * A class for mapping profile objects to a new format.
 * Only compatible with new profile API responses.
 */
export class ProfileMapper {
  /**
   * Search for an attribute in the profileApiResponseObject.
   * @param profileApiResponseObject - The API response object.
   * @param attributeName - The name of the attribute to search for.
   * @returns {string} - The value of the attribute, or an empty string if not found.
   */
  private attribute(profileApiResponseObject: any, attributeName: string): string {
    return findAttribute(profileApiResponseObject, attributeName);
  }

  /**
   * Search for a tag category in the profileApiResponseObject.
   * @param profileApiResponseObject - The API response object.
   * @param categoryName - The name of the category to search for.
   * @returns {string | undefined} - The first tag name in the category if found, or undefined if not found.
   */
  private tagCategory(profileApiResponseObject: any, categoryName: string): string | undefined {
    return findTagCategory(profileApiResponseObject, categoryName);
  }

  /**
   * Map a profile object to a new format.
   *
   * @param profileApiResponseObject - The profile API response object to be mapped.
   * @returns {Object} - The mapped profile object.
   */
  public map(profileApiResponseObject: any): Profile {
    return {
      id: profileApiResponseObject.profile_id || "",
      uid: profileApiResponseObject.profile_id || "",
      profileCmsId: profileApiResponseObject.profile_id || "",
      uuid: profileApiResponseObject.profile_id || "",
      foreignProfileId: profileApiResponseObject.profile_id || "",
      real_name: this.attribute(profileApiResponseObject, "name"),
      is_smoker: this.tagCategory(profileApiResponseObject, "characteristics") === "smoker",
      hobbies: this.attribute(profileApiResponseObject, "hobbies"),
      has_piercing: this.tagCategory(profileApiResponseObject, "characteristics") === "piercings",
      has_tattoo: this.tagCategory(profileApiResponseObject, "characteristics") === "tattoos",
      hair_colour: this.tagCategory(profileApiResponseObject, "hair_color") || "",
      civil_status: this.tagCategory(profileApiResponseObject, "civil_status") || "",
      eye_colour: this.tagCategory(profileApiResponseObject, "eye_color") || "",
      about_me: this.attribute(profileApiResponseObject, "about_me"),
      about_you: this.attribute(profileApiResponseObject, "about_you"),
      adult_level: this.tagCategory(profileApiResponseObject, "adult_level") || "",
      age: this.attribute(profileApiResponseObject, "date_of_birth")
        ? calculateAge(this.attribute(profileApiResponseObject, "date_of_birth"))
        : 0,
      appearance: this.tagCategory(profileApiResponseObject, "appearance") || "",
      build: this.tagCategory(profileApiResponseObject, "build") || "",
      status: this.tagCategory(profileApiResponseObject, "profile_type") || "",
      gender: this.tagCategory(profileApiResponseObject, "gender") === "m" ? "male" : "female",
      metadata: {
        length: +this.tagCategory(profileApiResponseObject, "length") || 0,
        profession: this.tagCategory(profileApiResponseObject, "profession") || "",
        transportation: this.tagCategory(profileApiResponseObject, "transportation") || "",
        housing_situation: this.tagCategory(profileApiResponseObject, "housing_situation") || "",
        passion: this.tagCategory(profileApiResponseObject, "passion") || "",
        sexual_orientation: this.tagCategory(profileApiResponseObject, "sexual_orientation") || "",
      },
      sexual_orientation: this.tagCategory(profileApiResponseObject, "sexual_orientation") || "",
      transportation: this.tagCategory(profileApiResponseObject, "transportation") || "",
      passion: this.tagCategory(profileApiResponseObject, "passion") || "",
      profession: this.attribute(profileApiResponseObject, "profession") || "",
      length: this.tagCategory(profileApiResponseObject, "length") || "0",
      is_angel: !!this.tagCategory(profileApiResponseObject, "is_angel"),
      avatar: profileApiResponseObject?.avatar?.url || "",
      media: profileApiResponseObject.media || [],
      domain: this.tagCategory(profileApiResponseObject, "site") || "",
      geo_iso: this.attribute(profileApiResponseObject, "geo_iso"),
      geo_latitude: this.attribute(profileApiResponseObject, "geo_latitude"),
      geo_longitude: this.attribute(profileApiResponseObject, "geo_longitude"),
      geo_free: this.attribute(profileApiResponseObject, "geo_region_1") ? 0 : 1,
      geo_language: this.attribute(profileApiResponseObject, "geo_language"),
      geo_type: "",
      geo_locality: this.attribute(profileApiResponseObject, "geo_locality"),
      name: this.attribute(profileApiResponseObject, "profile_name"),
      date_of_birth: this.attribute(profileApiResponseObject, "date_of_birth"),
      base_profile_id: null,
      created_at: this.tagCategory(profileApiResponseObject, "created_at") || "",
      updated_at: this.tagCategory(profileApiResponseObject, "modified") || "",
      geo: [
        {
          iso: this.attribute(profileApiResponseObject, "geo_iso"),
          language: this.attribute(profileApiResponseObject, "geo_language"),
          timezone: this.attribute(profileApiResponseObject, "geo_timezone"),
          region1: this.attribute(profileApiResponseObject, "geo_region_1"),
          region2: this.attribute(profileApiResponseObject, "geo_region_2"),
          region3: this.attribute(profileApiResponseObject, "geo_region_3"),
          region4: this.attribute(profileApiResponseObject, "geo_region_4"),
          locality: this.attribute(profileApiResponseObject, "geo_locality"),
          postcode: this.attribute(profileApiResponseObject, "postcode"),
        },
      ],
      creditsBalance: null,
      last_activity: profileApiResponseObject.last_activity_at || "",
    } as Omit<
      Profile,
      | "category"
      | "company_id"
      | "has_photo"
      | "is_active"
      | "is_disabled"
      | "is_entertainment"
      | "last_modified_user_email"
      | "length"
      | "origin"
      | "photo_count"
      | "photo_setting"
      | "search_preference"
    > as Profile;
  }
}
