import {
  AbuseMessage,
  AbuseHistoryFilter,
  ChatDocument,
  MessageDocumentWithAbuse,
  NonRealtimeRepository,
  NotificationTypes,
} from "@/types";
import { ABUSE_STATUS_SAFE, ABUSE_STATUS_ABUSE } from "@/types/constants";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  getFirestore,
  CollectionReference,
  Query,
  collectionGroup,
  where,
  query,
  collection,
  doc,
  getDoc,
  getDocs,
  limitToLast,
  endBefore,
  limit,
  startAfter,
  orderBy,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import _get from "lodash/get";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { useNotificationStore } from "@/stores/notifications";
import { useAbuseStore } from "@/stores/abuse";

export class AbuseHistoryRepository implements NonRealtimeRepository {
  private _chatsCollectionRef: CollectionReference<ChatDocument>;
  private _abuseHistoryCollectionGroup: Query;
  private _abuseHistoryUnsubscribeFunction: () => void;

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    const firestore = getFirestore(this._project);
    this._chatsCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getChatsCollectionRef();
    this._abuseHistoryCollectionGroup = query(
      collectionGroup(firestore, "flagged_messages"),
      where("locale", "==", this._locale),
      where("abuse.status", "in", [ABUSE_STATUS_SAFE, ABUSE_STATUS_ABUSE]),
    );
  }

  private setVisibleDocuments(documentSnapshots: any): void {
    const firstVisible = documentSnapshots.docs[0];
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    useAbuseStore().firstAbuseHistoryMessage = firstVisible.data();
    useAbuseStore().lastAbuseHistoryMessage = lastVisible.data();
  }

  private flaggedMessagesByChatQuery(chatId: string): Query {
    const docRef = doc(this._chatsCollectionRef, chatId);
    return query(
      collection(docRef, "flagged_messages"),
      where("locale", "==", this._locale),
      where("abuse.status", "in", [ABUSE_STATUS_SAFE, ABUSE_STATUS_ABUSE]),
    );
  }

  private createAbuseHistoryMessagesQuery(filters: AbuseHistoryFilter): Query {
    let abuseHistoryQuery = this._abuseHistoryCollectionGroup;
    if (filters.chatId) {
      abuseHistoryQuery = this.flaggedMessagesByChatQuery(filters.chatId);
    }

    if (filters.operatorId) {
      abuseHistoryQuery = query(abuseHistoryQuery, where("senderId", "==", filters.operatorId));
    }

    if (filters.operatorName) {
      abuseHistoryQuery = query(abuseHistoryQuery, where("senderName", "==", filters.operatorName));
    }

    const sortBy = filters.sortBy || "abuse.checkedAt";
    const sortDirection = filters.sortDesc ? "desc" : "asc";
    abuseHistoryQuery = query(abuseHistoryQuery, orderBy(sortBy, sortDirection));

    const limitAmount = 10;
    if (!filters.direction) {
      abuseHistoryQuery = query(abuseHistoryQuery, limit(limitAmount));
    }
    if (filters.direction === "next") {
      abuseHistoryQuery = query(abuseHistoryQuery, startAfter(_get(useAbuseStore().lastAbuseHistoryMessage, sortBy)));
      abuseHistoryQuery = query(abuseHistoryQuery, limit(limitAmount));
    }
    if (filters.direction === "previous") {
      abuseHistoryQuery = query(abuseHistoryQuery, endBefore(_get(useAbuseStore().firstAbuseHistoryMessage, sortBy)));
      abuseHistoryQuery = query(abuseHistoryQuery, limitToLast(limitAmount));
    }

    return abuseHistoryQuery;
  }

  public async dispatch(action: "getAbuseMessages", params: any): Promise<void> {
    this[action](params);
  }

  public disableAllListeners(): void {
    if (this._abuseHistoryUnsubscribeFunction) {
      this._abuseHistoryUnsubscribeFunction();
    }
  }

  public async getAbuseMessages(filters: AbuseHistoryFilter) {
    try {
      const querySnapshot = await getDocs(this.createAbuseHistoryMessagesQuery(filters));
      if (querySnapshot.empty) {
        useAbuseStore().abuseHistoryMessages = [];
        return;
      }

      this.setVisibleDocuments(querySnapshot);
      const abuseMessages = await Promise.all(
        querySnapshot.docs.map(async (document: QueryDocumentSnapshot) => {
          const message = {
            id: document.id,
            ...document.data(),
          } as MessageDocumentWithAbuse;

          const chat = await this.getChatDocument(document.ref.parent.parent.id);
          if (!chat) {
            return {
              message,
            };
          }

          chat.id = document.ref.parent.parent.id as Branded<string, "ChatId">;
          const abuseMessage = {
            message,
            chat,
          } as AbuseMessage;
          return abuseMessage;
        }),
      );
      useAbuseStore().abuseHistoryMessages = abuseMessages;
    } catch (error: any) {
      useNotificationStore().addNotification({
        message: error,
        type: NotificationTypes.Error,
      });
    }
  }

  private async getChatDocument(chatId: string): Promise<ChatDocument> {
    const docRef = doc(this._chatsCollectionRef, chatId);
    const chatSnapshot = await getDoc(docRef);
    return chatSnapshot.data() as ChatDocument;
  }
}
