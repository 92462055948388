import { LogPredefinedValues } from "@/types";

export const hobbies: LogPredefinedValues[] = [
  {
    name: "sport",
    title: "Sport and Hobbies",
    values: [
      "3D printing",
      "Acrobatics",
      "Acting",
      "Action figure",
      "Air sports",
      "Aircraft spotting",
      "Airsoft",
      "Amateur astronomy",
      "Amateur geology",
      "Amateur radio",
      "American football (gridiron)",
      "Amusement park visiting",
      "Animal fancy",
      "Animation",
      "Ant-keeping",
      "Antiquing",
      "Antiquities",
      "Aquascaping",
      "Archery",
      "Art collecting",
      "Association football",
      "Astrology",
      "Astronomy",
      "Audiophile",
      "Australian rules football",
      "Auto audiophilia",
      "Auto racing",
      "Axe throwing",
      "BASE jumping",
      "BMX",
      "Backgammon",
      "Backpacking",
      "Badminton",
      "Baking",
      "Baseball",
      "Basketball",
      "Baton twirling",
      "Beach volleyball",
      "Beauty pageants",
      "Beekeeping",
      "Billiards",
      "Biology",
      "Birdwatching",
      "Blacksmithing",
      "Blogging",
      "Board sports",
      "Board/tabletop games",
      "Bodybuilding",
      "Bonsai",
      "Book collecting",
      "Book discussion clubs",
      "Book restoration",
      "Bowling",
      "Boxing",
      "Brazilian jiu-jitsu",
      "Breadmaking",
      "Breakdancing",
      "Bridge",
      "Building",
      "Bullet journaling",
      "Bus riding",
      "Bus spotting",
      "Butterfly watching",
      "Button collecting",
      "Cabaret",
      "Calligraphy",
      "Camping",
      "Candle making",
      "Candy making",
      "Canoeing",
      "Canyoning",
      "Car fixing & building",
      "Car riding",
      "Card games",
      "Cartophily",
      "Caving",
      "Checkers (draughts)",
      "Cheerleading",
      "Cheesemaking",
      "Chemistry",
      "Chess",
      "Cleaning",
      "Climbing",
      "Clothesmaking",
      "Coffee roasting",
      "Coin collecting",
      "Collecting",
      "Color guard",
      "Coloring",
      "Comic book collecting",
      "Composting",
      "Computer programming",
      "Confectionery",
      "Cooking",
      "Cosplaying",
      "Couponing",
      "Craft",
      "Creative writing",
      "Cribbage",
      "Cricket",
      "Crocheting",
      "Croquet",
      "Cross-stitch",
      "Crossword puzzles",
      "Cryptography",
      "Cue sports",
      "Curling",
      "Cycling",
      "DJing",
      "Dance",
      "Dancing",
      "Darts",
      "Debate",
      "Deltiology",
      "Die-cast toy",
      "Digital arts",
      "Digital hoarding",
      "Disc golf",
      "Distro Hopping",
      "Do it yourself",
      "Dog sport",
      "Dolls",
      "Dominoes",
      "Dowsing",
      "Drama",
      "Drawing",
      "Drink mixing",
      "Drinking",
      "Driving",
      "Eating",
      "Electrochemistry",
      "Electronic games",
      "Electronics",
      "Element collecting",
      "Embroidery",
      "Ephemera collecting",
      "Equestrianism",
      "Esports",
      "Exhibition drill",
      "Experimenting",
      "Fantasy sports",
      "Farming",
      "Fashion design",
      "Fashion",
      "Fencing",
      "Field hockey",
      "Figure skating",
      "Filmmaking",
      "Fingerprint collecting",
      "Fishing",
      "Fishkeeping",
      "Flag football",
      "Flower arranging",
      "Flower collecting and pressing",
      "Flower growing",
      "Fly tying",
      "Flying disc",
      "Flying",
      "Footbag",
      "Foraging",
      "Foreign language learning",
      "Fossicking",
      "Fossil hunting",
      "Freestyle football",
      "Frisbee",
      "Furniture building",
      "Fusilately",
      "Gaming",
      "Gardening",
      "Genealogy",
      "Geocaching",
      "Geography",
      "Ghost hunting",
      "Gingerbread house making",
      "Glassblowing",
      "Go",
      "Gold prospecting",
      "Golfing",
      "Gongoozling",
      "Graffiti",
      "Graphic design",
      "Gunsmithing",
      "Gymnastics",
      "Hacking",
      "Handball",
      "Herbalism",
      "Herp keeping",
      "Herping",
      "High-power rocketry",
      "Hiking",
      "History",
      "Hobby horsing",
      "Hobby tunneling",
      "Home improvement",
      "Homebrewing",
      "Hooping",
      "Horseback riding",
      "Horseshoes",
      "Houseplant care",
      "Hula hooping",
      "Humor",
      "Hunting",
      "Hydroponics",
      "Ice hockey",
      "Ice skating",
      "Iceboat racing",
      "Inline skating",
      "Insect collecting",
      "Jewelry making",
      "Jigsaw puzzles",
      "Jogging",
      "Journaling",
      "Judo",
      "Juggling",
      "Jujitsu",
      "Jukskei",
      "Jumping rope",
      "Kabaddi",
      "Karaoke",
      "Karate",
      "Kart racing",
      "Kayaking",
      "Kendama",
      "Kite flying",
      "Kitesurfing",
      "Knife collecting",
      "Knife making",
      "Knife throwing",
      "Knitting",
      "Knot tying",
      "Knowledge/word games",
      "Kombucha brewing",
      "LARPing",
      "Lace making",
      "Lacrosse",
      "Lapidary",
      "Laser tag",
      "Learning",
      "Leather crafting",
      "Lego building",
      "Letterboxing",
      "Listening to music",
      "Listening to podcasts",
      "Lock picking",
      "Long-distance running",
      "Longboarding",
      "Lotology",
      "Machining",
      "Macrame",
      "Magic",
      "Magnet fishing",
      "Mahjong",
      "Makeup",
      "Marbles",
      "Marching band",
      "Martial arts",
      "Mathematics",
      "Mazes (indoor/outdoor)",
      "Meditation",
      "Metal detecting",
      "Metalworking",
      "Meteorology",
      "Microscopy",
      "Mineral collecting",
      "Model United Nations",
      "Model aircraft",
      "Model building",
      "Model engineering",
      "Motor sports",
      "Mountain biking",
      "Mountaineering",
      "Movie and movie memorabilia collecting",
      "Museum visiting",
      "Mushroom hunting",
      "Nail art",
      "Needlepoint",
      "Netball",
      "Nordic skating",
      "Orienteering",
      "Origami",
      "Paintball",
      "Painting",
      "Palmistry",
      "Parkour",
      "Perfume",
      "Pet adoption & fostering",
      "Philately",
      "Phillumeny",
      "Photography",
      "Physics",
      "Pickleball",
      "Playing musical instruments",
      "Podcast hosting",
      "Poi",
      "Poker",
      "Polo",
      "Pool",
      "Pottery",
      "Powerlifting",
      "Practical jokes",
      "Pressed flower craft",
      "Psychology",
      "Public transport riding",
      "Puzzles",
      "Quidditch",
      "Quilling",
      "Quilting",
      "Quizzes",
      "Race walking",
      "Racquetball",
      "Radio-controlled car racing",
      "Radio-controlled model",
      "Rafting",
      "Rail transport modeling",
      "Rail transport modelling",
      "Railway journeys",
      "Railway studies",
      "Rappelling",
      "Rapping",
      "Reading",
      "Record collecting",
      "Refinishing",
      "Reiki",
      "Research",
      "Road biking",
      "Robot combat",
      "Rock balancing",
      "Rock climbing",
      "Rock tumbling",
      "Role-playing games",
      "Roller derby",
      "Roller skating",
      "Rubik's Cube",
      "Rugby league football",
      "Rugby",
      "Running",
      "Sailing",
      "Sand art",
      "Satellite watching",
      "Scouting",
      "Scrapbooking",
      "Scuba diving",
      "Sculling",
      "Sculpting",
      "Scutelliphily",
      "Sea glass collecting",
      "Seashell collecting",
      "Sewing",
      "Shoemaking",
      "Shoes",
      "Shogi",
      "Shooting sport",
      "Shooting",
      "Shopping",
      "Shortwave listening",
      "Shuffleboard",
      "Singing",
      "Skateboarding",
      "Sketching",
      "Skiing",
      "Skimboarding",
      "Skipping rope",
      "Skydiving",
      "Slacklining",
      "Sled dog racing",
      "Slot car racing",
      "Slot car",
      "Snowboarding",
      "Snowmobiling",
      "Snowshoeing",
      "Soapmaking",
      "Soccer (football)",
      "Social media",
      "Softball",
      "Speed skating",
      "Speedcubing",
      "Sport stacking",
      "Sports memorabilia",
      "Sports science",
      "Spreadsheets",
      "Squash",
      "Stamp collecting",
      "Stand-up comedy",
      "Stone collecting",
      "Stone skipping",
      "Stuffed toy collecting",
      "Sun bathing",
      "Surfing",
      "Survivalism",
      "Swimming",
      "Table football",
      "Table tennis",
      "Taekwondo",
      "Tai chi",
      "Tarot",
      "Taxidermy",
      "Tea bag collecting",
      "Tennis polo",
      "Tennis",
      "Tether car",
      "Thrifting",
      "Thru-hiking",
      "Ticket collecting",
      "Topiary",
      "Tour skating",
      "Tourism",
      "Toys",
      "Trade fair visiting",
      "Trainspotting",
      "Transit map collecting",
      "Trapshooting",
      "Travel",
      "Triathlon",
      "Ultimate frisbee",
      "Urban exploration",
      "Vacation",
      "Vegetable farming",
      "Vehicle restoration",
      "Video editing",
      "Video game collecting",
      "Video game developing",
      "Video gaming",
      "Videography",
      "Vintage cars",
      "Vintage clothing",
      "Vinyl Records",
      "Virtual reality",
      "Volleyball",
      "Walking",
      "Watching movies",
      "Watching television",
      "Water polo",
      "Water sports",
      "Waxing",
      "Weaving",
      "Weight training",
      "Weightlifting",
      "Welding",
      "Whale watching",
      "Whittling",
      "Wikipedia editing",
      "Winemaking",
      "Wood carving",
      "Woodworking",
      "Word searches",
      "Worldbuilding",
      "Wrestling",
      "Writing",
      "Yo-yoing",
      "Yoga",
      "Zumba",
    ],
  },
];
