export const IS_GEO_FREE = 1;
export const IS_NOT_GEO_FREE = 0;
export const VARIABLE_REGEX = /(%\[.*?\]%)/gi;

export const ERROR_AUTH_RE_LOGIN = "auth/requires-recent-login";
export const ERROR_TOO_MANY_ATTEMPTS = "auth/too-many-requests";
export const ERROR_INVALID_MF_SESSION = "auth/invalid-multi-factor-session";
export const ERROR_AUTH_CODE_EXPIRED = "auth/code-expired";

export const NOTIFICATION_SESSION_EXPIRED = "Your login session has expired. Please log in again to access your account.";
export const CONTINUOUS_CHARACTERS_THRESHOLD = 40;
