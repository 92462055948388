import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  doc,
  getDocs,
  addDoc,
  getDoc,
  Timestamp,
  CollectionReference,
  Query,
  limit,
  DocumentSnapshot,
  orderBy,
  setDoc,
} from "firebase/firestore";
import { useSystemStore } from "@/stores/system";
import { NonRealtimeRepository, SystemNotification } from "@/types";

export class SystemNotificationRepository implements NonRealtimeRepository {
  private _systemNotificationsCollection: CollectionReference<SystemNotification>;
  private _systemNotificationsSortedQuery: Query;

  constructor(private _user: User | null, private _project: FirebaseApp) {
    const firebase = getFirestore(this._project);

    this._systemNotificationsCollection = collection(
      firebase,
      "system_notifications",
    ) as CollectionReference<SystemNotification>;
    this._systemNotificationsSortedQuery = query(this._systemNotificationsCollection, orderBy("createdAt", "desc"));
  }

  public async dispatch(
    action:
      | "getHistorySystemNotifications"
      | "getCurrentSystemNotification"
      | "createSystemNotification"
      | "disableCurrentNotification",
    params: any,
  ) {
    await this[action]?.(params);
  }

  public async getHistorySystemNotifications(): Promise<void> {
    const MAX_HISTORY_RECORDS_COUNT = 20;
    const queryLog = query(this._systemNotificationsSortedQuery, limit(MAX_HISTORY_RECORDS_COUNT));
    const querySnapshot = await getDocs(queryLog);

    useSystemStore().resetSystemNotifications();

    if (querySnapshot.empty) {
      return;
    }

    querySnapshot.docs.forEach((document: any) => {
      const systemNotification = {
        ...document.data(),
        id: document.id,
      } as SystemNotification;
      useSystemStore().addSystemNotification(systemNotification);
    });
  }

  public async getCurrentSystemNotification(): Promise<void> {
    const querySnapshot = await getDocs(this.currentSystemNotificationQuery());

    if (querySnapshot.empty) {
      useSystemStore().setCurrentSystemNotification(null);
      return;
    }

    const currentSystemNotification = {
      ...querySnapshot.docs[0].data(),
      id: querySnapshot.docs[0].id,
    } as SystemNotification;

    const showUntilAt = querySnapshot.docs[0].data().showUntilAt;
    const isCurrentStillActive = !showUntilAt || showUntilAt?.toDate() > new Date();
    useSystemStore().setCurrentSystemNotification(isCurrentStillActive ? currentSystemNotification : null);
  }

  public async createSystemNotification(
    systemNotification: Omit<SystemNotification, "id" | "createdBy" | "createdAt">,
  ): Promise<DocumentSnapshot | undefined> {
    if (!this._user) {
      return;
    }

    const systemNotificationRef = await addDoc(this._systemNotificationsCollection, {
      ...systemNotification,
      createdBy: this._user.uid,
      createdAt: Timestamp.now(),
    } as Omit<SystemNotification, "id">);
    const newSystemNotificationDoc = await getDoc(systemNotificationRef);
    return newSystemNotificationDoc;
  }

  public async disableCurrentNotification(): Promise<void> {
    // just set "showUntilAt" to now
    const querySnapshot = await getDocs(this.currentSystemNotificationQuery());

    if (querySnapshot.empty) {
      return;
    }

    const showUntilAt = querySnapshot.docs[0].data().showUntilAt;
    const isCurrentStillActive = !showUntilAt || showUntilAt?.toDate() > new Date();
    if (!isCurrentStillActive) {
      return;
    }

    await setDoc(
      doc(this._systemNotificationsCollection, querySnapshot.docs[0].id),
      {
        showUntilAt: Timestamp.now(),
      },
      { merge: true },
    );
  }

  private currentSystemNotificationQuery(): Query {
    return query(this._systemNotificationsSortedQuery, limit(1));
  }
}
