import { PokeDocument, Repository } from "@/types";
import { ABUSE_STATUS_EXPIRED, ABUSE_STATUS_PENDING, ABUSE_STATUS_SAFE, ABUSE_STATUS_ABUSE } from "@/types/constants";
import { User } from "firebase/auth";
import { FirebaseApp } from "firebase/app";
import {
  Query,
  CollectionReference,
  where,
  collection,
  doc,
  updateDoc,
  query,
  QuerySnapshot,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { useAbuseStore } from "@/stores/abuse";

export class AbusePokesRepository implements Repository {
  private _pokesCollectionRef: CollectionReference<PokeDocument>;
  private _abusePokesCollection: Query;
  private _abuseMessagesUnsubscribeFunction: () => void;
  private initialized = false;

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    const firestore = getFirestore(this._project);
    this._pokesCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getPokesCollectionRef();
    const abusePokesCollectionGeos = collection(firestore, "geos");
    const abusePokesDocument = doc(abusePokesCollectionGeos, _locale);
    this._abusePokesCollection = collection(abusePokesDocument, "pokes");
  }

  private createAbusePokesQuery(collection: Query) {
    return query(collection, where("abuse.status", "in", [ABUSE_STATUS_PENDING, ABUSE_STATUS_EXPIRED]));
  }

  public enableListeners() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this._abuseMessagesUnsubscribeFunction = onSnapshot(
      this.createAbusePokesQuery(this._abusePokesCollection),
      this.constructChangeHandler(),
    );
  }

  public async dispatch(
    action: "disableAllListeners" | "markAsAbuse" | "markAsSafe" | "claim" | "unclaim",
    params: any,
  ): Promise<void> {
    this[action]?.(params);
  }

  public async markAsAbuse({ abusePoke, operatorId }: { abusePoke: PokeDocument; operatorId: string }): Promise<void> {
    const docRef = doc(this._pokesCollectionRef, abusePoke.id);
    await updateDoc(docRef, {
      "abuse.status": ABUSE_STATUS_ABUSE,
      "abuse.checkedAt": new Date(),
      "abuse.checkedBy": operatorId,
      "abuse.claimedBy": null,
    } as any);
  }

  public async markAsSafe({ abusePoke, operatorId }: { abusePoke: PokeDocument; operatorId: string }): Promise<void> {
    const docRef = doc(this._pokesCollectionRef, abusePoke.id);
    await updateDoc(docRef, {
      "abuse.status": ABUSE_STATUS_SAFE,
      "abuse.checkedAt": new Date(),
      "abuse.checkedBy": operatorId,
      "abuse.claimedBy": null,
    } as any);
  }

  public async claim({ abusePoke, operatorId }: { abusePoke: PokeDocument; operatorId: string }): Promise<void> {
    const docRef = doc(this._pokesCollectionRef, abusePoke.id);
    await updateDoc(docRef, {
      "abuse.status": ABUSE_STATUS_PENDING,
      "abuse.claimedBy": operatorId,
      "abuse.claimedAt": new Date(),
    } as any);
  }

  public async unclaim({ abusePoke }: { abusePoke: PokeDocument; operatorId: string }): Promise<void> {
    const docRef = doc(this._pokesCollectionRef, abusePoke.id);
    await updateDoc(docRef, {
      "abuse.status": ABUSE_STATUS_PENDING,
      "abuse.claimedBy": null,
      "abuse.claimedAt": null,
    } as any);
  }

  public disableAllListeners(): void {
    if (this._abuseMessagesUnsubscribeFunction) {
      this._abuseMessagesUnsubscribeFunction();
    }
  }

  private constructChangeHandler() {
    return (snapshot: QuerySnapshot): void => {
      snapshot.docChanges().forEach(async (docChange) => {
        const abusePoke = {
          id: docChange.doc.id,
          ...docChange.doc.data(),
        } as PokeDocument;

        switch (docChange.type) {
          case "removed":
            useAbuseStore().removeAbusePoke(abusePoke);
            break;
          case "added":
            useAbuseStore().addAbusePoke(abusePoke);
            break;
          case "modified":
            useAbuseStore().updateAbusePoke(abusePoke);
            break;
        }
      });
    };
  }
}
