import { Repository, UserData } from "@/types";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import { getFirestore, collection, doc, onSnapshot, CollectionReference } from "firebase/firestore";
import { useAuthenticatorStore } from "@/stores/authenticator";

export class UserRepository implements Repository {
  private _usersRef: CollectionReference<UserData>;
  private _userUnsubscribeFunction: () => void;
  private initialized = false;

  constructor(private _user: User, private _project: FirebaseApp) {
    const firebase = getFirestore(this._project);
    this._usersRef = collection(firebase, `users`);
  }

  private createUserQuery(collection: CollectionReference) {
    return doc(collection, this._user.uid);
  }

  public enableListeners() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;

    this._userUnsubscribeFunction = onSnapshot(this.createUserQuery(this._usersRef), (doc) => {
      if (!doc.data()) {
        return;
      }
      const { disabled } = doc.data();
      if (disabled) {
        useAuthenticatorStore().signOut();
      }
    });
  }

  public async dispatch(action: "enableListeners" | "disableAllListeners"): Promise<any> {
    return this[action]?.();
  }

  public disableAllListeners(): void {
    this._userUnsubscribeFunction?.();
  }
}
