import { DomainConfiguration, ChatDocument, Attachment, UsedAttachment } from "@/types";
import { ATTACHMENT_HASH_REGEX, ATTACHMENT_CDN_REGEX } from "@/types/constants";
import AttachmentRepository from "@/repositories/attachmentRepository";
import { useConfigStore } from "@/stores/config";

const attachmentsRepository = new AttachmentRepository();

function isAttachmentObject(attachment: string | Attachment): attachment is Attachment {
  return attachment && typeof attachment === "object" && "displayUrl" in attachment;
}

/**
 * Determines if an attachment URL can be served by a CDN based on a regex pattern.
 *
 * @param {string} attachmentUrl - The attachment URL to check.
 * @returns {boolean} True if the URL matches the pattern, indicating it can be served by the CDN; otherwise, false.
 */
export function canServeByCdn(attachmentUrl: string): boolean {
  return RegExp(ATTACHMENT_CDN_REGEX).test(attachmentUrl);
}

/**
 * Generates a CDN URL for the given attachment URL using a domain configuration.
 *
 * @param {string} attachmentUrl - The attachment URL to create a CDN URL for.
 * @param {DomainConfiguration} domainConfiguration - The domain configuration containing CDN information.
 * @returns {string} The CDN URL for the attachment.
 */
export function insertAttachmentFilenameToCdnDomain(
  attachmentHash: string,
  domainConfiguration: DomainConfiguration,
): string {
  if (!domainConfiguration.cdnBaseLiveUrl) {
    throw new Error(
      `Oops! It seems like there's a problem with the configuration for the domain "${domainConfiguration.domain}". Please make sure to set the CDN_BASE_LIVE_URL for this domain in your configuration.`,
    );
  }
  return domainConfiguration.cdnBaseLiveUrl.replace(/%s/gi, attachmentHash);
}

export function addCdnUrlsToAttachmentsHashes(attachments: string[], chat: ChatDocument): Record<string, string> {
  if (!chat) {
    return {};
  }

  const domainConfiguration: DomainConfiguration = useConfigStore().domainConfig(chat.domain);
  return Object.fromEntries(
    attachments
      .filter(canServeByCdn)
      .map((attachmentHash) => [
        attachmentHash,
        insertAttachmentFilenameToCdnDomain(attachmentHash, domainConfiguration),
      ]),
  );
}

/**
 * Generates a CDN URL for the given attachment URL using a domain configuration.
 *
 * @param {string} attachmentUrl - The attachment URL to create a CDN URL for.
 * @param {DomainConfiguration} domainConfiguration - The domain configuration containing CDN information.
 * @returns {string} The CDN URL for the attachment.
 */
export async function getAttachmentUrlForCdn(attachmentUrlWithHash: string): Promise<string> {
  return await attachmentsRepository.getSignedAttachment(attachmentUrlWithHash);
}

export async function getAttachmentsUrlsForCdn(attachmentsUrlWithHashes: string[]): Promise<Record<string, string>> {
  return await attachmentsRepository.getSignedAttachments(attachmentsUrlWithHashes);
}

export async function getFullAttachmentUrlForCdn(originalAttachmentUrl: string, domain: string): Promise<string> {
  if (!originalAttachmentUrl || !domain) {
    return "";
  }
  if (!canServeByCdn(originalAttachmentUrl)) {
    return originalAttachmentUrl;
  }
  const domainConfiguration: DomainConfiguration = useConfigStore().domainConfig(domain);
  const attachmentWithCdnUrl = insertAttachmentFilenameToCdnDomain(originalAttachmentUrl, domainConfiguration);
  return await getAttachmentUrlForCdn(attachmentWithCdnUrl);
}

export async function getFullAttachmentsUrlsForCdn(
  urlsByHash: Record<string, string>,
): Promise<Record<string, string>> {
  if (Object.keys(urlsByHash).length === 0) {
    return {};
  }

  const urlsWithHashesAndDisplayUrls = await getAttachmentsUrlsForCdn(Object.values(urlsByHash));
  return Object.fromEntries(
    Object.entries(urlsWithHashesAndDisplayUrls).map(([urlWithHash, displayUrl]) => {
      return [
        Object.entries(urlsByHash).find(([_hash, originalUrlWithHash]) => originalUrlWithHash === urlWithHash)[0],
        displayUrl,
      ];
    }),
  );
}

export function extractAttachmentCdnHash(fullSignedUrl: string): string {
  const cdnHash = fullSignedUrl.match(ATTACHMENT_HASH_REGEX)[0];
  return cdnHash;
}

export function areAttachmentsEqual(attachmentA: Attachment, attachmentB: Attachment): boolean {
  if (!attachmentA || !attachmentB) return false;

  const haveAttachmentsSameUrl = !!attachmentA.url && attachmentA.url === attachmentB.url;
  const haveAttachmentsSameHashes = !!attachmentA.cdnHash && attachmentA.cdnHash === attachmentB.cdnHash;
  return haveAttachmentsSameUrl || haveAttachmentsSameHashes;
}

export function extractAttachmentUrlToSave(attachment?: Attachment | UsedAttachment | string): string | null {
  if (!attachment) return null;
  if (typeof attachment === "string") return attachment;
  return attachment.url || (attachment as Attachment).cdnHash || null;
}

export async function convertAttachmentToObject(attachment: string | Attachment, domain: string): Promise<Attachment> {
  if (!attachment) {
    return null;
  }

  if (isAttachmentObject(attachment)) {
    return attachment;
  }

  return (canServeByCdn(attachment)
    ? {
        cdnHash: attachment,
        displayUrl: await getFullAttachmentUrlForCdn(attachment, domain),
      }
    : { url: attachment, displayUrl: attachment }) as unknown as Attachment;
}
