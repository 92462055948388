import { AbuseMessageParsedItem, AbuseMatch, ReportedMessage, ReportCategory } from "@/types";
import vuetifyColors from "vuetify/lib/util/colors";
import _camelCase from "lodash/camelCase";
import _capitalize from "lodash/capitalize";
import { useReportedMessages } from "@/stores/reportedMessages";

export const MINUTES_TO_ABANDON = 5;

export const abuseWordsCategories: Record<string, { testid: string; title: string; icon: string }> = {
  COMPANY_INFORMATION: {
    testid: "information",
    title: "Company Information",
    icon: "mdi-information-variant",
  },
  EMAIL_PROVIDER_VARIANTS: {
    testid: "email",
    title: "Email provider variants",
    icon: "mdi-email-outline",
  },
  NATURE_OF_SERVICE: {
    testid: "nature",
    title: "Nature of service",
    icon: "mdi-cards-playing-outline",
  },
};

export const ABUSE_NO_FILTER_NAME = "No filter (all words)";
export const ABUSE_GLOBAL_LOCALE_NAME = "For all countries";

// colors are from https://vuetifyjs.com/en/styles/colors/#material-colors
export const likelihoodColors = {
  VERY_LIKELY: "red",
  LIKELY: "orange darken-2",
  POSSIBLE: "blue darken-2",
  UNLIKELY: "light-blue",
  VERY_UNLIKELY: "light-blue lighten-3",
  LIKELIHOOD_UNSPECIFIED: "blue",
};

type EnrichedLikelihoodColor = {
  color: string;
  key: keyof typeof likelihoodColors;
  title: string;
};

export const enrichedLikelihoodColors: EnrichedLikelihoodColor[] = (() => {
  delete likelihoodColors.LIKELIHOOD_UNSPECIFIED;
  return Object.keys(likelihoodColors).map((likelihoodKey) => ({
    color: likelihoodColors[likelihoodKey as keyof typeof likelihoodColors],
    key: likelihoodKey as keyof typeof likelihoodColors,
    title: _capitalize(likelihoodKey.replace(/_/g, " ")),
  }));
})();

function getVuetifyColor(vuetifyColor: string) {
  const [colorName, colorModification]: string[] = vuetifyColor?.split(" ") || ["", ""];
  return (vuetifyColors as any)[_camelCase(colorName)]?.[colorModification?.replace("-", "") || "base"];
}

function wrapMatch(matchText: string, likelihood: keyof typeof likelihoodColors): string {
  const hexColor = getVuetifyColor(likelihoodColors[likelihood]);
  const staticStyle = "border-radius: 5px; padding-left: 2px; padding-right: 2px;";
  const openTag = `<strong class="white--text" style='background-color: ${hexColor}; ${staticStyle}'>`;
  const closeTag = "</strong>";
  return `${openTag}${matchText}${closeTag}`;
}

function splitByMatch(inputText: string, match: AbuseMatch): AbuseMessageParsedItem[] {
  const startPositionMatch = inputText.indexOf(match.quote);
  const endPositionMatch = startPositionMatch + match.quote.length;

  const beforeMatchText = inputText.substring(0, startPositionMatch);
  const matchText = inputText.substring(startPositionMatch, endPositionMatch);
  const afterMatchText = inputText.substring(endPositionMatch);

  const newParsedItems: AbuseMessageParsedItem[] = [];

  if (beforeMatchText) {
    newParsedItems.push({
      type: "text",
      original: beforeMatchText,
      displayText: beforeMatchText,
    });
  }

  newParsedItems.push({
    type: "match",
    original: matchText,
    displayText: wrapMatch(matchText, match.likelihood),
    likelihood: match.likelihood,
    matchType: match.type,
  });

  if (afterMatchText) {
    newParsedItems.push({
      type: "text",
      original: afterMatchText,
      displayText: afterMatchText,
    });
  }

  return newParsedItems;
}

export function parseAbuseMessage(content: string, matches: AbuseMatch[] = []): AbuseMessageParsedItem[] {
  if (!content) {
    return [];
  }

  const doubleSpaceRegex = / +(?= )/g;
  const fixedContent = content.replace(doubleSpaceRegex, "");

  const parsedItems: AbuseMessageParsedItem[] = [
    {
      type: "text",
      original: fixedContent,
      displayText: fixedContent,
    },
  ];

  matches.forEach((match) => {
    const itemWithMatchIndex = parsedItems.findIndex((item: AbuseMessageParsedItem) => {
      return item.type === "text" && item.original.indexOf(match.quote) >= 0;
    });

    if (itemWithMatchIndex < 0) {
      return;
    }

    parsedItems.splice(itemWithMatchIndex, 1, ...splitByMatch(parsedItems[itemWithMatchIndex].original, match));
  });
  return parsedItems;
}

export function reportMessageCategoryIcon(reported: ReportedMessage): string {
  if (!reported) {
    return "";
  }

  return (
    useReportedMessages().categories[reported.message.senderType]?.find(
      (category: ReportCategory) => category.value === reported.report.category,
    )?.icon || "mdi-block-helper"
  );
}
