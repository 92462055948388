import {
  PokeDocument,
  PokeFilterAges,
  PokeFilterGenders,
  PokeFilterLastPaymentPeriods,
  PokeFilterCreditStatuses,
  PokeFilterPhotoStatuses,
  PokeFilterTextStatuses,
  PokeConversationStatuses,
  PokeTypeDisplay,
  LegacyPokeOnlyBooleanFilters,
} from "@/types";
import {
  POKE_STRATEGY_DEFAULT,
  POKE_STRATEGY_RECURRING,
  POKE_STRATEGY_FOLLOW_UP,
  PokeStrategyName,
  PokeTypeName,
} from "@/types/constants";

export const pokeTypes: Record<PokeTypeName, PokeTypeDisplay> = {
  message: {
    title: "Message",
    icon: "mdi-message-text-outline",
    color: "primary",
    cssTextColor: "primary--text",
  },
  flirt: {
    title: "Flirt",
    icon: "mdi-sticker-emoji",
    color: "purple",
    cssTextColor: "purple--text",
  },
  favorite: {
    title: "Favorite",
    icon: "mdi-star-outline",
    color: "orange",
    cssTextColor: "orange--text",
  },
  event_like: {
    title: "Like",
    icon: "mdi-thumb-up-outline",
    color: "blue",
    cssTextColor: "blue--text",
  },
  proposal: {
    title: "Sex proposal",
    icon: "mdi-heart-outline",
    color: "pink",
    cssTextColor: "pink--text",
  },
};

export const availableTargetVariables = [
  "%[hairColor]%",
  "%[eyeColor]%",
  "%[build]%",
  "%[city]%",
  "%[province]%",
  "%[name]%",
  "%[age]%",
];
export const excludedLegacyTargetVariables = ["%[distance]%", "%[timeOfDay]%"];

export function getPokeAges(poke: PokeDocument): PokeFilterAges[] {
  return [
    ...(poke.filters.age_18_till_25 ? [PokeFilterAges.Age18_25] : []),
    ...(poke.filters.age_26_till_35 ? [PokeFilterAges.Age26_35] : []),
    ...(poke.filters.age_36_till_45 ? [PokeFilterAges.Age36_45] : []),
    ...(poke.filters.age_46_till_54 ? [PokeFilterAges.Age46_54] : []),
    ...(poke.filters.age_55_till_99 ? [PokeFilterAges.Age55_99] : []),
    ...(poke.filters.age_unknown ? [PokeFilterAges.Unknown] : []),
  ];
}

export function getPokeGenders(poke: PokeDocument): PokeFilterGenders[] {
  return [
    ...(poke.filters.gender_couple ? [PokeFilterGenders.Couple] : []),
    ...(poke.filters.gender_female ? [PokeFilterGenders.Female] : []),
    ...(poke.filters.gender_male ? [PokeFilterGenders.Male] : []),
    ...(poke.filters.gender_transgender ? [PokeFilterGenders.Transgender] : []),
    ...(poke.filters.gender_unknown ? [PokeFilterGenders.Unknown] : []),
  ];
}

export function getPokePayments(poke: PokeDocument): PokeFilterLastPaymentPeriods[] {
  return [
    ...(poke.filters.past_paid_older_than_1_month ? [PokeFilterLastPaymentPeriods.MoreOneMonth] : []),
    ...(poke.filters.past_paid_within_1_month ? [PokeFilterLastPaymentPeriods.WithinOneMonth] : []),
    ...(poke.filters.past_paid_within_2_weeks ? [PokeFilterLastPaymentPeriods.WithinTwoWeeks] : []),
    ...(poke.filters.past_paid_within_1_week ? [PokeFilterLastPaymentPeriods.WithinOneWeek] : []),
  ];
}

export function getPokeCredits(poke: PokeDocument): PokeFilterCreditStatuses[] {
  return [
    ...(poke.filters.has_credits ? [PokeFilterCreditStatuses.HasCredits] : []),
    ...(poke.filters.had_credits ? [PokeFilterCreditStatuses.HadCredits] : []),
    ...(poke.filters.never_had_credits ? [PokeFilterCreditStatuses.NeverHadCredits] : []),
  ];
}

export function getPokePhotoStatuses(poke: PokeDocument): PokeFilterPhotoStatuses[] {
  return [
    ...(poke.filters.with_photo ? [PokeFilterPhotoStatuses.WithPhoto] : []),
    ...(poke.filters.without_photo ? [PokeFilterPhotoStatuses.WithoutPhoto] : []),
  ];
}

export function getPokeTextStatuses(poke: PokeDocument): PokeFilterTextStatuses[] {
  return [
    ...(poke.filters.with_text ? [PokeFilterTextStatuses.WithText] : []),
    ...(poke.filters.without_text ? [PokeFilterTextStatuses.WithoutText] : []),
  ];
}

export function getPokeConversationStatuses(poke: PokeDocument): PokeConversationStatuses[] {
  return [
    ...(poke.filters.in_conversation ? [PokeConversationStatuses.InConversation] : []),
    ...(poke.filters.not_in_conversation ? [PokeConversationStatuses.NotInConversation] : []),
  ];
}

export function getPokeLastLogin(poke: PokeDocument): string {
  return poke.filters.lastLogin == "0"
    ? "today"
    : poke.filters.lastLogin == "1"
    ? "yesterday"
    : `${poke.filters.lastLogin} days ago`;
}

export function getPokeLocations(poke: PokeDocument): string[] {
  return [
    ...(poke.filters.geoRegion1 ? [poke.filters.geoRegion1.region1] : ["not selected"]),
    ...(poke.filters.geoRegion1?.locality ? [poke.filters.geoRegion1.locality] : []),
  ];
}

export const pokeStrategy: Record<PokeStrategyName, PokeTypeDisplay> = {
  [POKE_STRATEGY_DEFAULT]: {
    title: "Default",
    icon: "mdi-message-outline",
    color: "primary",
    cssTextColor: "primary--text",
  },
  [POKE_STRATEGY_RECURRING]: {
    title: "Recurring",
    icon: "mdi-rotate-right",
    color: "lime darken-4",
    cssTextColor: "lime--text text--darken-4",
  },
  [POKE_STRATEGY_FOLLOW_UP]: {
    title: "Follow up",
    icon: "mdi-transfer",
    color: "light-blue darken-4",
    cssTextColor: "light-blue--text text--darken-4",
  },
};

const pokeFiltersCategories: Record<string, (keyof LegacyPokeOnlyBooleanFilters)[]> = {
  photo: ["with_photo", "without_photo"],
  text: ["with_text", "without_text"],
  age: ["age_18_till_25", "age_26_till_35", "age_36_till_45", "age_46_till_54", "age_55_till_99", "age_unknown"],
  credits: ["has_credits", "had_credits", "never_had_credits"],
  lastPaid: [
    "past_paid_within_1_week",
    "past_paid_within_2_weeks",
    "past_paid_within_1_month",
    "past_paid_older_then_1_month",
  ],
  gender: ["gender_male", "gender_female", "gender_couple", "gender_transgender", "gender_unknown"],
};

export function invertAllFalseValuesInOneFilterCategory(
  legacyFilters: LegacyPokeOnlyBooleanFilters,
): LegacyPokeOnlyBooleanFilters {
  const updatedLegacyFilters: Record<keyof LegacyPokeOnlyBooleanFilters, boolean> = { ...legacyFilters };
  Object.keys(pokeFiltersCategories).forEach((category) => {
    const areAllFalse = pokeFiltersCategories[category].every((key) => !legacyFilters[key]);
    if (areAllFalse) {
      pokeFiltersCategories[category].forEach((key) => {
        updatedLegacyFilters[key] = true;
      });
    }
  });
  return updatedLegacyFilters;
}
