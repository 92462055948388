export function getAuthErrorDescription(code: string): string {
  if (code === "auth/wrong-password") {
    return "The password is invalid or the user does not have a password.";
  } else if (code === "auth/claims-too-large") {
    return "The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.";
  } else if (code === "auth/email-already-exists") {
    return "The provided email is already in use by an existing user. Each user must have a unique email.";
  } else if (code === "auth/id-token-expired") {
    return "The provided Firebase ID token is expired.";
  } else if (code === "auth/id-token-revoked") {
    return "The Firebase ID token has been revoked.";
  } else if (code === "auth/insufficient-permission") {
    return "The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource.";
  } else if (code === "auth/invalid-argument") {
    return "An invalid argument was provided to an Authentication method.";
  } else if (code === "auth/invalid-claims") {
    return "The custom claim attributes provided to setCustomUserClaims() are invalid.";
  } else if (code === "auth/invalid-creation-time") {
    return "The creation time must be a valid UTC date string.";
  } else if (code === "auth/invalid-disabled-field") {
    return "The provided value for the disabled user property is invalid. It must be a boolean.";
  } else if (code === "auth/invalid-display-name") {
    return "The provided value for the displayName user property is invalid. It must be a non-empty string.";
  } else if (code === "auth/invalid-email-verified") {
    return "The provided value for the emailVerified user property is invalid. It must be a boolean.";
  } else if (code === "auth/invalid-hash-algorithm") {
    return "The hash algorithm must match one of the strings in the list of supported algorithms.";
  } else if (code === "auth/invalid-hash-block-size") {
    return "The hash block size must be a valid number.";
  } else if (code === "auth/invalid-hash-derived-key-length") {
    return "The hash derived key length must be a valid number.";
  } else if (code === "auth/invalid-hash-key") {
    return "The hash key must a valid byte buffer.";
  } else if (code === "auth/invalid-hash-memory-cost") {
    return "The hash memory cost must be a valid number.";
  } else if (code === "auth/invalid-hash-parallelization") {
    return "The hash parallelization must be a valid number.";
  } else if (code === "auth/invalid-hash-rounds") {
    return "The hash rounds must be a valid number.";
  } else if (code === "auth/invalid-hash-salt-separator") {
    return "The hashing algorithm salt separator field must be a valid byte buffer.";
  } else if (code === "auth/invalid-id-token") {
    return "The provided ID token is not a valid Firebase ID token.";
  } else if (code === "auth/invalid-last-sign-in-time") {
    return "The last sign-in time must be a valid UTC date string.";
  } else if (code === "auth/invalid-page-token") {
    return "The provided next page token in listUsers() is invalid. It must be a valid non-empty string.";
  } else if (code === "auth/invalid-password") {
    return "The provided value for the password user property is invalid.";
  } else if (code === "auth/invalid-password-salt") {
    return "The password salt must be a valid byte buffer";
  } else if (code === "auth/invalid-photo-url") {
    return "The provided value for the photoURL user property is invalid. It must be a string URL.";
  } else if (code === "auth/invalid-provider-data") {
    return "The providerData must be a valid array of UserInfo objects.";
  } else if (code === "auth/invalid-oauth-responsetype") {
    return "Only exactly one OAuth responseType should be set to true.";
  } else if (code === "auth/invalid-session-cookie-duration") {
    return "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.";
  } else if (code === "auth/invalid-uid") {
    return "The provided uid must be a non-empty string with at most 128 characters.";
  } else if (code === "auth/invalid-user-import") {
    return "The user record to import is invalid.";
  } else if (code === "auth/maximum-user-count-exceeded") {
    return "The maximum allowed number of users to import has been exceeded.";
  } else if (code === "auth/missing-hash-algorithm") {
    return "Importing users with password hashes requires that the hashing algorithm and its parameters be provided.";
  } else if (code === "auth/missing-uid") {
    return "A uid identifier is required for the current operation.";
  } else if (code === "auth/missing-oauth-client-secret") {
    return "The OAuth configuration client secret is required to enable OIDC code flow.";
  } else if (code === "auth/phone-number-already-exists") {
    return "The provided phoneNumber is already in use by an existing user";
  } else if (code === "auth/project-not-found") {
    return "No Firebase project was found for the credential used to initialize the Admin SDKs.";
  } else if (code === "auth/reserved-claims") {
    return "One or more custom user claims provided to setCustomUserClaims() are reserved.";
  } else if (code === "auth/session-cookie-expired") {
    return "The provided Firebase session cookie is expired.";
  } else if (code === "auth/session-cookie-revoked") {
    return "The Firebase session cookie has been revoked.";
  } else if (code === "auth/uid-already-exists") {
    return "The provided uid is already in use by an existing user. Each user must have a unique uid.";
  } else if (code === "auth/admin-restricted-operation") {
    return "This operation is restricted to administrators only.";
  } else if (code === "auth/app-not-authorized") {
    return "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key.";
  } else if (code === "auth/app-not-installed") {
    return "The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.";
  } else if (code === "auth/captcha-check-failed") {
    return "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.";
  } else if (code === "auth/code-expired") {
    return "The SMS code has expired. Please re-send the verification code to try again.";
  } else if (code === "auth/cordova-not-ready") {
    return "Cordova framework is not ready.";
  } else if (code === "auth/cors-unsupported") {
    return "This browser is not supported.";
  } else if (code === "auth/credential-already-in-use") {
    return "This credential is already associated with a different user account.";
  } else if (code === "auth/custom-token-mismatch") {
    return "The custom token corresponds to a different audience.";
  } else if (code === "auth/requires-recent-login") {
    return "This operation is sensitive and requires recent authentication. Log in again before retrying this request.";
  } else if (code === "auth/dependent-sdk-initialized-before-auth") {
    return "Another Firebase SDK was initialized and is trying to use Auth before Auth is initialized.";
  } else if (code === "auth/dynamic-link-not-activated") {
    return "Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.";
  } else if (code === "auth/email-change-needs-verification") {
    return "Multi-factor users must always have a verified email.";
  } else if (code === "auth/email-already-in-use") {
    return "The email address is already in use by another account.";
  } else if (code === "auth/emulator-config-failed") {
    return "Auth instance has already been used to make a network call. Auth can no longer be configured to use the emulator.";
  } else if (code === "auth/expired-action-code") {
    return "The action code has expired.";
  } else if (code === "auth/cancelled-popup-request") {
    return "This operation has been cancelled due to another conflicting popup being opened.";
  } else if (code === "auth/internal-error") {
    return "An internal AuthError has occurred.";
  } else if (code === "auth/invalid-app-credential") {
    return "The phone verification request contains an invalid application verifier.";
  } else if (code === "auth/invalid-app-id") {
    return "The mobile app identifier is not registered for the current project.";
  } else if (code === "auth/invalid-user-token") {
    return "This user's credential isn't valid for this project.";
  } else if (code === "auth/invalid-auth-event") {
    return "An internal AuthError has occurred.";
  } else if (code === "auth/invalid-verification-code") {
    return "The verification code is incorrect. Please check and try again.";
  } else if (code === "auth/invalid-continue-uri") {
    return "The continue URL provided in the request is invalid.";
  } else if (code === "auth/invalid-cordova-configuration") {
    return "The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.";
  } else if (code === "auth/invalid-custom-token") {
    return "The custom token format is incorrect. Please check the documentation.";
  } else if (code === "auth/invalid-dynamic-link-domain") {
    return "The provided dynamic link domain is not configured or authorized for the current project.";
  } else if (code === "auth/invalid-email") {
    return "The email address is badly formatted.";
  } else if (code === "auth/invalid-emulator-scheme") {
    return "Emulator URL must start with a valid scheme (http:// or https://).";
  } else if (code === "auth/invalid-api-key") {
    return "Your API key is invalid, please check you have copied it correctly.";
  } else if (code === "auth/invalid-cert-hash") {
    return "The SHA-1 certificate hash provided is invalid.";
  } else if (code === "auth/invalid-credential") {
    return "The supplied auth credential is malformed or has expired.";
  } else if (code === "auth/invalid-message-payload") {
    return "The email template corresponding to this action contains invalid characters in its message.";
  } else if (code === "auth/invalid-multi-factor-session") {
    return "The request does not contain a valid proof of first factor successful sign-in.";
  } else if (code === "auth/invalid-oauth-provider") {
    return "EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.";
  } else if (code === "auth/invalid-oauth-client-id") {
    return "The OAuth client ID provided is either invalid or does not match the specified API key.";
  } else if (code === "auth/unauthorized-domain") {
    return "This domain is not authorized for OAuth operations for your Firebase project.";
  } else if (code === "auth/invalid-action-code") {
    return "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.";
  } else if (code === "auth/invalid-persistence-type") {
    return "The specified persistence type is invalid. It can only be local, session or none.";
  } else if (code === "auth/invalid-phone-number") {
    return "The format of the phone number provided is incorrect.";
  } else if (code === "auth/invalid-provider-id") {
    return "The specified provider ID is invalid.";
  } else if (code === "auth/invalid-recipient-email") {
    return "The email corresponding to this action failed to send as the provided recipient email address is invalid.";
  } else if (code === "auth/invalid-sender") {
    return "The email template corresponding to this action contains an invalid sender email or name.";
  } else if (code === "auth/invalid-verification-id") {
    return "The verification ID used to create the phone auth credential is invalid.";
  } else if (code === "auth/invalid-tenant-id") {
    return "The Auth instance's tenant ID is invalid.";
  } else if (code === "auth/missing-android-pkg-name") {
    return "An Android Package Name must be provided if the Android App is required to be installed.";
  } else if (code === "auth/auth-domain-config-required") {
    return "Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.";
  } else if (code === "auth/missing-app-credential") {
    return "The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.";
  } else if (code === "auth/missing-verification-code") {
    return "The phone auth credential was created with an empty SMS verification code.";
  } else if (code === "auth/missing-continue-uri") {
    return "A continue URL must be provided in the request.";
  } else if (code === "auth/missing-iframe-start") {
    return "An internal AuthError has occurred.";
  } else if (code === "auth/missing-ios-bundle-id") {
    return "An iOS Bundle ID must be provided if an App Store ID is provided.";
  } else if (code === "auth/missing-or-invalid-nonce") {
    return "The request does not contain a valid nonce.";
  } else if (code === "auth/missing-multi-factor-info") {
    return "No second factor identifier is provided.";
  } else if (code === "auth/missing-multi-factor-session") {
    return "The request is missing proof of first factor successful sign-in.";
  } else if (code === "auth/missing-phone-number") {
    return "To send verification codes, provide a phone number for the recipient.";
  } else if (code === "auth/missing-verification-id") {
    return "The phone auth credential was created with an empty verification ID.";
  } else if (code === "auth/app-deleted") {
    return "This instance of FirebaseApp has been deleted.";
  } else if (code === "auth/multi-factor-info-not-found") {
    return "The user does not have a second factor matching the identifier provided.";
  } else if (code === "auth/multi-factor-auth-required") {
    return "Proof of ownership of a second factor is required to complete sign-in.";
  } else if (code === "auth/account-exists-with-different-credential") {
    return "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.";
  } else if (code === "auth/network-request-failed") {
    return "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred.";
  } else if (code === "auth/no-auth-event") {
    return "An internal AuthError has occurred.";
  } else if (code === "auth/no-such-provider") {
    return "User was not linked to an account with the given provider.";
  } else if (code === "auth/null-user") {
    return "A null user object was provided as the argument for an operation which requires a non-null user object.";
  } else if (code === "auth/operation-not-allowed") {
    return "Please contact the admin to enable MFA feature in your application";
  } else if (code === "auth/operation-not-supported-in-this-environment") {
    return "This operation is not supported in the environment this application is running on.";
  } else if (code === "auth/popup-blocked") {
    return "Unable to establish a connection with the popup. It may have been blocked by the browser.";
  } else if (code === "auth/popup-closed-by-user") {
    return "The popup has been closed by the user before finalizing the operation.";
  } else if (code === "auth/provider-already-linked") {
    return "User can only be linked to one identity for the given provider.";
  } else if (code === "auth/quota-exceeded") {
    return "Too many attempts. Please wait at least a minute, or ask administrator to reset your 2FA (TOTP/SMS).";
  } else if (code === "auth/redirect-cancelled-by-user") {
    return "The redirect operation has been cancelled by the user before finalizing.";
  } else if (code === "auth/redirect-operation-pending") {
    return "A redirect sign-in operation is already pending.";
  } else if (code === "auth/rejected-credential") {
    return "The request contains malformed or mismatching credentials.";
  } else if (code === "auth/second-factor-already-in-use") {
    return "The second factor is already enrolled on this account.";
  } else if (code === "auth/maximum-second-factor-count-exceeded") {
    return "The maximum allowed number of second factors on a user has been exceeded.";
  } else if (code === "auth/tenant-id-mismatch") {
    return "The provided tenant ID does not match the Auth instance's tenant ID";
  } else if (code === "auth/timeout") {
    return "The operation has timed out.";
  } else if (code === "auth/user-token-expired") {
    return "The user's credential is no longer valid. The user must sign in again.";
  } else if (code === "auth/too-many-requests") {
    return "We have blocked all requests from this device due to unusual activity. Try again later.";
  } else if (code === "auth/unauthorized-continue-uri") {
    return "The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.";
  } else if (code === "auth/unsupported-first-factor") {
    return "Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.";
  } else if (code === "auth/unsupported-persistence-type") {
    return "The current environment does not support the specified persistence type.";
  } else if (code === "auth/unsupported-tenant-operation") {
    return "This operation is not supported in a multi-tenant context.";
  } else if (code === "auth/unverified-email") {
    return "Please verify email first. Check your email inbox.";
  } else if (code === "auth/user-cancelled") {
    return "The user did not grant application the permissions it requested.";
  } else if (code === "auth/user-not-found") {
    return "There is no user record corresponding to this identifier. The user may have been deleted.";
  } else if (code === "auth/user-disabled") {
    return "The user account has been disabled by an administrator.";
  } else if (code === "auth/user-mismatch") {
    return "The supplied credentials do not correspond to the previously signed in user.";
  } else if (code === "auth/weak-password") {
    return "The password must be 6 characters long or more.";
  } else if (code === "auth/web-storage-unsupported") {
    return "This browser is not supported or 3rd party cookies and data may be disabled.";
  } else if (code === "auth/already-initialized") {
    return "initializeAuth() has already been called with different options";
  }
  return code;
}
