import { defineStore } from "pinia";
import { DomainConfiguration, DomainCount, ProfileStoreState, Profile, GeoFreeLocationApiParams } from "@/types";
import { ORIGIN_TYPE_LEGACY } from "@/types/constants";
import _intersectionBy from "lodash/intersectionBy";
import ProfileRepository from "@/repositories/profileRepository";
import ProfileApiConnector from "@/repositories/connectors/profile/ProfileApiConnector";
import MessageCentralProfileConnector from "@/repositories/connectors/profile/MessageCentralProfileConnector";
import { useConfigStore } from "@/stores/config";

export const useProfileStore = defineStore("profile", {
  state: (): ProfileStoreState => ({
    entertainmentProfiles: [],
    entertainmentProfilesCount: null,
    activeEntertainmentProfile: null,
    geoFreeEntertainmentProfileLocation: null,
    activeCustomerProfile: null,
    entertainmentProfileFilters: {},
    customerProfilesCountPerDomain: [],
    loadingCounts: false,
    playersPerDomain: {},
  }),
  getters: {
    customerProfilesCount(): number {
      return this.customerProfilesCountPerDomain.reduce((sum: number, customerProfilesCounts: DomainCount) => {
        return sum + customerProfilesCounts.count;
      }, 0);
    },
    activeCustomerTimezone(): string {
      return this.activeCustomerProfile?.geo[0].timezone || "UTC";
    },
    activeProfileTimezone(): string {
      return this.activeEntertainmentProfile?.geo[0].timezone || "UTC";
    },
    targetsPerDomain(): {
      site: string;
      estimate: number;
      reached: number;
    }[] {
      return (
        Array.from(this.customerProfilesCountPerDomain)?.map(
          (targetPerDomain: { domainName: string; count: number }) => {
            return {
              site: targetPerDomain.domainName.replaceAll(/_/g, "."),
              estimate: targetPerDomain.count,
              reached: 0,
            };
          },
        ) || []
      );
    },
    customerProfileFilters(): Record<string, any> {
      return Object.keys(this.entertainmentProfileFilters)
        .filter((filterName) => ["gender", "build", "hair_colour", "eye_colour", "geo_type"].indexOf(filterName) >= 0)
        .reduce(
          (filter, filterName) =>
            Object.assign(filter, {
              [filterName]: this.entertainmentProfileFilters[filterName],
            }),
          {},
        );
    },
    getPlayerByCmsId() {
      return (domain: string, profileCmsId: string) => {
        return this.playersPerDomain[domain].find((player) => {
          return player.profileCmsId == profileCmsId;
        });
      };
    },
  },
  actions: {
    setCustomerProfilesCountPerDomain(customerProfilesCounts: DomainCount) {
      this.customerProfilesCountPerDomain.push(customerProfilesCounts);
    },
    clearCustomerProfilesCountPerDomain() {
      this.customerProfilesCountPerDomain = [];
    },
    resetActiveProfiles() {
      this.activeCustomerProfile = null;
      this.activeEntertainmentProfile = null;
    },
    async getEntertainmentProfile({
      entertainmentProfileId,
      domain,
      entertainmentProfileUuid,
    }: {
      entertainmentProfileId: string | number;
      domain: string;
      entertainmentProfileUuid: string;
    }): Promise<void> {
      const profileRepository = new ProfileRepository(domain);
      const profile = await profileRepository.getEntertainmentProfile(entertainmentProfileId, entertainmentProfileUuid);

      this.activeEntertainmentProfile = profile;
    },
    async getGeoFreeEntertainmentProfileLocation({
      entertainmentProfileId,
      domain,
      countryCode,
      customerLat,
      customerLng,
    }: GeoFreeLocationApiParams): Promise<void> {
      const profileRepository = new ProfileRepository(domain);
      const geoFreeLocation = await profileRepository.getGeoFreeEntertainmentProfileLocation(
        entertainmentProfileId,
        countryCode,
        customerLat,
        customerLng,
      );

      this.geoFreeEntertainmentProfileLocation = geoFreeLocation;
    },
    updateActiveEntertainmentProfile(profile: Profile): Profile {
      this.activeEntertainmentProfile = profile;
      return profile;
    },
    async getPokeEntertainmentProfile({
      entertainmentProfileId,
      domain,
      entertainmentProfileUuid,
    }: {
      entertainmentProfileId: string;
      domain: string;
      entertainmentProfileUuid: string;
    }): Promise<Profile> {
      const profileRepository = new ProfileRepository(domain);
      const profile = await profileRepository.getPokeEntertainmentProfile(
        entertainmentProfileId,
        domain,
        entertainmentProfileUuid,
      );
      this.activeEntertainmentProfile = profile;
      return profile;
    },
    async getCustomerProfile({
      customerProfileId,
      customerUuid,
      domain,
    }: {
      customerProfileId: string;
      customerUuid: string;
      domain: string;
    }) {
      const profileRepository = new ProfileRepository(domain);
      const profile = await profileRepository.getCustomerProfile({ customerProfileId, customerUuid });
      this.activeCustomerProfile = profile;
    },
    async getCustomerCountsFromMessageCentral({
      domainsPerMessageCentral,
      filters,
      profileId,
      profileUuid,
    }: {
      domainsPerMessageCentral: Record<string, string[]>;
      filters: Record<string, any>;
      profileId: string;
      profileUuid: string;
    }): Promise<any> {
      await Promise.all(
        Object.values(domainsPerMessageCentral).map(async (domainNames: string[]) => {
          const profileRepository = new ProfileRepository(domainNames[0]);
          const countPerMessageCentral = await profileRepository.getCustomerProfilesCount({
            domainNames,
            filters,
            profileId,
            profileUuid,
          });
          for (const [domainName, count] of Object.entries(countPerMessageCentral)) {
            this.setCustomerProfilesCountPerDomain({
              domainName,
              count,
            } as DomainCount);
          }
        }),
      );
    },
    async getCustomerCountsFromProfileAPI({
      domainNames,
      filters,
      profileId,
      profileUuid,
    }: {
      domainNames: string[];
      filters: Record<string, any>;
      profileId: string;
      profileUuid: string;
    }): Promise<any> {
      const countPerDomain = await new ProfileApiConnector().getCustomerProfilesCount({
        domainNames,
        filters,
        profileId,
        profileUuid,
      });

      for (const [domainName, count] of Object.entries(countPerDomain)) {
        this.setCustomerProfilesCountPerDomain({
          domainName,
          count,
        } as DomainCount);
      }
    },
    async getLegacyCounts({
      domains,
      filters,
      profile,
    }: {
      domains: string[];
      filters: Record<string, any>;
      profile: Profile;
    }): Promise<any> {
      this.loadingCounts = true;
      this.clearCustomerProfilesCountPerDomain();

      const domainsConfigurations: Record<string, DomainConfiguration> = Object.fromEntries(
        domains.map((domain: string) => [
          domain,
          useConfigStore().domains.find((domainConfig: DomainConfiguration) => domainConfig.domain === domain),
        ]),
      );

      const haveAllDomainsProfileApiIntegration = Object.values(domainsConfigurations).every(
        (domainsConfiguration) => domainsConfiguration.profileApiEnabled,
      );
      if (haveAllDomainsProfileApiIntegration) {
        await this.getCustomerCountsFromProfileAPI({
          domainNames: domains,
          filters,
          profileId: profile?.uuid,
          profileUuid: profile?.uuid,
        });
        this.loadingCounts = false;
        return;
      }

      const haveCombinedIntegrations = haveAllDomainsProfileApiIntegration
        ? false
        : Object.values(domainsConfigurations).some((domainsConfiguration) => domainsConfiguration.profileApiEnabled);

      if (haveCombinedIntegrations) {
        const profileApiDomains = Object.values(domainsConfigurations)
          .filter((domainsConfiguration: DomainConfiguration) => domainsConfiguration.profileApiEnabled)
          .map((domainsConfiguration: DomainConfiguration) => domainsConfiguration.domain);
        await this.getCustomerCountsFromProfileAPI({
          domainNames: profileApiDomains,
          filters,
          profileId: profile?.uuid,
          profileUuid: profile?.uuid,
        });
      }

      // at least one domain is not ProfileAPI-based, thus we have to use MessageCentral
      const domainsPerMessageCentral = Object.entries(domainsConfigurations).reduce(
        (
          domainsPerMessageCentral: any,
          [domainName, domainConfiguration]: [domainName: string, domainConfig: DomainConfiguration],
        ) => {
          if (domainConfiguration.profileApiEnabled) {
            return domainsPerMessageCentral;
          }
          domainsPerMessageCentral[domainConfiguration.messageCentralUrl] ||= [];
          domainsPerMessageCentral[domainConfiguration.messageCentralUrl].push(domainName);
          return domainsPerMessageCentral;
        },
        {},
      );
      await this.getCustomerCountsFromMessageCentral({
        domainsPerMessageCentral,
        filters,
        profileId: profile?.profileCmsId || profile.id,
        profileUuid: profile?.uuid,
      });

      this.loadingCounts = false;
    },
    async getCustomerCounts({
      origin,
      domains,
      filters,
      profile,
    }: {
      origin: string;
      domains: string[];
      filters: Record<string, any>;
      profile: Profile;
      locale: string;
    }): Promise<void> {
      this.clearCustomerProfilesCountPerDomain();

      await this.getLegacyCounts({
        domains,
        filters,
        profile,
      });
    },
    async searchCustomerProfilesByName({ sites, filters, profileCmsId }: any): Promise<any> {
      if (!sites || sites.length === 0) {
        return [];
      }

      const profileRepository = new ProfileRepository(sites[0]);
      return await profileRepository.searchCustomerProfilesByName({
        sites,
        filters,
        profileCmsId,
      });
    },
    async searchCustomersByName({ origin, sites, filters, profileCmsId, locale }: any) {
      if (origin === "legacy") {
        return await this.searchCustomerProfilesByName({
          sites,
          filters,
          profileCmsId,
          locale,
        });
      }

      const site = sites[0] || "";
      const profileRepository = new ProfileRepository(site);
      return profileRepository.searchCustomerProfilesByName(filters);
    },
    setDomainCustomerProfilesCount(selectedTargetsPerDomain: any[]): void {
      this.loadingCounts = true;
      this.clearCustomerProfilesCountPerDomain();

      selectedTargetsPerDomain.forEach((domainTarget) => {
        this.setCustomerProfilesCountPerDomain({
          domainName: domainTarget.domain,
          count: domainTarget.targets.length,
        } as DomainCount);
      });

      this.loadingCounts = false;
    },
    clearDomainCustomerProfilesCount(): void {
      this.loadingCounts = true;
      this.clearCustomerProfilesCountPerDomain();
      this.loadingCounts = false;
    },
    /**
     * Retrieves entertainment profiles from the Profile API and stores them in the store state.
     *
     * @param {string[]} locales - An array of locales for the requested profiles.
     * @param {string[]} profileTypes - An array of profile types.
     * @param {string[]} domains - The selected domains for which profiles are requested.
     * @param {string} query - The search query.
     * @param {any} filters - Filters for the profiles.
     * @param {number} page - The page number for pagination.
     * @param {number} limit - The maximum number of profiles to retrieve.
     * @returns {Promise<void>} - A Promise that resolves when profiles are fetched and stored.
     */
    async getEntertainmentProfilesFromProfileAPI({
      locales,
      profileTypes,
      domains,
      query,
      filters,
      page,
      limit,
    }: {
      locales: string[];
      profileTypes: string[];
      domains: string[];
      query: string;
      filters: any;
      page: number;
      limit: number;
    }): Promise<Profile[]> {
      this.playersPerDomain = {};

      const players = await new ProfileApiConnector().getEntertainmentProfiles({
        query,
        filters,
        page,
        limit,
        locales,
        profileTypes,
      });

      domains.forEach((domain: string) => {
        this.playersPerDomain[domain] = players as any[];
      });

      return players;
    },
    /**
     * Retrieves entertainment profiles from Message Central and stores them in the store state for selected domains.
     *
     * @param {string[]} domains - The selected domains for which profiles are requested.
     * @param {string} query - The search query.
     * @param {any} filters - Filters for the profiles.
     * @param {number} page - The page number for pagination.
     * @param {number} limit - The maximum number of profiles to retrieve per domain.
     * @returns {Promise<void>} - A Promise that resolves when profiles are fetched and stored for all selected domains.
     */
    async getEntertainmentProfilesFromMessageCentral({
      domains,
      query,
      filters,
      page,
      limit,
    }: {
      domains: string[];
      query: string;
      filters: any;
      page: number;
      limit: number;
      locales: string[];
    }): Promise<Profile[]> {
      this.playersPerDomain = {};

      const playersPerDomains = await Promise.all(
        domains.map(async (domain: string) => {
          const players = await new MessageCentralProfileConnector(domain).getEntertainmentProfiles({
            query,
            filters,
            page,
            limit,
          });
          this.playersPerDomain[domain] = players as any[];
          return players;
        }),
      );
      const uniquePlayers = _intersectionBy(...playersPerDomains, (player: any) => +player.profileCmsId);
      return uniquePlayers;
    },
  },
  debounce: {
    getCustomerCounts: 1000,
  },
});
