import { initializeApp, getApp, getApps, deleteApp, FirebaseApp } from "firebase/app";
import { getAuth, signInWithCustomToken, Auth, User, onAuthStateChanged, UserCredential } from "firebase/auth";
import { getAnalytics, isSupported, setUserId, setUserProperties, Analytics } from "firebase/analytics";
import axios from "axios";
import { Repository } from "@/types";

import { ChatRepository } from "./ChatRepository.class";
import { PokeRepository } from "./PokeRepository.class";
import { QualityRepository } from "./QualityRepository.class";
import { AbuseMessagesRepository } from "./AbuseMessagesRepository.class";
import { AbusePokesRepository } from "./AbusePokesRepository.class";
import { AbuseHistoryRepository } from "./AbuseHistoryRepository.class";
import { LogRepository } from "./LogRepository.class";
import { MessageRepository } from "./MessageRepository.class";
import { AnnouncementsRepository } from "./AnnouncementsRepository.class";
import { ReportedMessagesRepository } from "./ReportedMessagesRepository.class";

import { AbuseWordsRepository } from "./AbuseWordsRepository.class";
import { UserRepository } from "./UserRepositoryClass";
import { SystemNotificationRepository } from "./SystemNotificationRepository.class";
import { useConfigStore } from "@/stores/config";
import http from "@/services/http";

export type FirebaseRepoName =
  | "chat"
  | "poke"
  | "quality"
  | "abuseMessages"
  | "abusePokes"
  | "abuseHistory"
  | "announcements"
  | "message"
  | "log"
  | "reportedMessages";

class FirebaseRepoManager {
  private _repositories = {
    chat: {} as Record<string, ChatRepository>,
    poke: {} as Record<string, PokeRepository>,
    abuseMessages: {} as Record<string, AbuseMessagesRepository>,
    abusePokes: {} as Record<string, AbusePokesRepository>,
    abuseHistory: {} as Record<string, AbuseHistoryRepository>,
    announcements: {} as Record<string, AnnouncementsRepository>,
    log: {} as Record<string, LogRepository>,
    message: {} as Record<string, MessageRepository>,
    reportedMessages: {} as Record<string, ReportedMessagesRepository>,
    quality: {} as Record<string, QualityRepository>,
  };

  private regions: { [region: string]: FirebaseApp };
  private central: FirebaseApp;
  private localeMapping: { [locale: string]: string };

  private _rootRepositories = {
    abuseWords: {} as AbuseWordsRepository,
    systemNotification: {} as SystemNotificationRepository,
    user: {} as UserRepository,
  };

  public teardown() {
    this.disableListeners("chat");
    this.disableListeners("message");
    this.disableListeners("log");
    this.disableListeners("poke");
    this.disableListeners("quality");
    this.disableListeners("abuseMessages");
    this.disableListeners("abusePokes");
    this.disableListeners("abuseHistory");
    this.disableListeners("announcements");
    this.disableListeners("reportedMessages");
    this._rootRepositories["user"].disableAllListeners?.();
    this._rootRepositories["abuseWords"].disableAllListeners?.();

    // remove initialized regional firebase apps
    const { regional } = useConfigStore().variables;
    Object.keys(regional || {}).map(async (region) => {
      const config = regional[region];
      const app = getApp(config.projectId);
      deleteApp(app);
    });

    // use timeout because disableListeners don't finish work within this function
    setTimeout(() => {
      // cleanup all locales to support correct re-login to a user with different locales
      Object.keys(this._repositories).forEach((repoKey: string) => ((this._repositories as any)[repoKey] = {}));
    }, 0);
  }

  private async buildUp(user: User) {
    await this.signInAllRegions(user);

    const { claims } = await user.getIdTokenResult();
    const locales = claims.geos as string[];
    await this.fetchMapping(locales);

    locales.forEach((locale: string) => {
      const project = getApp(this.localeMapping[locale]);
      const chatRepo = new ChatRepository(user, project, locale);
      const pokeRepo = new PokeRepository(user, project, locale);
      const qualityRepo = new QualityRepository(user, project, locale);
      const messageRepository = new MessageRepository(user, project, locale);
      const logRepository = new LogRepository(user, project, locale);
      const abuseMessages = new AbuseMessagesRepository(user, project, locale);
      const abusePokes = new AbusePokesRepository(user, project, locale);
      const abuseHistory = new AbuseHistoryRepository(user, project, locale);
      const announcements = new AnnouncementsRepository(user, project, locale);
      const reportedMessages = new ReportedMessagesRepository(user, project, locale);

      this._repositories["chat"][locale] = chatRepo;
      this._repositories["poke"][locale] = pokeRepo;
      this._repositories["quality"][locale] = qualityRepo;
      this._repositories["abuseMessages"][locale] = abuseMessages;
      this._repositories["abusePokes"][locale] = abusePokes;
      this._repositories["abuseHistory"][locale] = abuseHistory;
      this._repositories["announcements"][locale] = announcements;
      this._repositories["message"][locale] = messageRepository;
      this._repositories["log"][locale] = logRepository;
      this._repositories["reportedMessages"][locale] = reportedMessages;
    });

    this._rootRepositories["abuseWords"] = new AbuseWordsRepository({ user, claims }, this.CentralFirebase);
    this._rootRepositories["systemNotification"] = new SystemNotificationRepository(user, this.CentralFirebase);
    this._rootRepositories["user"] = new UserRepository(user, this.CentralFirebase);
    useConfigStore().setConfigInitialized();
  }

  /**
   * Sign in a user to all Firebase regions.
   *
   * @param {User} user - The user to sign in.
   *
   * @returns {Promise<void>} - A Promise that resolves when all sign-in requests are complete.
   */
  private async signInAllRegions(user: User): Promise<void> {
    const signInPromises = Object.values(this.regions).map(async (firebaseApp) => {
      const customToken = await this.createCustomToken(user, firebaseApp.name);
      return this.signInAtFirebaseProject(customToken, firebaseApp);
    });
    await Promise.all(signInPromises);
  }

  private async fetchMapping(locales: string[]) {
    const apiUrl = process.env.VUE_APP_API_BASE_ENDPOINT;

    const { data } = await http.get(`${apiUrl}/firebase/projects/locales`, {
      params: { locales },
    });

    this.localeMapping = data;
  }

  private fetchRegions() {
    const { regional, central } = useConfigStore().variables;

    const initializedApps = getApps().map((item) => item.name);

    this.regions = Object.entries(regional).reduce((acc, [region, config]: any) => {
      if (!config.projectId) return acc;
      if (initializedApps.includes(config.projectId)) return acc;
      acc[region] = initializeApp(config, config.projectId);
      return acc;
    }, {} as any);

    this.central = initializedApps.includes("[DEFAULT]") ? getApp("[DEFAULT]") : initializeApp(central, "[DEFAULT]");
  }

  private async createCustomToken(user: User, projectId: string) {
    const { token } = await user.getIdTokenResult();
    const projectCredentialsEndpoint = `${process.env.VUE_APP_API_BASE_ENDPOINT}/firebase/token/${projectId}`;
    const response = await axios.get<any>(projectCredentialsEndpoint, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response?.data?.token) {
      return response.data.token;
    }
  }

  /**
   * Sign in a user to a Firebase project using a custom token.
   *
   * @param {string} token - The custom token to use for authentication.
   * @param {FirebaseApp} project - The Firebase project to sign in to.
   *
   * @returns {Promise<UserCredential>} - A Promise that resolves with a user credential object when the sign-in request is complete.
   */
  private async signInAtFirebaseProject(token: string, project: FirebaseApp): Promise<UserCredential> {
    const auth = getAuth(project);
    return signInWithCustomToken(auth, token);
  }

  public get CentralFirebase(): FirebaseApp {
    return this.central;
  }

  public get Auth(): Auth {
    return getAuth(this.central);
  }

  public get Analytics(): Analytics {
    return getAnalytics(this.central);
  }

  public get AbuseWords(): AbuseWordsRepository {
    return this._rootRepositories["abuseWords"];
  }

  public get SystemNotification(): SystemNotificationRepository {
    return this._rootRepositories["systemNotification"];
  }

  public async init(): Promise<void> {
    this.fetchRegions();

    await new Promise<void>((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        this.Auth,
        async (user) => {
          unsubscribe();
          if (!user) {
            return;
          }

          const { claims } = await user.getIdTokenResult();
          const { companyName } = claims;
          if (isSupported()) {
            setUserId(this.Analytics, user.uid);
            setUserProperties(this.Analytics, {
              company_name: companyName,
            });
          }
          await this.buildUp(user);
          this._rootRepositories["user"].enableListeners();
          resolve();
        },
        reject,
      );
    });
  }

  public async dispatch(type: FirebaseRepoName, locale: string, action: string, params: any): Promise<any> {
    if (locale === "all" || locale === "*") {
      Object.entries(this._repositories[type]).forEach(([_geo, repo]) => {
        repo.dispatch(action as never, params);
      });
      return;
    }
    if (!locale) {
      return;
    }
    if (!this._repositories[type][locale]) {
      return;
    }

    return await this._repositories[type][locale].dispatch(action as never, params);
  }

  public enableListeners(repoName: keyof FirebaseRepoManager["_repositories"], options?: any): void {
    this.enableListenersForRepos(Object.values(this._repositories[repoName]) as Repository[], options);
  }
  public enableRootListener(repoName: keyof FirebaseRepoManager["_rootRepositories"], options?: any): void {
    this.enableListenersForRepos([this._rootRepositories[repoName]] as Repository[], options);
  }
  private enableListenersForRepos(repos: Repository[], options?: any): void {
    repos.forEach((repo) => {
      repo.enableListeners(options);
    });
  }

  public disableListeners(repoName: keyof FirebaseRepoManager["_repositories"]): void {
    this.disableListenersForRepos(Object.values(this._repositories[repoName]) as Repository[]);
  }
  public disableRootListener(repoName: keyof FirebaseRepoManager["_rootRepositories"]): void {
    this.disableListenersForRepos([this._rootRepositories[repoName]] as Repository[]);
  }
  private disableListenersForRepos(repos: Repository[]): void {
    repos.forEach((repo) => {
      repo.disableAllListeners?.();
    });
  }
}

export const firebaseRepoManager = new FirebaseRepoManager();
