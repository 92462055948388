
import { defineComponent } from "vue";
import { ChatDocument, Roles } from "@/types";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import HeaderProfileToolbar from "./HeaderProfileToolbar.vue";
import ChatFloatingPanel from "@/components/chat/ChatFloatingPanel.vue";
import LogbookFloatingPanel from "@/components/chat/LogbookFloatingPanel.vue";
import { mapState } from "pinia";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useNotificationStore } from "@/stores/notifications";
import { useConfigStore } from "@/stores/config";
import { useChatStore } from "@/stores/chat";

type MenuItem = {
  id: string;
  link: string;
  roles: Roles[];
  title?: string;
  icon?: string;
};

export default defineComponent({
  components: { HeaderProfileToolbar, ChatFloatingPanel, LogbookFloatingPanel },
  data() {
    return {
      drawer: false,
      menuItems: [
        {
          link: "/chat",
          title: "Chat",
          roles: [Roles.Admin, Roles.Operator],
          id: "chatButton",
        },
        {
          link: "/pokes",
          title: "Poke",
          roles: [Roles.Admin, Roles.Poker],
          id: "pokeButton",
        },
        {
          link: "/abuse",
          title: "Abuse",
          roles: [Roles.Admin, Roles.AbuseChecker],
          id: "abuseButton",
        },
        {
          link: "/announcements",
          title: "Notices",
          roles: [Roles.Admin],
          id: "announcementsButton",
        },
        {
          link: "/statistics",
          title: "Statistics",
          roles: [Roles.Admin, Roles.Manager, Roles.Poker, Roles.AbuseChecker],
          id: "statisticsButton",
        },
        {
          link: "/trigger",
          icon: "mdi-message-settings",
          roles: [Roles.Admin],
          id: "triggerButton",
        },
        {
          link: "/debug",
          icon: "mdi-bug",
          roles: [Roles.Developer],
          id: "debugButton",
        },
      ] as MenuItem[],
    };
  },
  computed: {
    ...mapState(useAuthenticatorStore, ["user", "isUserSessionAuthorized"]),
    ...mapState(useChatStore, ["claimedChat"]),
    isSmallDevice(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isChatPage(): boolean {
      return this.$route.path.startsWith("/chat");
    },
    imgSrc(): string {
      return useConfigStore().getLogoImageFilename("-white");
    },
  },
  watch: {
    claimedChat: {
      handler(newClaimedChat: ChatDocument, oldClaimedChat: ChatDocument): void {
        if (newClaimedChat) {
          useNotificationStore().showClaimedChatNotification();
          return;
        }
        useNotificationStore().resetClaimedChatNotification();
        if (!this.isChatPage) {
          firebaseRepoManager.dispatch("chat", oldClaimedChat?.locale, "disableClaimedChatListener", null);
        }
      },
      immediate: true,
    },
  },
});
