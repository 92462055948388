
import { defineComponent } from "vue";
import NotificationSnackbar from "./components/NotificationSnackbar.vue";
import { MetaInfo } from "vue-meta";
import { getAnalytics, isSupported, logEvent, setCurrentScreen } from "firebase/analytics";
import { isPWA } from "@/helpers/utility";
import { useConfigStore } from "@/stores/config";

export default defineComponent({
  name: "App",
  metaInfo() {
    const appTitle = useConfigStore().appTitle;
    return {
      title: "App",
      titleTemplate: (chunk: string) => {
        return `${chunk} | ${appTitle}`;
      },
      changed(metaInfo: MetaInfo) {
        if (isSupported()) {
          const analytics = getAnalytics();
          setCurrentScreen?.(analytics, metaInfo.title);
          logEvent<string>(analytics, "screen_view", {
            app_name: isPWA() ? "pwa" : "web",
            screen_name: metaInfo.title,
          });
        }
      },
    };
  },
  components: {
    NotificationSnackbar,
  },
  mounted() {
    useConfigStore().getTenantInfo();
  },
});
