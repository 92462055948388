export const removeEmptyElements = (obj: unknown): any => {
  if (Array.isArray(obj)) {
    obj.forEach((element, index) => obj.splice(index, 1, removeEmptyElements(element)));
    return obj;
  }

  if (typeof obj !== "object") {
    return obj;
  }

  return Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => (Array.isArray(v) ? v.length !== 0 : ![null, undefined, ""].includes(v)))
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyElements(v) : v]),
  );
};
