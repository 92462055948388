import { defineStore } from "pinia";
import { SocketStoreState } from "@/types";
import { useAuthenticatorStore } from "./authenticator";
import { socket } from "@/services/socketService";

export const useSocketStore = defineStore("socket", {
  state: (): SocketStoreState => ({
    isConnected: false,
  }),
  actions: {
    bindEvents() {
      socket.on("connect", () => {
        this.isConnected = true;
      });

      socket.on("disconnect", () => {
        this.isConnected = false;
      });

      socket.on("reconnect_attempt", () => {
        socket.io.opts.transports = ["polling", "websocket"];
      });

      socket.on("disconnectOperator", () => {
        useAuthenticatorStore().signOut();
      });
    },
    connect(token: string): void {
      socket.io.opts.query = {
        token,
      };
      socket.connect();
    },
    disconnect(): void {
      socket.disconnect();
    },
  },
});
