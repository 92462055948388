import { PokeMessageParsedItem } from "@/types";

export function parseForVariables(message: string, availableTargetVariables: string[]): PokeMessageParsedItem[] {
  if (!message) return [];

  const openBracketRegex = /%(\s|\.)*\[/; // %[ or % [
  const closeBracketRegex = /\](\s|\.)*%/; // ]% or ] %

  const damagedOpenBracketVariableRegex = /((%|\[).*?\]\s*%)/gi;
  const damagedCloseBracketVariableRegex = /(%\s*\[.*?(\]|%))/gi;
  const removeSpaces = (input: string) => input.replace(/\s*/gm, "");

  const messageItems: PokeMessageParsedItem[] = [];
  const hasLastItemEndOfSentence = () => {
    if (messageItems.length === 0) {
      return true;
    }
    if (messageItems.at(-1).type === "var") {
      return false;
    }
    return messageItems.at(-1).original.trim().endsWith(".");
  };

  const finalize = () => {
    const original = message;
    if (original?.length > 0) {
      messageItems.push({
        type: "text",
        original: message,
        hasSentenceBegin: hasLastItemEndOfSentence(),
      });
    }
    message = "";
  };

  let isInsideBracket = false;
  let isDamagedOpenBracket = false;
  let hasSentenceBegin = true;
  while (message.length > 0) {
    if (isInsideBracket) {
      if (closeBracketRegex.test(message)) {
        isInsideBracket = false;
        const openBracketLength = isDamagedOpenBracket ? 1 : message.match(openBracketRegex)[0].length;
        const closeBracketMatch = message.match(closeBracketRegex);
        const closeBracketIndex = closeBracketMatch.index;
        const closeBracketLength = closeBracketMatch[0].length;
        const original = message.substring(0, closeBracketIndex + closeBracketLength);
        const varName = message.substring(openBracketLength, closeBracketIndex);
        const fixedVarName = removeSpaces(varName);
        const isValid = availableTargetVariables.includes(`%[${fixedVarName}]%`);
        const isBroken =
          isDamagedOpenBracket || varName !== fixedVarName || openBracketLength > 2 || closeBracketLength > 2;
        messageItems.push({ type: "var", original, fixedVarName, isValid, isBroken, hasSentenceBegin });
        message = message.substring(closeBracketIndex + closeBracketLength);
      } else if (damagedCloseBracketVariableRegex.test(message)) {
        // find damaged close bracket
        isInsideBracket = false;
        const openBracketLength = message.match(openBracketRegex)[0].length;
        const messageWithoutOpenBracket = message.substring(openBracketLength);
        const closeBracketMatch = messageWithoutOpenBracket.match(/\]|%/);
        const closeBracketIndex = closeBracketMatch.index + openBracketLength;
        const closeBracketLength = 1;
        const original = message.substring(0, closeBracketIndex + closeBracketLength);
        const varName = message.substring(openBracketLength, closeBracketIndex);
        const fixedVarName = removeSpaces(varName);
        const isValid = availableTargetVariables.includes(`%[${fixedVarName}]%`);
        const isBroken = true;
        messageItems.push({ type: "var", original, fixedVarName, isValid, isBroken, hasSentenceBegin });
        message = message.substring(closeBracketIndex + closeBracketLength);
      } else {
        finalize();
      }
    } else {
      // outside of the brackets
      if (openBracketRegex.test(message)) {
        isInsideBracket = true;
        isDamagedOpenBracket = false;
        const openBracketMatch = message.match(openBracketRegex);
        const openBracketIndex = openBracketMatch.index;
        const original = message.substring(0, openBracketIndex);
        if (original?.length > 0) {
          messageItems.push({ type: "text", original, hasSentenceBegin });
          message = message.substring(openBracketIndex);
        }
      } else if (damagedOpenBracketVariableRegex.test(message)) {
        // find damaged open bracket
        isInsideBracket = true;
        isDamagedOpenBracket = true;
        const openBracketMatch = message.match(/%|\[/);
        const openBracketIndex = openBracketMatch.index;
        const original = message.substring(0, openBracketIndex);
        if (original?.length > 0) {
          messageItems.push({ type: "text", original, hasSentenceBegin });
          message = message.substring(openBracketIndex);
        }
      } else if (closeBracketRegex.test(message) && messageItems[messageItems.length - 1]?.type === "var") {
        // find one more close bracket after a close bracket
        const closeBracketMatch = message.match(closeBracketRegex);
        const closeBracketIndex = closeBracketMatch.index;
        const closeBracketLength = closeBracketMatch[0].length;
        const original = message.substring(0, closeBracketIndex + closeBracketLength);
        const varName = message.substring(0, closeBracketIndex);
        const fixedVarName = removeSpaces(varName);
        const lastVar = messageItems[messageItems.length - 1];
        lastVar.original = `${lastVar.original}${original}`;
        lastVar.fixedVarName = `${lastVar.fixedVarName}${fixedVarName}`;
        lastVar.isValid = false;
        lastVar.isBroken = false;
        message = message.substring(closeBracketIndex + closeBracketLength);
      } else {
        finalize();
      }
    }

    hasSentenceBegin = hasLastItemEndOfSentence();
  }

  return messageItems;
}
