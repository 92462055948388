import { AnnouncementsTopic, Announcement, Repository } from "@/types";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  where,
  doc,
  getDocs,
  addDoc,
  deleteDoc,
  onSnapshot,
  Timestamp,
  CollectionReference,
  collectionGroup,
  orderBy,
  QuerySnapshot,
  Query,
  QueryDocumentSnapshot,
  DocumentReference,
  setDoc,
} from "firebase/firestore";
import { useAuthenticatorStore } from "@/stores/authenticator";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { useAnnouncementsStore } from "@/stores/announcements";

export class AnnouncementsRepository implements Repository {
  private _announcementsTopicsCollection: CollectionReference<AnnouncementsTopic>;
  private _announcementsCollectionGroup: Query;
  private _announcementsUnsubscribeFunction: () => void;
  private _announcementsTopicsUnsubscribeFunction: () => void;
  private initialized = false;

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    const firestore = getFirestore(this._project);
    this._announcementsTopicsCollection = new FirestoreReferenceGenerator(
      this._project,
      this._locale,
    ).getAnnouncementsTopicsCollectionRef();
    this._announcementsCollectionGroup = query(
      collectionGroup(firestore, "announcements"),
      where("locale", "==", this._locale),
      orderBy("updatedAt", "asc"),
    );
  }

  public enableListeners() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    useAnnouncementsStore().announcementsPerLocale = {};
    useAnnouncementsStore().announcementsTopics = {};
    this._announcementsUnsubscribeFunction = onSnapshot(
      this._announcementsCollectionGroup,
      this.constructChangeHandler(
        "announcement",
        useAnnouncementsStore().setAnnouncement,
        useAnnouncementsStore().removeAnnouncement,
      ),
    );
    this._announcementsTopicsUnsubscribeFunction = onSnapshot(
      this._announcementsTopicsCollection,
      this.constructChangeHandler(
        "topic",
        useAnnouncementsStore().setAnnouncementTopic,
        useAnnouncementsStore().removeAnnouncementTopic,
      ),
    );
  }

  public async dispatch(
    action:
      | "enableListeners"
      | "getAnnouncements"
      | "updateAnnouncementsTopic"
      | "updateAnnouncement"
      | "removeAnnouncementsTopic"
      | "removeAnnouncement"
      | "newAnnouncementsTopic"
      | "newAnnouncement"
      | "disableAllListeners",
    params: any,
  ): Promise<void> {
    await this[action]?.(params);
  }

  public async updateAnnouncementsTopic(topic: AnnouncementsTopic) {
    const docRef = doc(this._announcementsTopicsCollection, topic.id);
    await setDoc(
      docRef,
      {
        title: topic.title,
        emoji: topic.emoji,
      },
      { merge: true },
    );
  }

  public async updateAnnouncement({ topicId, id, message }: Pick<Announcement, "topicId" | "id" | "message">) {
    const docRef = doc(
      this._announcementsTopicsCollection,
      `${topicId}/announcements/${id}`,
    ) as unknown as DocumentReference<Announcement>;

    await setDoc(
      docRef,
      {
        message,
        updatedAt: Timestamp.now(),
        updatedBy: useAuthenticatorStore().user.uid,
      },
      { merge: true },
    );
  }

  public async removeAnnouncementsTopic(topicId: string) {
    const announcementsDocRef = doc(this._announcementsTopicsCollection, topicId);
    const announcementsCollection = collection(announcementsDocRef, "announcements");
    const announcementsByTopicSnapshot = await getDocs(announcementsCollection);
    announcementsByTopicSnapshot.forEach(async (doc: any) => {
      await this.removeAnnouncement({ topicId, id: doc.id });
    });
    const docRef = doc(this._announcementsTopicsCollection, topicId);
    return await deleteDoc(docRef);
  }

  public async removeAnnouncement({ topicId, id }: { topicId: string; id: string }) {
    const docRef = doc(this._announcementsTopicsCollection, `${topicId}/announcements/${id}`);
    return await deleteDoc(docRef);
  }

  async newAnnouncementsTopic({ title, emoji }: { title: string; emoji: string }) {
    await addDoc(this._announcementsTopicsCollection, { title, emoji, locale: this._locale });
  }

  async newAnnouncement({ topicId, message }: { topicId: string; message: string }) {
    const announcementsDocument = doc(this._announcementsTopicsCollection, topicId);
    const announcementsCollection = collection(announcementsDocument, "announcements");
    await addDoc(announcementsCollection, {
      locale: this._locale,
      message,
      createdBy: this._user.uid,
      createdAt: Timestamp.now(),
      updatedBy: this._user.uid,
      updatedAt: Timestamp.now(),
    });
  }

  public disableAllListeners() {
    this._announcementsUnsubscribeFunction?.();
    this._announcementsTopicsUnsubscribeFunction?.();
    this.initialized = false;
  }

  private constructChangeHandler(type: string, modifyFunction: (doc: any) => void, removeFunction: (doc: any) => void) {
    return (snapshot: QuerySnapshot) => {
      snapshot.docChanges().forEach((docChange) => {
        const document = {
          locale: this._locale,
          ...(type === "announcement" ? { topicId: docChange.doc.ref.path.split("/")[3] } : {}),
          id: docChange.doc.id,
          ...docChange.doc.data(),
        } as AnnouncementsTopic | Announcement;

        docChange.type === "removed" ? removeFunction(document) : modifyFunction(document);
      });
    };
  }

  public async getAnnouncements() {
    const announcementsTopicsSnapshot = await getDocs(this._announcementsTopicsCollection);
    const announcementsSnapshot = await getDocs(this._announcementsCollectionGroup);
    if (announcementsTopicsSnapshot.size === 0) {
      useAnnouncementsStore().cleanAnnouncementsByLocale(this._locale);
      useAnnouncementsStore().cleanAnnouncementsTopicsByLocale(this._locale);
      return;
    }
    announcementsTopicsSnapshot.forEach((doc: QueryDocumentSnapshot) => {
      useAnnouncementsStore().setAnnouncementTopic({
        locale: this._locale,
        id: doc.id as Branded<string, "AnnouncementsTopicId">,
        ...(doc.data() as AnnouncementsTopic),
      });
    });
    announcementsSnapshot.forEach((doc: QueryDocumentSnapshot) => {
      useAnnouncementsStore().setAnnouncement({
        locale: this._locale,
        topicId: doc.ref.path.split("/")[3],
        id: doc.id as Branded<string, "AnnouncementId">,
        ...(doc.data() as Announcement),
      });
    });
  }
}
