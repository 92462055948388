import { QueryStringObject } from "@/types";

/**
 * Turns an object into query string parameters
 *
 * @param object
 * @returns string
 */
export function buildQueryStringFromObject(object: QueryStringObject): string {
  return Object.keys(object)
    .map((key) => key + "=" + object[key])
    .join("&");
}
