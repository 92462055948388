import axios from "axios";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const baseURL = process.env.VUE_APP_API_BASE_ENDPOINT;

const Repository = axios.create({
  baseURL,
});

Repository.interceptors.request.use(
  async (config) => {
    const firebaseApp = getApp();
    const token = await getAuth(firebaseApp).currentUser?.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default class AttachmentsRepository {
  async getSignedAttachment(hash: string): Promise<string> {
    const response = await Repository.post(`/url/sign`, { url: hash });
    if (!response.data) {
      throw new Error("Can't get the attachment url for hash: " + hash);
    }
    return response.data.data as string;
  }

  async getSignedAttachments(hashes: string[]): Promise<Record<string, string>> {
    const response = await Repository.post(`/url/sign-bulk`, { urls: hashes, with_keys: true });
    if (!response.data) {
      throw new Error("Can't get attachments urls for hashes: " + hashes);
    }
    return response.data.data as Record<string, string>;
  }
}
