function getRoles(): string[] {
  return JSON.parse(localStorage.getItem("roles")) || [];
}

export default {
  setRoles: (roles: string[]): void => {
    localStorage.setItem("roles", JSON.stringify(roles));
  },
  hasRole: (role: string): boolean => {
    const userRoles = getRoles();

    return userRoles.includes(role);
  },
  hasAnyRole: (roles: string[]): boolean => {
    const userRoles = getRoles();

    return userRoles.some((role) => roles.includes(role));
  },
};
