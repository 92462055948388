import { defineStore } from "pinia";
import { SystemNotification } from "@/types/index";
import { SystemStoreState } from "@/types";

export const useSystemStore = defineStore("system", {
  state: (): SystemStoreState => ({
    currentSystemNotification: null,
    systemNotifications: [],
  }),
  actions: {
    setCurrentSystemNotification(systemNotification: SystemNotification) {
      if (!systemNotification) {
        this.currentSystemNotification = null;
        return;
      }

      this.currentSystemNotification = structuredClone(systemNotification);
    },
    addSystemNotification(systemNotification: SystemNotification) {
      this.systemNotifications.push(systemNotification);
    },
    resetSystemNotifications() {
      this.systemNotifications = [];
    },
  },
});
