import { Repository, AbuseWord } from "@/types";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  where,
  doc,
  getDocs,
  addDoc,
  deleteDoc,
  onSnapshot,
  CollectionReference,
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentReference,
} from "firebase/firestore";
import { ABUSE_GLOBAL_LOCALE_NAME } from "@/views/abuse/abuseUtility";
import { useAbuseStore } from "@/stores/abuse";

export class AbuseWordsRepository implements Repository {
  private _user: User;
  private _abuseWordsRef: CollectionReference<AbuseWord>;
  private _abuseWordsUnsubscribeFunction: () => void;
  private initialized = false;
  locales: string[];

  constructor({ user, claims }: { user: User; claims: any }, private _project: FirebaseApp) {
    this._user = user;
    this.locales = claims.geos;
    new Date().getMilliseconds();
    const firestore = getFirestore(this._project);
    this._abuseWordsRef = collection(firestore, `abuse_words`) as CollectionReference<AbuseWord>;
  }

  private createAbuseWordsQuery(locale: string) {
    if (locale == null) {
      return this._abuseWordsRef;
    }

    return query(this._abuseWordsRef, where("locale", "==", locale));
  }

  public enableListeners({ locale }: { locale: string }) {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    useAbuseStore().words = [];
    this._abuseWordsUnsubscribeFunction = onSnapshot(this.createAbuseWordsQuery(locale), this.constructChangeHandler());
  }

  public async dispatch(
    action: "enableListeners" | "removeAbuseWord" | "newAbuseWord" | "disableAllListeners",
    params: any,
  ): Promise<void> {
    await this[action]?.(params);
  }

  public async removeAbuseWord({ id }: AbuseWord) {
    const firestore = getFirestore(this._project);
    const docRef = doc(firestore, `abuse_words/${id}`);
    return await deleteDoc(docRef);
  }

  async getExistingDoc(abuseWord: AbuseWord): Promise<QueryDocumentSnapshot> {
    const abuseWordsQuery = query(
      this._abuseWordsRef,
      where("value", "==", abuseWord.value),
      where("locale", "in", ["", ...(abuseWord.locale ? [abuseWord.locale] : [])]),
    );
    const abuseWordsSnapshot = await getDocs(abuseWordsQuery);
    return abuseWordsSnapshot.docs[0];
  }

  async newAbuseWord(abuseWord: AbuseWord): Promise<void | DocumentReference> {
    const existedDoc = await this.getExistingDoc(abuseWord);
    if (existedDoc?.exists()) {
      const { locale } = existedDoc.data();
      throw new Error(
        `Word "${abuseWord.value}" already exists in "${locale || ABUSE_GLOBAL_LOCALE_NAME}" abuse list.`,
      );
    }

    return await addDoc(this._abuseWordsRef, abuseWord);
  }

  public disableAllListeners() {
    this._abuseWordsUnsubscribeFunction?.();
    this.initialized = false;
  }

  private constructChangeHandler() {
    return (snapshot: QuerySnapshot<AbuseWord>) => {
      snapshot.docChanges().forEach((docChange) => {
        const document = {
          id: docChange.doc.id as Branded<string, "AbuseWordId">,
          ...docChange.doc.data(),
        };

        switch (docChange.type) {
          case "added":
            useAbuseStore().addAbuseWord(document);
            break;
          case "modified":
            useAbuseStore().updateAbuseWord(document);
            break;
          case "removed":
            useAbuseStore().removeAbuseWord(document);
            break;
          default:
            break;
        }
      });
    };
  }
}
