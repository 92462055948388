import { ProfileConnector, ProfileConnectorName } from "@/types";
import MessageCentralProfileConnector from "./MessageCentralProfileConnector";
import ProfileApiConnector from "./ProfileApiConnector";
import { useConfigStore } from "@/stores/config";

const connectors = {
  messageCentral: MessageCentralProfileConnector,
  profileApi: ProfileApiConnector,
};

/**
 * A function that takes a domain string and returns a formatted string.
 * @param {string} domain - The domain string to be formatted.
 * @returns {string} - A formatted string based on the input domain string.
 * @throws {TypeError} - If the 'domain' parameter is not a string or is an empty string.
 */
function getConnectorName(domain: string): ProfileConnectorName {
  const domainConfig = useConfigStore().domainConfig(domain);

  return domainConfig?.profileApiEnabled ? "profileApi" : "messageCentral";
}

export const ProfileConnectorFactory = {
  /**
   * A function that returns a ProfileConnector object for the given domain.
   * @param {string} domain - The domain for which a ProfileConnector object is requested.
   * @returns {ProfileConnector} - A ProfileConnector object for the given domain.
   * @throws {TypeError} - If the 'domain' parameter is not a string or is an empty string.
   * @throws {Error} - If no ProfileConnector is available for the given domain.
   * @example
   * const profileConnector = getProfileConnector("example.com");
   * profileConnector.connect();
   */
  get(domain: string): ProfileConnector {
    const name = getConnectorName(domain);

    if (!connectors[name]) {
      throw new Error(`Connector ${name} was not found`);
    }

    return new connectors[name](domain);
  },
};
