import { defineStore } from "pinia";
import { SettingsState } from "@/types";

export const useSettingsStore = defineStore("settings", {
  state: (): SettingsState => ({
    isClaimedChatNotificationEnabled: JSON.parse(localStorage.getItem("isClaimedChatNotificationEnabled")) ?? true,
    claimedChatNotificationTimeoutSeconds:
      JSON.parse(localStorage.getItem("claimedChatNotificationTimeoutSeconds")) ?? 10000,
  }),
  actions: {
    setClaimedChatNotificationEnabled(enabled: boolean) {
      localStorage.setItem("isClaimedChatNotificationEnabled", JSON.stringify(enabled));
      this.isClaimedChatNotificationEnabled = enabled;
    },
    setClaimedChatNotificationTimeoutSeconds(seconds: number) {
      localStorage.setItem("claimedChatNotificationTimeoutSeconds", seconds.toString());
      this.claimedChatNotificationTimeoutSeconds = seconds;
    },
  },
});
