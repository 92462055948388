import axios, { AxiosRequestConfig } from "axios";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_CLAIM_BASE_URL,
});

apiClient.interceptors.request.use(
  async ( config ) => {
    const firebaseApp = getApp();
    const token = await getAuth(firebaseApp).currentUser?.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default {
  operators: {
    async getAll(config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.get("/operators", config);
      return response.data;
    },
    async connected(config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.get("/operators/connected", config);
      return response.data;
    },
  },
  redis: {
    async getKeys(keyPattern: string, config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.get(`/redis/keys/${keyPattern}`, config);
      return response.data;
    },
    async getValuesForKey(key: string, config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.get(`/redis/values/${key}`, config);
      return response.data;
    },
    async deleteKey(key: string, config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.delete(`/redis/keys/${key}`, config);
      return response.data;
    },
    async clearCache(config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.delete(`/redis/cache`, config);
      return response.data;
    },
    async pools(geo: string, tenant: string, config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.get(`/redis/pools/geo/${geo}/tenant/${tenant}`, config);
      return response.data;
    },
    async deleteUserKey(config: AxiosRequestConfig = {}): Promise<any> {
      const response = await apiClient.delete(`/redis/key`, config);
      return response.data;
    },
  },
};
