
import { defineComponent } from "vue";
import { mapState } from "pinia";
import vuetifyColors from "vuetify/lib/util/colors";
import { Colors } from "vuetify/lib/util/colors";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { useChatStore } from "@/stores/chat";

const LAST_SECONDS = 60;
const LAST_CALL_SECONDS = 30;

const levelColors = [
  { color: "blue", hexColor: vuetifyColors.blue.base },
  { color: "orange darken-4", hexColor: vuetifyColors.orange.darken4 },
  { color: "red", hexColor: vuetifyColors.red.base },
] as { color: keyof Colors; hexColor: string }[];

export default defineComponent({
  name: "ChatCountdown",
  props: {
    deadline: {
      type: Date,
      default: null,
    },
    isVisibleOnlyWhenActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["end"],
  data() {
    return {
      intervalId: null,
      startDate: dayjs(),
      timeLeftSeconds: 0,
      levelColorIndex: 0,
    };
  },
  computed: {
    ...mapState(useChatStore, ["claimedChat"]),

    endDateJs(): Dayjs {
      return dayjs(this.deadline || this.claimedChat?.claimExpiresAt?.toDate());
    },
    fromStartToEndSeconds(): number {
      return this.endDateJs.diff(this.startDate, "second");
    },
    timeLeftPercent(): number {
      return 100 * (this.timeLeftSeconds / this.fromStartToEndSeconds);
    },
    timeLeftDisplay(): string {
      if (!this.timeLeftSeconds || this.timeLeftSeconds < 0) {
        return "—";
      }

      const outputSeconds = this.timeLeftSeconds % 60;
      const outputMinutes = (this.timeLeftSeconds - outputSeconds) / 60;
      return `${outputMinutes}:${outputSeconds < 10 ? "0" : ""}${outputSeconds}`;
    },
    isActive(): boolean {
      return this.timeLeftPercent > 0 && this.timeLeftPercent <= 100;
    },
    isVisible(): boolean {
      return !this.isVisibleOnlyWhenActive || this.isActive;
    },
    color(): keyof Colors {
      return levelColors[this.levelColorIndex].color as keyof Colors;
    },
    hexColor(): string {
      return levelColors[this.levelColorIndex].hexColor;
    },
  },
  mounted() {
    this.startDate = dayjs();
    this.tick();
    this.setupCountdownTimer();
  },
  methods: {
    setupCountdownTimer(): void {
      clearInterval(this.intervalId);
      this.intervalId = setInterval(this.tick, 1000);
    },
    tick(): void {
      if (!this.endDateJs) {
        clearInterval(this.intervalId);
        this.timeLeftSeconds = 0;
        this.levelColorIndex = 0;
        return;
      }

      this.timeLeftSeconds = this.endDateJs.diff(dayjs(), "second");
      this.levelColorIndex = this.timeLeftSeconds > LAST_SECONDS ? 0 : this.timeLeftSeconds > LAST_CALL_SECONDS ? 1 : 2;

      if (this.timeLeftSeconds <= 0) {
        clearInterval(this.intervalId);
        this.$emit("end");
      }
    },
  },
});
