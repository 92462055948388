import { defineStore } from "pinia";
import { ReportedMessagesStoreState, ReportedMessage, NotificationTypes } from "@/types";
import { reportedMessagesCategories } from "@/types/categories";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import { useNotificationStore } from "@/stores/notifications";

export const useReportedMessages = defineStore("reportedMessages", {
  state: (): ReportedMessagesStoreState => ({
    reportedMessages: [],
    currentReportedMessage: null,
    firstReportedMessageId: null,
    lastReportedMessageId: null,
    categories: reportedMessagesCategories,
  }),
  getters: {},
  actions: {
    async reportMessageStatus(locale: string, id: string, isProcessed: boolean): Promise<void> {
      try {
        await firebaseRepoManager.dispatch("reportedMessages", locale, "setProcessedStatus", {
          id,
          isProcessed,
        });
      } catch (error) {
        useNotificationStore().addNotification({
          message: `Failed to change the message status.`,
          type: NotificationTypes.Error,
        });
      }
    },
    updateReportedMessage(reportedMessage: ReportedMessage) {
      if (this.currentReportedMessage?.id === reportedMessage?.id) {
        this.currentReportedMessage = { ...reportedMessage };
      }
      const findIndex = this.reportedMessages.findIndex((message) => message.id === reportedMessage.id);
      findIndex >= 0 && this.reportedMessages.splice(findIndex, 1, { ...reportedMessage });
    },
  },
});
