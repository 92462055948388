import { defineStore } from "pinia";
import { AbuseWord, AbuseMessage, AbuseState, PokeDocument, AbuseListItem, NotificationTypes } from "@/types";
import { MINUTES_TO_ABANDON } from "@/views/abuse/abuseUtility";
import { ABUSE_TYPE_MESSAGE, ABUSE_TYPE_POKE } from "@/types/constants";
import { Timestamp } from "firebase/firestore";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import dayjs from "dayjs";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useNotificationStore } from "@/stores/notifications";

function isCreatedBeforeAbandonedTime(uid: string, claimedBy: string, claimedAt: Timestamp): boolean {
  return (
    claimedBy === uid && dayjs(new Date()).isBefore(dayjs(claimedAt.seconds * 1000).add(MINUTES_TO_ABANDON, "minute"))
  );
}

function isAbuseMessage(obj: AbuseMessage | PokeDocument): obj is AbuseMessage {
  return "chat" in obj;
}

export const useAbuseStore = defineStore("abuse", {
  state: (): AbuseState => ({
    initialized: false,
    words: [] as AbuseWord[],
    recipients: {},
    abuseMessages: [] as AbuseMessage[],
    abusePokes: [] as PokeDocument[],
    claimedAbuseMessage: null,
    claimedAbusePoke: null,
    abuseHistoryMessages: [] as AbuseMessage[],
    firstAbuseHistoryMessage: null,
    lastAbuseHistoryMessage: null,
  }),

  getters: {
    alreadyClaimedFlaggedMessage: (state: AbuseState): AbuseMessage => {
      return state.abuseMessages.find((abuseMessage: AbuseMessage) =>
        isCreatedBeforeAbandonedTime(
          useAuthenticatorStore().user.uid,
          abuseMessage.message.abuse.claimedBy,
          abuseMessage.message.abuse.claimedAt,
        ),
      );
    },
    alreadyClaimedFlaggedPoke: (state: AbuseState): PokeDocument => {
      return state.abusePokes.find((abusePoke: PokeDocument) =>
        isCreatedBeforeAbandonedTime(
          useAuthenticatorStore().user.uid,
          abusePoke.abuse.claimedBy,
          abusePoke.abuse.claimedAt,
        ),
      );
    },
    claimedAt: (state: AbuseState): Timestamp | null => {
      if (state.claimedAbuseMessage) {
        return state.claimedAbuseMessage.message.abuse?.claimedAt;
      }

      if (state.claimedAbusePoke) {
        return state.claimedAbusePoke.abuse?.claimedAt;
      }
    },
    uniqueLocalesInAbuseWords: (state: AbuseState): string[] => {
      return [...new Set(state.words.map((word: AbuseWord) => word.locale).filter(Boolean))];
    },
    abuseMessageListItems: (state: AbuseState): AbuseListItem[] => {
      return state.abuseMessages.map((abuseMessage) => {
        return {
          type: ABUSE_TYPE_MESSAGE,
          customer: {
            id: abuseMessage.chat.customer.uid,
            name: abuseMessage.chat.customer.name,
          },
          profile: abuseMessage.chat.profile,
          agent: {
            id: abuseMessage.message.senderId,
            name: abuseMessage.message.senderName,
          },
          id: abuseMessage.chat.id,
          abuse: abuseMessage.message.abuse,
          message: {
            content: abuseMessage.message.content,
            attachment: abuseMessage.message.attachment,
          },
          createdAt: abuseMessage.message.createdAt,
          abuseMessage,
        };
      });
    },
    abusePokeListItems: (state: AbuseState): AbuseListItem[] => {
      return state.abusePokes.map((abusePoke) => {
        return {
          type: ABUSE_TYPE_POKE,
          customer: {
            id: "-",
            name: "-",
          },
          profile: abusePoke.profile,
          agent: abusePoke.agent,
          id: abusePoke.id,
          abuse: abusePoke.abuse,
          message: abusePoke.message,
          createdAt: abusePoke.createdAt,
          abusePoke,
        };
      });
    },
    sortedAbuseQueue:
      (getters: any) =>
      (currentTime?: Date): (AbuseMessage | PokeDocument)[] => {
        return [...getters.sortedFlaggedMessages(currentTime), ...getters.sortedFlaggedPokes(currentTime)];
      },
    nextFlaggedAbuseToCheck(): AbuseMessage | PokeDocument {
      return this.sortedAbuseQueue()[0];
    },
    abuseList(): AbuseListItem[] {
      return [...this.abuseMessageListItems, ...this.abusePokeListItems];
    },
    sortedFlaggedPokes:
      (state: AbuseState) =>
      (currentTime?: Date): PokeDocument[] => {
        const unclaimedFlaggedPokes = state.abusePokes.filter((abusePoke: PokeDocument) => !abusePoke.abuse?.claimedBy);

        const abandonedClaimedPokes = state.abusePokes.filter((abusePoke: PokeDocument) => {
          return (
            abusePoke.abuse?.claimedBy &&
            dayjs(currentTime || new Date()).isAfter(
              dayjs(abusePoke.abuse.claimedAt.seconds * 1000).add(MINUTES_TO_ABANDON, "minute"),
            )
          );
        });
        return [...unclaimedFlaggedPokes, ...abandonedClaimedPokes].sort((a: PokeDocument, b: PokeDocument) => {
          return a.createdAt < b.createdAt ? -1 : 1;
        });
      },
    sortedFlaggedMessages:
      (state: AbuseState) =>
      (currentTime?: Date): AbuseMessage[] => {
        const unclaimedFlaggedMessages = state.abuseMessages.filter(
          (abuseMessage: AbuseMessage) => !abuseMessage.message?.abuse?.claimedBy,
        );

        const abandonedClaimedMessages = state.abuseMessages.filter((abuseMessage: AbuseMessage) => {
          return (
            abuseMessage.message?.abuse?.claimedBy &&
            dayjs(currentTime || new Date()).isAfter(
              dayjs(abuseMessage.message.abuse.claimedAt.seconds * 1000).add(MINUTES_TO_ABANDON, "minute"),
            )
          );
        });
        return [...unclaimedFlaggedMessages, ...abandonedClaimedMessages].sort((a: AbuseMessage, b: AbuseMessage) => {
          return a.message.createdAt < b.message.createdAt ? -1 : 1;
        });
      },
    hasClaimedAbuse(): boolean {
      return Boolean(this.alreadyClaimedFlaggedMessage) || Boolean(this.alreadyClaimedFlaggedPoke);
    },
  },

  actions: {
    init() {
      this.initialized = true;
    },
    updateAbuseWord(abuseWord: AbuseWord) {
      const foundWord: AbuseWord = this.words.find((word: AbuseWord) => word.id === abuseWord.id);
      foundWord ? Object.assign(foundWord, abuseWord) : this.words.push(abuseWord);
    },
    removeAbuseWord(abuseWord: AbuseWord) {
      const index = this.words.map((word: AbuseWord) => word.id).indexOf(abuseWord.id);
      if (index > -1) {
        this.words.splice(index, 1);
      }
    },
    addAbuseMessage(insertingAbuseMessage: AbuseMessage) {
      if (
        this.abuseMessages?.some(
          (abuseMessage: AbuseMessage) => abuseMessage.message.id === insertingAbuseMessage.message.id,
        )
      ) {
        return;
      }

      this.abuseMessages.push(insertingAbuseMessage);
    },
    addAbuseWord(abuseWord: AbuseWord) {
      this.words.push(abuseWord);
    },
    updateAbuseMessage(abuseMessage: AbuseMessage) {
      const existsAtIndex = this.abuseMessages.findIndex(
        (existingAbuseMessage: AbuseMessage) => existingAbuseMessage.message.id === abuseMessage.message.id,
      );

      existsAtIndex > -1 ? (this.abuseMessages[existsAtIndex] = abuseMessage) : this.abuseMessages.push(abuseMessage);

      if (this.claimedAbuseMessage && this.claimedAbuseMessage.message.id === abuseMessage.message.id) {
        this.claimedAbuseMessage = abuseMessage;
      }
    },
    removeAbuseMessage(abuseMessage: AbuseMessage) {
      const index = this.abuseMessages
        .map((abuseMessage: AbuseMessage) => abuseMessage.message.id)
        .indexOf(abuseMessage.message.id);
      if (index > -1) {
        this.abuseMessages.splice(index, 1);
      }
    },
    async claimNextFlaggedMessage(): Promise<void> {
      if (this.claimedAbuseMessage) {
        const index = this.abuseMessages
          .map((abuseMessage: AbuseMessage) => abuseMessage.message.id)
          .indexOf(this.claimedAbuseMessage.message.id);
        if (index > -1) {
          this.abuseMessages.splice(index, 1);
        }
      }

      if (this.claimedAbusePoke) {
        const index = this.abusePokes.map((abusePoke: PokeDocument) => abusePoke.id).indexOf(this.claimedAbusePoke.id);
        if (index > -1) {
          this.abusePokes.splice(index, 1);
        }
      }

      this.claimedAbuseMessage = null;
      this.claimedAbusePoke = null;

      if (this.alreadyClaimedFlaggedMessage) {
        this.claimedAbuseMessage = this.alreadyClaimedFlaggedMessage;
        return;
      }

      if (this.alreadyClaimedFlaggedPoke) {
        this.claimedAbusePoke = this.alreadyClaimedFlaggedPoke;
        return;
      }

      const abuseToClaim = this.nextFlaggedAbuseToCheck;
      if (!abuseToClaim) {
        return;
      }

      try {
        if (isAbuseMessage(abuseToClaim)) {
          await firebaseRepoManager.dispatch("abuseMessages", abuseToClaim.chat.locale, "claim", {
            abuseMessage: abuseToClaim,
            operatorId: useAuthenticatorStore().user.uid,
          });
          this.claimedAbuseMessage = abuseToClaim;
          return;
        }

        await firebaseRepoManager.dispatch("abusePokes", abuseToClaim.locale, "claim", {
          abusePoke: abuseToClaim,
          operatorId: useAuthenticatorStore().user.uid,
        });
        this.claimedAbusePoke = abuseToClaim;
      } catch (error) {
        useNotificationStore().addNotification({
          message: error.toString(),
          type: NotificationTypes.Error,
        });
      }
    },
    async unclaimAbuseMessage(): Promise<void> {
      try {
        if (this.claimedAbuseMessage) {
          await firebaseRepoManager.dispatch("abuseMessages", this.claimedAbuseMessage.chat.locale, "unclaim", {
            abuseMessage: this.claimedAbuseMessage,
          });
          this.claimedAbuseMessage = null;

          return;
        }

        if (this.claimedAbusePoke) {
          await firebaseRepoManager.dispatch("abusePokes", this.claimedAbusePoke.locale, "unclaim", {
            abusePoke: this.claimedAbusePoke,
          });
          this.claimedAbusePoke = null;
        }
      } catch (error) {
        useNotificationStore().addNotification({
          message: error.toString(),
          type: NotificationTypes.Error,
        });
      }
    },
    async markAsAbuse(): Promise<void> {
      if (this.claimedAbuseMessage) {
        await firebaseRepoManager.dispatch("abuseMessages", this.claimedAbuseMessage.chat.locale, "markAsAbuse", {
          abuseMessage: { ...this.claimedAbuseMessage },
          operatorId: useAuthenticatorStore().user.uid,
        });
      }

      if (this.claimedAbusePoke) {
        await firebaseRepoManager.dispatch("abusePokes", this.claimedAbusePoke.locale, "markAsAbuse", {
          abusePoke: { ...this.claimedAbusePoke },
          operatorId: useAuthenticatorStore().user.uid,
        });
      }
      this.claimNextFlaggedMessage();
    },
    async markAsSafe(): Promise<void> {
      if (this.claimedAbuseMessage) {
        await firebaseRepoManager.dispatch("abuseMessages", this.claimedAbuseMessage.chat.locale, "markAsSafe", {
          abuseMessage: { ...this.claimedAbuseMessage },
          operatorId: useAuthenticatorStore().user.uid,
        });
      }

      if (this.claimedAbusePoke) {
        await firebaseRepoManager.dispatch("abusePokes", this.claimedAbusePoke.locale, "markAsSafe", {
          abusePoke: { ...this.claimedAbusePoke },
          operatorId: useAuthenticatorStore().user.uid,
        });
      }
      this.claimNextFlaggedMessage();
    },
    addAbusePoke(newAbusePoke: PokeDocument) {
      if (this.abusePokes?.some((abusePoke) => abusePoke.id === newAbusePoke.id)) {
        return;
      }
      this.abusePokes.push(newAbusePoke);
    },
    updateAbusePoke(abusePoke: PokeDocument) {
      const existsAtIndex = this.abusePokes.findIndex((existingAbusePoke) => existingAbusePoke.id === abusePoke.id);

      if (existsAtIndex !== -1) {
        this.abusePokes[existsAtIndex] = abusePoke;
      } else {
        this.abusePokes.push(abusePoke);
      }

      if (this.claimedAbusePoke && this.claimedAbusePoke.id === abusePoke.id) {
        this.claimedAbusePoke = abusePoke;
      }
    },
    removeAbusePoke(abusePoke: PokeDocument) {
      const index = this.abusePokes.map((abusePoke) => abusePoke.id).indexOf(abusePoke.id);
      if (index > -1) {
        this.abusePokes.splice(index, 1);
      }
    },
  },
});
