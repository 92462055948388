
import { defineComponent, PropType } from "vue";
import { mapState } from "pinia";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import { displayDateTime } from "@/helpers/datetime";
import { ConcerningType, Log, LogsByCategory, LogCategoryName, ChatDocument, NotificationTypes, Roles } from "@/types";
import { colorBySenderType } from "@/helpers/colors";
import { logsCategories } from "@/types/categories";
import RoleAccess from "@/plugins/RoleAccess";
import LogBookDialog from "@/components/LogBookDialog.vue";
import { useNotificationStore } from "@/stores/notifications";
import { useChatStore } from "@/stores/chat";
import { useStatisticsStore } from "@/stores/statistics";

export default defineComponent({
  name: "LogbookPanel",
  components: {
    LogBookDialog,
  },
  props: {
    concerning: {
      type: String as PropType<ConcerningType>,
      required: true,
    },
  },
  data() {
    return {
      logsCategories,
      hasOpenDialog: false,
      logToEdit: null,
    };
  },
  computed: {
    ...mapState(useAuthenticatorStore, ["user"]),
    ...mapState(useChatStore, ["claimedChat"]),
    ...mapState(useStatisticsStore, ["activeChat"]),
    chat(): ChatDocument {
      return this.claimedChat || this.activeChat;
    },
    logsByCategories(): LogsByCategory {
      return useChatStore().sortedCategorizedLogs?.[this.concerning] || {};
    },
    logsCount(): number {
      if (!this.logsByCategories) {
        return 0;
      }

      return Object.keys(this.logsByCategories).reduce((sum: number, category: string) => {
        return sum + Object.keys(this.logsByCategories[category as LogCategoryName]).length;
      }, 0);
    },
    isSmallDevice(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    hasLogbookModifyAccess(): boolean {
      return RoleAccess.hasAnyRole([Roles.Admin, Roles.Manager, Roles.AbuseChecker]);
    },
    hasLogbookViewAccess(): boolean {
      return RoleAccess.hasAnyRole([Roles.Admin, Roles.Manager, Roles.AbuseChecker]);
    },
  },
  methods: {
    displayDateTime,
    colorBySenderType,
    isLogAuthor(log: Log): boolean {
      return log.createdBy === this.user.uid || this.hasLogbookModifyAccess;
    },
    async deleteLog(log: Log): Promise<void> {
      try {
        await firebaseRepoManager.dispatch("log", this.chat.locale, "removeLog", {
          chatId: this.chat.id,
          id: log.id,
        });
      } catch (error) {
        useNotificationStore().addNotification({
          message: `Failed to remove logbook.`,
          type: NotificationTypes.Error,
        });
      }
    },
    openCreateDialog(): void {
      this.logToEdit = null;
      this.hasOpenDialog = true;
    },
    openEditDialog(log: Log): void {
      this.logToEdit = log;
      this.hasOpenDialog = true;
    },
  },
});
