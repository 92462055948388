import { defineStore } from "pinia";
import { NotificationStoreState, Notification } from "@/types";

export const useNotificationStore = defineStore("notification", {
  state: (): NotificationStoreState => ({
    notifications: [],
    isClaimedChatNotificationActive: false,
  }),
  actions: {
    addNotification(notification: Omit<Notification, "show">) {
      return new Promise((resolve) => {
        this.notifications.push({ ...notification, show: true });
        resolve(true);
      });
    },
    removeNotification(index: number) {
      return new Promise((resolve) => {
        this.notifications.splice(index, 1);
        resolve(true);
      });
    },
    removeAllNotifications() {
      return new Promise((resolve) => {
        this.notifications = [];
        resolve(true);
      });
    },
    setNotificationSeen(index: number) {
      return new Promise((resolve) => {
        this.notifications[index] && (this.notifications[index].show = false);
        resolve(true);
      });
    },
    showClaimedChatNotification() {
      this.isClaimedChatNotificationActive = true;
    },
    resetClaimedChatNotification() {
      this.isClaimedChatNotificationActive = false;
    },
  },
});
