import { Announcement, AnnouncementsState, AnnouncementsTopic, NotificationTypes } from "@/types";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import _set from "lodash/set";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useNotificationStore } from "@/stores/notifications";
import { defineStore } from "pinia";

function sortAnnouncements(announcements: Record<string, Announcement>) {
  return Object.fromEntries(
    new Map(
      Object.entries(announcements).sort((a: [string, Announcement], b: [string, Announcement]): number => {
        return a[1].updatedAt.seconds - b[1].updatedAt.seconds;
      }),
    ),
  );
}

export const useAnnouncementsStore = defineStore("announcements", {
  state: (): AnnouncementsState => ({
    isAnnouncementsLoadingDone: false,
    announcementsPerLocale: {},
    announcementsTopics: {},
    isAnnouncementDialogShown: false,
  }),
  getters: {
    announcementsCount(state: AnnouncementsState): number {
      let totalAnnouncements = 0;
      const announcementsPerLocales = Object.values(state.announcementsPerLocale);
      for (const announcementsInOneLocale of announcementsPerLocales) {
        const announcements = Object.values(announcementsInOneLocale);
        for (const announcement of announcements) {
          totalAnnouncements += Object.keys(announcement).length;
        }
      }
      return totalAnnouncements;
    },
  },
  actions: {
    async getAnnouncementsForOperator(): Promise<void> {
      try {
        await Promise.all(
          useAuthenticatorStore().user.geos.map(async (locale) => {
            await firebaseRepoManager.dispatch("announcements", locale, "getAnnouncements", null);
          }),
        );
        this.isAnnouncementsLoadingDone = true;
      } catch (error) {
        useNotificationStore().addNotification({
          message: error.toString(),
          type: NotificationTypes.Error,
        });
      }
    },
    setAnnouncementsLocales(locales: string[]) {
      locales?.forEach((locale) => {
        this.announcementsPerLocale[locale] ||= {};
        this.announcementsTopics[locale] ||= {};
      });
    },
    setAnnouncement(announcement: Announcement) {
      _set(
        this.announcementsPerLocale,
        [announcement.locale ?? "", announcement.topicId ?? "", announcement.id],
        announcement,
      );
      Object.keys(this.announcementsPerLocale).forEach((locale) => {
        Object.keys(this.announcementsPerLocale[locale]).forEach((topicId) => {
          this.announcementsPerLocale[locale][topicId] = sortAnnouncements(
            this.announcementsPerLocale[locale][topicId],
          );
        });
      });
      this.announcementsPerLocale = { ...this.announcementsPerLocale };
    },
    setAnnouncementTopic(topic: AnnouncementsTopic) {
      _set(this.announcementsTopics, [topic.locale, topic.id], topic);
      this.announcementsTopics = { ...this.announcementsTopics };
    },
    removeAnnouncement(announcement: Announcement) {
      delete this.announcementsPerLocale[announcement.locale ?? ""]?.[announcement.topicId ?? ""]?.[announcement.id];
      this.announcementsPerLocale = { ...this.announcementsPerLocale };
    },
    removeAnnouncementTopic(topic: AnnouncementsTopic) {
      delete this.announcementsTopics[topic.locale][topic.id];
      this.announcementsTopics = { ...this.announcementsTopics };
    },
    cleanAnnouncementsByLocale(locale: string) {
      this.announcementsPerLocale = { ...this.announcementsPerLocale, [locale]: {} };
    },
    cleanAnnouncementsTopicsByLocale(locale: string) {
      delete this.announcementsTopics[locale];
    },
  },
});
