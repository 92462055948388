
import { defineComponent } from "vue";
import { CUSTOMER_SENDER_TYPE, PROFILE_SENDER_TYPE } from "@/types/constants";
import ChatCountdown from "@/components/chat/ChatCountdown.vue";

export default defineComponent({
  name: "ChatFloatingPanel",
  components: {
    ChatCountdown,
  },
  data() {
    return {
      CUSTOMER_SENDER_TYPE,
      PROFILE_SENDER_TYPE,
      isLogbookDialogOpen: false,
    };
  },
  computed: {
    isSmallDevice(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    toClaimedChat(): void {
      this.$router.push("/chat/claimed");
    },
  },
});
