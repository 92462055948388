import { GeoFreeLocationApiResponse, Profile, ProfileConnector, FilterPlatersByNameParams } from "@/types";
import { ProfileConnectorFactory } from "./connectors/profile/ProfileConnectorFactory";

export default class ProfileRepository {
  private connector: ProfileConnector;

  constructor(domain: string) {
    this.connector = ProfileConnectorFactory.get(domain);
  }

  async getEntertainmentProfiles({
    query,
    filters,
    page,
    limit,
    locales,
  }: {
    query: string;
    filters: any;
    page: number;
    limit: number;
    locales?: string[];
  }): Promise<Profile[]> {
    return this.connector.getEntertainmentProfiles({ query, filters, page, limit, locales });
  }

  async getEntertainmentProfilesCount(filters: any) {
    return this.connector.getEntertainmentProfilesCount(filters);
  }

  async getEntertainmentProfile(entertainmentProfileId: string | number, entertainmentProfileUuid: string) {
    return this.connector.getEntertainmentProfile(entertainmentProfileId, entertainmentProfileUuid);
  }

  async getGeoFreeEntertainmentProfileLocation(
    entertainmentProfileId: string,
    countryCode: string,
    customerLat: string,
    customerLng: string,
  ): Promise<GeoFreeLocationApiResponse> {
    return this.connector.getGeoFreeEntertainmentProfileLocation(
      entertainmentProfileId,
      countryCode,
      customerLat,
      customerLng,
    );
  }

  async getPokeEntertainmentProfile(entertainmentProfileId: string, domain: string, entertainmentProfileUuid: string) {
    return await this.connector.getPokeEntertainmentProfile(entertainmentProfileId, domain, entertainmentProfileUuid);
  }

  async getCustomerProfile({ customerProfileId, customerUuid }: { customerProfileId: string; customerUuid: string }) {
    return this.connector.getCustomerProfile({ customerProfileId, customerUuid });
  }

  async getCustomerProfilesCount(filters: any) {
    return this.connector.getCustomerProfilesCount(filters);
  }

  async getAttachments(profile: Profile) {
    return await this.connector.getAttachments(profile);
  }

  async getCreditsBalance(profile: Profile, domainName?: string): Promise<number> {
    return await this.connector.getCreditsBalance(profile, domainName);
  }

  supportMultiDomainCount() {
    return this.connector.supportMultiDomainCount();
  }

  searchCustomerProfilesByName(filters: FilterPlatersByNameParams) {
    return this.connector.searchCustomerProfilesByName(filters);
  }
}
