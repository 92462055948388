import { defineStore } from "pinia";
import { PokeScheduleStoreState, PokeDocument, DomainConfiguration, NotificationTypes } from "@/types";
import { ORIGIN_TYPE_LEGACY } from "@/types/constants";
import { Dayjs } from "dayjs";

import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import { useConfigStore } from "./config";
import { useNotificationStore } from "./notifications";

export const usePokeScheduleStore = defineStore("pokeSchedule", {
  state: (): PokeScheduleStoreState => ({
    pokes: [],
    dailyPokes: [],
    origin: ORIGIN_TYPE_LEGACY,
    scheduledProfiles: {
      future: {},
      past: {},
    },
  }),
  getters: {
    futureScheduledPoke: (state: PokeScheduleStoreState) => (profileUUID: string) => {
      return state.scheduledProfiles.future[profileUUID];
    },
    pastScheduledPoke: (state: PokeScheduleStoreState) => (profileUUID: string) => {
      return state.scheduledProfiles.past[profileUUID];
    },
    followUpsByPoke:
      (state: PokeScheduleStoreState) =>
      (mainPoke: PokeDocument): PokeDocument[] => {
        return state.dailyPokes.filter((poke) => poke.followUp?.parentId === mainPoke.id);
      },
    nextFollowUpsByPoke:
      (state: PokeScheduleStoreState) =>
      (followUpPoke: PokeDocument): PokeDocument[] => {
        const nextFollowUps = [];
        let nextFollowUp = followUpPoke;
        while (nextFollowUp) {
          nextFollowUp = state.dailyPokes.find((poke) => poke.followUp?.previousId === nextFollowUp.id);
          if (nextFollowUp) {
            nextFollowUps.push(nextFollowUp);
          }
        }
        return nextFollowUps;
      },
  },
  actions: {
    addPoke(poke: PokeDocument) {
      this.pokes.push(poke);
    },
    addDailyPoke(poke: PokeDocument) {
      this.dailyPokes.push(poke);
    },
    updatePoke(pokeToUpdate: PokeDocument) {
      const foundPoke = this.pokes.find((poke: any) => poke.id === pokeToUpdate.id);
      if (!foundPoke) {
        this.pokes.push(pokeToUpdate);
        return;
      }

      Object.assign(foundPoke, pokeToUpdate);
    },
    updateDailyPoke(pokeToUpdate: PokeDocument) {
      const foundPoke = this.dailyPokes.find((poke: any) => poke.id === pokeToUpdate.id);
      if (!foundPoke) {
        this.dailyPokes.push(pokeToUpdate);
        return;
      }

      Object.assign(foundPoke, pokeToUpdate);
    },
    removePoke(poke: PokeDocument) {
      this.pokes = this.pokes.filter((filteringPoke: PokeDocument) => filteringPoke.id !== poke.id);
      this.dailyPokes = this.dailyPokes.filter((filteringPoke: PokeDocument) => filteringPoke.id !== poke.id);
    },
    addFutureScheduledPoke(poke: PokeDocument) {
      this.scheduledProfiles.future[poke.profile.uuid] = poke;
    },
    addPastScheduledPoke(poke: PokeDocument) {
      this.scheduledProfiles.past[poke.profile.uuid] = poke;
    },
    clearScheduledProfiles() {
      this.scheduledProfiles = {
        future: {},
        past: {},
      };
    },
    async removeScheduledPoke(poke: PokeDocument): Promise<void> {
      const domain = poke.domains[0];
      const domainConfiguration = useConfigStore().domains.find(
        (domainConfig: DomainConfiguration) => domainConfig.domain === domain,
      );

      try {
        await firebaseRepoManager.dispatch("poke", domainConfiguration.locale, "deletePoke", poke.id);
        this.removePoke(poke);
        let notificationMessage = "The poke was removed.";

        const followUpPokes: PokeDocument[] = this.followUpsByPoke(poke);
        followUpPokes.forEach((followUpPoke) => {
          this.removePoke(followUpPoke);
        });
        if (followUpPokes.length > 0) {
          notificationMessage += ` It's ${followUpPokes.length} follow-ups have been removed as well.`;
        }

        if (poke.followUp) {
          const nextFollowUpPokes = this.nextFollowUpsByPoke(poke);
          nextFollowUpPokes.forEach((followUpPoke: PokeDocument) => {
            this.removePoke(followUpPoke);
          });
          if (nextFollowUpPokes.length > 0) {
            notificationMessage += ` It's ${nextFollowUpPokes.length} next follow-ups have been removed as well.`;
          }
        }

        useNotificationStore().addNotification({
          message: notificationMessage,
          type: NotificationTypes.Success,
        });
      } catch (error) {
        useNotificationStore().addNotification({
          message: error.toString(),
          type: NotificationTypes.Error,
        });
      }
    },
    async getDailyPokes({ geo, filters }: { geo: string; filters: Record<string, any> }): Promise<void> {
      await firebaseRepoManager.dispatch("poke", geo, "getDailyPokes", { ...filters });
    },
    getPokesByScheduled({
      locale,
      scheduled,
      domains,
    }: {
      locale: string;
      scheduled: Dayjs;
      domains: string[];
    }): Promise<PokeDocument[]> {
      return firebaseRepoManager.dispatch("poke", locale, "getPokesByScheduled", {
        scheduled,
        domains,
      });
    },
  },
});
