
import { defineComponent } from "vue";
import { NotificationTypes, Roles } from "@/types";
import { mapState } from "pinia";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useNotificationStore } from "@/stores/notifications";

type MenuItem = {
  icon: string;
  title: string;
  to?: string;
  id: string;
  roles?: Roles[];
  onClick?: () => void;
};

export default defineComponent({
  computed: {
    ...mapState(useAuthenticatorStore, ["tenant", "user"]),
    menuItems(): MenuItem[] {
      return [
        {
          icon: "mdi-chart-box",
          title: "My statistics",
          to: "/personal-stats",
          id: "myStatsButton",
          roles: [Roles.Admin, Roles.Operator],
        },
        {
          icon: "mdi-cog",
          title: "Settings",
          to: "/personal-settings",
          id: "settingsButton",
        },
        {
          icon: "mdi-logout",
          title: "Log out",
          onClick: this.signOut,
          id: "logOutButton",
        },
      ];
    },
  },
  methods: {
    async signOut() {
      try {
        await useAuthenticatorStore().signOut();
      } catch (error) {
        useNotificationStore().addNotification({
          message: `Failed signout: ${error}.`,
          type: NotificationTypes.Error,
        });
      }
    },
  },
});
