import { AbuseMessage, ChatDocument, MessageDocument, Repository } from "@/types";
import { ABUSE_STATUS_EXPIRED, ABUSE_STATUS_PENDING, ABUSE_STATUS_SAFE, ABUSE_STATUS_ABUSE } from "@/types/constants";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  getFirestore,
  CollectionReference,
  Query,
  QuerySnapshot,
  DocumentData,
  where,
  query,
  collection,
  onSnapshot,
  doc,
  getDoc,
  updateDoc,
  collectionGroup,
} from "firebase/firestore";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { useAbuseStore } from "@/stores/abuse";

export class AbuseMessagesRepository implements Repository {
  private _chatsCollectionRef: CollectionReference<ChatDocument>;
  private _abuseMessagesCollectionGroup: Query<DocumentData>;
  private _abuseMessagesUnsubscribeFunction: () => void;
  private initialized = false;

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    const firestore = getFirestore(this._project);
    this._chatsCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getChatsCollectionRef();
    this._abuseMessagesCollectionGroup = query(
      collectionGroup(firestore, "flagged_messages"),
      where("locale", "==", this._locale),
    );
  }

  private createAbuseMessagesQuery(collection: Query<DocumentData>) {
    return query(collection, where("abuse.status", "in", [ABUSE_STATUS_PENDING, ABUSE_STATUS_EXPIRED]));
  }

  public enableListeners() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    this._abuseMessagesUnsubscribeFunction = onSnapshot(
      this.createAbuseMessagesQuery(this._abuseMessagesCollectionGroup),
      this.constructChangeHandler(),
    );
  }

  public async dispatch(
    action: "disableAllListeners" | "markAsAbuse" | "markAsSafe" | "claim" | "unclaim",
    params: any,
  ): Promise<void> {
    this[action]?.(params);
  }

  public async markAsAbuse({
    abuseMessage,
    operatorId,
  }: {
    abuseMessage: AbuseMessage;
    operatorId: string;
  }): Promise<void> {
    await updateDoc(this.getAbuseMessageRef(abuseMessage.chat.id, abuseMessage.message.id), {
      "abuse.status": ABUSE_STATUS_ABUSE,
      "abuse.checkedAt": new Date(),
      "abuse.checkedBy": operatorId,
      "abuse.claimedBy": null,
    });
  }

  public async markAsSafe({
    abuseMessage,
    operatorId,
  }: {
    abuseMessage: AbuseMessage;
    operatorId: string;
  }): Promise<void> {
    await updateDoc(this.getAbuseMessageRef(abuseMessage.chat.id, abuseMessage.message.id), {
      "abuse.status": ABUSE_STATUS_SAFE,
      "abuse.checkedAt": new Date(),
      "abuse.checkedBy": operatorId,
      "abuse.claimedBy": null,
    });
  }

  public async claim({ abuseMessage, operatorId }: { abuseMessage: AbuseMessage; operatorId: string }): Promise<void> {
    await updateDoc(this.getAbuseMessageRef(abuseMessage.chat.id, abuseMessage.message.id), {
      "abuse.status": ABUSE_STATUS_PENDING,
      "abuse.claimedBy": operatorId,
      "abuse.claimedAt": new Date(),
    });
  }

  public async unclaim({ abuseMessage }: { abuseMessage: AbuseMessage }): Promise<void> {
    await updateDoc(this.getAbuseMessageRef(abuseMessage.chat.id, abuseMessage.message.id), {
      "abuse.status": ABUSE_STATUS_PENDING,
      "abuse.claimedBy": null,
      "abuse.claimedAt": null,
    });
  }

  public disableAllListeners(): void {
    if (this._abuseMessagesUnsubscribeFunction) {
      this._abuseMessagesUnsubscribeFunction();
    }
  }

  private constructChangeHandler() {
    return (snapshot: QuerySnapshot<DocumentData>) => {
      snapshot.docChanges().forEach(async (docChange) => {
        const message = {
          id: docChange.doc.id,
          ...docChange.doc.data(),
        } as MessageDocument;

        const chat = await this.getChatDocument(docChange.doc.ref.parent.parent.id);
        if (!chat) {
          return;
        }
        chat.id = docChange.doc.ref.parent.parent.id as ChatDocument["id"];

        const abuseMessage = {
          message,
          chat,
        } as AbuseMessage;

        switch (docChange.type) {
          case "removed":
            useAbuseStore().removeAbuseMessage(abuseMessage);
            break;
          case "added":
            useAbuseStore().addAbuseMessage(abuseMessage);
            break;
          case "modified":
            useAbuseStore().updateAbuseMessage(abuseMessage);
            break;
        }
      });
    };
  }

  private async getChatDocument(chatId: string): Promise<ChatDocument> {
    const chatSnapshot = await getDoc(doc(this._chatsCollectionRef, chatId));
    return chatSnapshot.data() as ChatDocument;
  }

  private getAbuseMessageRef(chatId: string, id: string) {
    const docRef = doc(this._chatsCollectionRef, chatId);
    const docCollection = collection(docRef, "flagged_messages");
    return doc(docCollection, id);
  }
}
