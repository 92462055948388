
import { defineComponent } from "vue";
import { mapState } from "pinia";
import { getRegionNameByLocale, getFlagCodeByLocale } from "@/helpers/international";
import { Announcement, AnnouncementsTopic } from "@/types";
import { Timestamp } from "firebase/firestore";
import _truncate from "lodash/truncate";
import dayjs from "dayjs";
import { useConfigStore } from "@/stores/config";
import { useAnnouncementsStore } from "@/stores/announcements";

export default defineComponent({
  data() {
    return {
      now: dayjs().format("D MMMM YYYY"),
      isLoading: false,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState(useConfigStore, ["initialized"]),
    ...mapState(useAnnouncementsStore, ["announcementsTopics", "isAnnouncementDialogShown", "announcementsPerLocale"]),

    announcementsByTopics(): {
      locale: string;
      topic: AnnouncementsTopic;
      announcements: Record<string, Announcement>;
    }[] {
      const topics = [] as { locale: string; topic: AnnouncementsTopic; announcements: Record<string, Announcement> }[];
      Object.keys(this.announcementsTopics).forEach((locale) => {
        Object.keys(this.announcementsTopics[locale]).forEach((topicId) => {
          if (this.announcementsPerLocale[locale]?.[topicId]) {
            topics.push({
              locale,
              topic: this.announcementsTopics[locale][topicId],
              announcements: this.announcementsPerLocale[locale][topicId],
            });
          }
        });
      });
      return topics;
    },
    topicsCount(): number {
      return this.announcementsByTopics.length;
    },
  },
  watch: {
    initialized(): void {
      if (!this.initialized) {
        return;
      }
      this.isLoading = true;
      useAnnouncementsStore().getAnnouncementsForOperator();
    },
    announcementsByTopics(): void {
      this.isLoading = false;
    },
  },
  mounted() {
    if (!this.initialized) {
      return;
    }
    this.isLoading = true;
    useAnnouncementsStore().getAnnouncementsForOperator();
  },
  methods: {
    getRegionNameByLocale,
    getFlagCodeByLocale,
    displayDate(dateTime: Timestamp): string {
      return dayjs(dateTime?.toDate()).format("D MMMM YYYY");
    },
    displayMessage(message: string) {
      return this.$sanitize(message.replace(/\n/g, "<br />"));
    },
    closeDialog(): void {
      useAnnouncementsStore().isAnnouncementDialogShown = false;
    },
    previousStep(): void {
      this.currentPage -= 1;
    },
    nextStep(): void {
      this.currentPage += 1;
    },
    truncateTopicTitle(title: string): string {
      return _truncate(title, {
        length: Math.max(Math.round(60 / this.topicsCount), 7),
      });
    },
  },
});
