import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export const primaryColor = "#28379E";
export const aiColor = "#9760FF";

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: primaryColor,
        ai: aiColor,
      },
    },
  },
});
