import isoConverter from "@/helpers/isoConverter";
import ProfileRepository from "@/repositories/profileRepository";
import { Profile, NotificationTypes } from "@/types";
import { IS_GEO_FREE } from "@/constants";
import { useNotificationStore } from "@/stores/notifications";

const CDN_URL = process.env.VUE_APP_CDN_BASE_URL;

export default {
  async getPokeEntertainmentProfile(id: string, domain: string, entertainmentProfileUuid: string): Promise<Profile> {
    try {
      const profileRepository = new ProfileRepository(domain);

      return await profileRepository.getPokeEntertainmentProfile(id, domain, entertainmentProfileUuid);
    } catch (error) {
      useNotificationStore().addNotification({
        message: `Failed to retrieve entertainment profile with id ${id}. ${error}`,
        type: NotificationTypes.Error,
      });
    }
  },
  getAvatarUrl(profile: any, origin: string): string {
    return origin === "legacy" ? profile.avatar : `${CDN_URL}/${profile.avatar}`;
  },
  fullLocation(profile: Profile): string {
    const type = profile.geo_type;
    const iso = profile.geo_iso;
    const geo = profile.geo && profile.geo.length ? profile.geo[0] : null;

    if (type == "anonymous") {
      return iso ? isoConverter(iso) : "not set";
    }

    if (!geo) {
      return "not set";
    }

    const narrowLocation = !geo.label || geo.label.toLowerCase() == "null" ? geo.locality : geo.label;
    const broadRegion = geo.region1;

    if (narrowLocation && broadRegion) {
      return `${narrowLocation}, ${broadRegion}`;
    }

    return narrowLocation ?? broadRegion;
  },
  isGeoFreeProfile(profile: Profile): boolean {
    return profile.geo_free === IS_GEO_FREE;
  },
};
