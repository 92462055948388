import { defineStore } from "pinia";
import {
  PokeWizardStoreState,
  PokeMessage,
  LegacyPokeFilters,
  FollowUpPoke,
  Profile,
  PokeMessageParsedItem,
  PokePayload,
  DomainConfiguration,
  PokeDocument,
  IconMapping,
  NotificationTypes,
  RegionFilter,
  Roles,
} from "@/types";
import { Timestamp } from "firebase/firestore";
import {
  RECURRING_POKE_EXECUTION_LIMIT,
  ORIGIN_TYPE_LEGACY,
  POKE_STRATEGY_DEFAULT,
  POKE_STRATEGY_RECURRING,
  POKE_STRATEGY_FOLLOW_UP,
  POKE_TYPE_MESSAGE,
} from "@/types/constants";
import {
  availableTargetVariables,
  excludedLegacyTargetVariables,
  invertAllFalseValuesInOneFilterCategory,
} from "@/helpers/poke";
import { removeEmptyElements } from "@/helpers/removeEmptyElements";
import { pokeTypes } from "@/helpers/poke";
import { primaryColor } from "@/plugins/vuetify";
import { parseForVariables } from "@/helpers/parse";
import { iconMapping, mapTake } from "@/helpers/utility";
import { rangeCMToFeet } from "@/helpers/measurementConverter";
import profileService from "@/services/profileService";
import RoleAccess from "@/plugins/RoleAccess";
import router from "@/router";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import http from "@/services/http";
import _intersectionBy from "lodash/intersectionBy";

import { useAuthenticatorStore } from "./authenticator";
import { useConfigStore } from "./config";
import { useNotificationStore } from "./notifications";
import { useProfileStore } from "./profile";

import colors from "vuetify/lib/util/colors";
const vuetifyColors: any = colors;

import dayjs from "dayjs";
import { default as Timezone } from "dayjs/plugin/timezone";
import { default as UTC } from "dayjs/plugin/utc";
import { useAttachmentsStore } from "./attachments";
import { convertAttachmentToObject } from "@/helpers/attachment";
dayjs.extend(Timezone);
dayjs.extend(UTC);

const defaultPokeWizard = {
  mainPokeMessage: {
    attachment: null,
    attachmentFilenameForLegacy: null,
    content: "",
    hasAttachment: false,
    messageLength: 0,
    scheduledDateTime: null,
    type: "poke",
  } as PokeMessage,
  profile: null,
  legacyProfile: null,
  selectedProfiles: [] as Profile[],
  legacyFilters: {
    age_18_till_25: true,
    age_26_till_35: true,
    age_36_till_45: true,
    age_46_till_54: true,
    age_55_till_99: true,
    age_unknown: true,
    duplicate_ip_target_level: "ignore_ip_filter",
    gender_couple: false,
    gender_female: false,
    gender_male: true,
    gender_transgender: false,
    gender_unknown: false,
    geoRegion1: "",
    had_credits: true,
    has_credits: true,
    in_conversation: false,
    lastLogin: 7,
    lastLoginByHours: 0,
    limit: 0,
    never_had_credits: true,
    not_in_conversation: true,
    past_paid_older_then_1_month: true,
    past_paid_within_1_month: true,
    past_paid_within_1_week: true,
    past_paid_within_2_weeks: true,
    targetFilterType: "",
    selectedTargetsPerDomain: null,
    with_photo: true,
    with_text: true,
    without_photo: true,
    without_text: true,
  } as LegacyPokeFilters,
  filters: {
    age: {
      age_max: 99,
      age_min: 18,
    },
    angel: 0,
    build: null,
    eye_colour: null,
    gender: "male",
    geo_type: null,
    hair_colour: null,
    has_photo: null,
    has_text: null,
    is_disabled: 0,
    is_doi: 1,
    locality: null,
    region1: null,
    region2: null,
    region3: null,
    region4: null,
    snowflake: 0,
  },
  domains: [],
  domainConfigurations: [],
  origin: ORIGIN_TYPE_LEGACY,
  frequency: {
    day: null,
    limit: RECURRING_POKE_EXECUTION_LIMIT,
  },
  id: null,
  locale: null,
  followUps: [] as FollowUpPoke[],
  geoStrategy: null,
  strategy: POKE_STRATEGY_DEFAULT,
} as Omit<
  PokeWizardStoreState,
  | "action"
  | "originalPoke"
  | "pagination"
  | "pokePlayerFilters"
  | "pokeType"
  | "query"
  | "searchedFilters"
  | "searchedPlayers"
>;

function getCommonProfileTypes(domainsConfigurations: DomainConfiguration[]) {
  const allProfileTypes = domainsConfigurations.map((domainConfig) => domainConfig.profileTypes);

  const commonProfileTypes = allProfileTypes.reduce((intersection, profileTypes) => {
    if (intersection.length === 0) {
      return profileTypes;
    }

    return intersection.filter((type) => profileTypes.includes(type));
  }, []);

  return Array.from(new Set(commonProfileTypes));
}

export const usePokeWizardStore = defineStore("pokeWizard", {
  state: (): PokeWizardStoreState => ({
    ...structuredClone(defaultPokeWizard),
    action: "create",
    originalPoke: null,
    pagination: {
      itemsPerPage: 10,
      page: 1,
    },
    pokePlayerFilters: {},
    pokeType: POKE_TYPE_MESSAGE,
    query: "",
    searchedFilters: {},
    searchedPlayers: [],
  }),
  getters: {
    isOriginalPokeInThePast: (state: PokeWizardStoreState): boolean => {
      if (!state.originalPoke) {
        return false;
      }
      const currentDate = Timestamp.now();
      return state.originalPoke.scheduled < currentDate;
    },
    isOriginalFollowPokeInThePast:
      (state: PokeWizardStoreState) =>
      (index: number): boolean => {
        if (!state.followUps[index]) {
          return false;
        }
        if (!state.followUps[index].originalDateTime) {
          return false;
        }
        const currentDate = dayjs(new Date()).tz(state.originalPoke.profile?.timezone || "UTC");
        return state.followUps[index].originalDateTime < currentDate;
      },
    profileTimezone: (state: PokeWizardStoreState) => {
      return state.profile?.geo[0].timezone;
    },
    cityField: (state: PokeWizardStoreState) => {
      if (!state.domainConfigurations.length) {
        return;
      }

      return state.domainConfigurations[0]?.geoStrategyCityField as keyof RegionFilter;
    },
    regionField: (state: PokeWizardStoreState) => {
      if (!state.domainConfigurations.length) {
        return;
      }

      return state.domainConfigurations[0].geoStrategyRegionField as keyof RegionFilter;
    },
    filtersForApi(state: PokeWizardStoreState): Record<string, any> {
      if (state.origin === ORIGIN_TYPE_LEGACY) {
        const legacyFilters = invertAllFalseValuesInOneFilterCategory(state.legacyFilters);
        return removeEmptyElements(structuredClone(legacyFilters));
      }

      const filters: Record<string, any> = {
        ...state.filters,
        gender: [state.filters.gender],
        geo_type: state.filters.geo_type ? [state.filters.geo_type] : null,
      };

      if (state.origin !== ORIGIN_TYPE_LEGACY && this.usedVariables.length > 0) {
        filters.hasFilters = this.usedVariables;
      }

      filters.age_min = filters.age.age_min;
      filters.age_max = filters.age.age_max;
      delete filters.age;

      return filters;
    },
    usedVariables(): string[] {
      if (!this.parsedPokeMessage?.length) {
        return [];
      }

      return this.parsedPokeMessage.reduce((uniqueVars: string[], item: PokeMessageParsedItem) => {
        if (item.type !== "var" || uniqueVars.includes(item.fixedVarName) || item.isBroken || !item.isValid) {
          return uniqueVars;
        }

        uniqueVars.push(item.fixedVarName);
        return uniqueVars;
      }, []);
    },
    typeColor(): string {
      return pokeTypes[this.pokeType]?.color || "primary";
    },
    typeColorRGB(): string {
      return vuetifyColors[this.typeColor]?.base || primaryColor;
    },
    parsedPokeMessage(): PokeMessageParsedItem[] {
      return parseForVariables(this.mainPokeMessage?.content, availableTargetVariables);
    },
    getPayload(state: PokeWizardStoreState): PokePayload | null {
      const createdAt = Timestamp.now();

      const scheduled = this.mainPokeMessage.scheduledDateTime
        ? Timestamp.fromDate(this.mainPokeMessage.scheduledDateTime.toDate())
        : createdAt;

      const user = useAuthenticatorStore().user;
      const profile = this.profile;

      if (!profile) {
        return null;
      }

      const message: PokeMessage = { ...this.mainPokeMessage, attachment: this.mainPokeMessage.attachment || null };
      delete message.scheduledDateTime;
      const isLegacy = this.origin === ORIGIN_TYPE_LEGACY;

      const payload = {
        id: this.id,
        agent: {
          id: user.uid,
          name: user.displayName,
        },
        company: user.companyId,
        profile: {
          id: isLegacy ? profile.profileCmsId : profile.id,
          name: profile.name,
          timezone: profile.geo[0].timezone,
          uuid: profile.uuid ?? null,
        },
        filters: this.filtersForApi,
        message,
        createdAt,
        scheduled,
        targets: {
          estimate: useProfileStore().customerProfilesCount,
          targetsPerDomain: useProfileStore().targetsPerDomain,
        },
        domains: this.domains,
        locale: this.locale,
        strategy: this.strategy,
        type: this.pokeType,
        origin: {
          type: this.origin,
        },
        tenant: useAuthenticatorStore().tenant,
      } as PokePayload;

      if (payload.strategy === POKE_STRATEGY_RECURRING && !state.frequency?.day) {
        payload.strategy = POKE_STRATEGY_DEFAULT;
      }
      if (payload.strategy === POKE_STRATEGY_RECURRING && state.frequency) {
        payload.frequency = state.frequency;
      }

      return payload;
    },
    availableTargetVariables(state: PokeWizardStoreState) {
      if (state.origin === "legacy") {
        return availableTargetVariables.filter((targetVariable: string) => {
          return !excludedLegacyTargetVariables.includes(targetVariable);
        });
      }
      return availableTargetVariables;
    },
    messageWithFixedVariables() {
      const parsedMessage: PokeMessageParsedItem[] = this.parsedPokeMessage;
      const result = parsedMessage.reduce((collector: string, item: PokeMessageParsedItem) => {
        if (item.type === "var") {
          return `${collector}%[${item.fixedVarName}]%`;
        } else {
          return `${collector}${item.original}`;
        }
      }, "");
      return result;
    },
    isGeoWizardEnabledForAllDomains(state: PokeWizardStoreState): boolean {
      return state.domainConfigurations.every(
        (domainConfiguration: DomainConfiguration) => domainConfiguration.geoWizardEnabled,
      );
    },
    entertainmentProfileVariables(): [] {
      if (!this.profile) {
        return [];
      }

      const selectedCharacteristics = mapTake("real_name")(this.profile);
      const geo = this.profile.geo?.[0] || null;
      if (!this.profile.geo_free) {
        const narrowLocation = !geo.label || geo.label.toLowerCase() == "null" ? geo.locality : geo.label;
        const broadRegion = geo.region1;

        if (narrowLocation) selectedCharacteristics["location_city"] = narrowLocation;
        if (broadRegion) selectedCharacteristics["location_region"] = broadRegion;
      }
      if (this.profile.geo_free && this.isGeoWizardEnabledForAllDomains) {
        selectedCharacteristics["player_city"] = "%[playerCity]%";
        selectedCharacteristics["player_province"] = "%[playerProvince]%";
      }
      return selectedCharacteristics;
    },
    selectedCharacteristics(): any {
      if (!this.profile) {
        return [];
      }

      return mapTake(
        "real_name",
        "passion",
        "civil_status",
        "is_smoker",
        "has_piercing",
        "has_tattoo",
        "eye_colour",
        "appearance",
        "gender",
        "hobbies",
        "length",
        "profession",
        "sexual_orientation",
        "transportation",
        "build",
      )(this.profile);
    },
    profileCharacteristics(): any {
      const profileCharacteristicsArray = Object.keys(this.selectedCharacteristics).map((characteristic: string) => {
        let value = "";
        const characteristicValue = this.selectedCharacteristics[characteristic];
        if (characteristicValue === true) {
          value = "Yes";
        } else if (characteristicValue === false) {
          value = "No";
        } else if (Array.isArray(characteristicValue)) {
          value = characteristicValue.join(", ");
        } else {
          value = characteristicValue;
        }

        if (characteristic === "length") {
          value =
            this.profile?.geo && ["GB", "US"].includes(this.profile.geo[0].iso) ? rangeCMToFeet(value) : `${value} cm`;
        }

        return {
          name: characteristic,
          value: value || "not set",
          icon: iconMapping[characteristic as keyof IconMapping] || "mdi-arrow-right",
        };
      });

      return profileCharacteristicsArray;
    },
    fullLocation(): string {
      if (!this.profile) {
        return "";
      }
      return profileService.fullLocation(this.profile);
    },
    avatarUrl(): any {
      const cdnUrl = process.env.VUE_APP_CDN_BASE_URL;
      return this.isLegacy ? this.profile?.avatar : `${cdnUrl}/${this.profile?.avatar}`;
    },
    isLegacy(): boolean {
      return this.origin === ORIGIN_TYPE_LEGACY;
    },
    canSendImmediately(): boolean {
      return RoleAccess.hasAnyRole([Roles.Admin]);
    },
    isEditMode(state: PokeWizardStoreState): boolean {
      return !!state.id;
    },
    hasMessageInput(): boolean {
      return this.pokeType === POKE_TYPE_MESSAGE;
    },
    hasSearchedFilters: (state: PokeWizardStoreState): boolean => {
      return Object.keys(state.searchedFilters).length > 0 || state.query.length > 0;
    },
    hasPokeTargets(): boolean {
      return useProfileStore().customerProfilesCount > 0;
    },
    reasonsOfInvalidity(): string {
      const reasons = {
        "there is no targets": !this.hasPokeTargets,
        "filter 'in conversation' is enabled": this.legacyFilters.in_conversation,
      };
      const activeReasonsMessages = Object.entries(reasons)
        .filter(([_reason, value]) => Boolean(value))
        .map(([reason, _value]) => reason);
      return activeReasonsMessages.length > 0 ? "It's disabled because: " + activeReasonsMessages.join(", ") : "";
    },
    isPokeValidToSend(): boolean {
      return !this.reasonsOfInvalidity;
    },
  },
  actions: {
    checkAttachmentWasAlreadyUsedInPoke(url: string): boolean {
      const usedAttachmentsInPoke = this.followUps
        .filter((followUp: FollowUpPoke) => followUp.poke.attachment)
        .map((followUp: FollowUpPoke) => followUp.poke.attachment.displayUrl);
      if (this.mainPokeMessage.attachment) {
        usedAttachmentsInPoke.push(this.mainPokeMessage.attachment.displayUrl);
      }
      return usedAttachmentsInPoke.includes(url);
    },
    resetPokeWizard() {
      this.$patch(structuredClone(defaultPokeWizard));
    },
    resetMainAttachment() {
      this.mainPokeMessage.attachment = null;
      this.mainPokeMessage.hasAttachment = false;
      this.mainPokeMessage.attachmentFilenameForLegacy = null;
    },
    async setFollowUps(followUps: FollowUpPoke[]) {
      this.followUps = await Promise.all(
        followUps.map(async (followUp) => ({
          ...followUp,
          poke: {
            ...followUp.poke,
            attachment: await convertAttachmentToObject(followUp.poke.attachment, this.domains[0]),
          },
        })),
      );
    },
    setProfile(profile: Profile) {
      this.profile = profile;
    },
    setSelectedProfiles(selectedProfiles: Profile[]) {
      this.selectedProfiles = selectedProfiles;
      this.setProfile(selectedProfiles[0]);
    },
    setLegacyFilters(filters: LegacyPokeFilters) {
      this.legacyFilters = filters;

      if (!this.profile || (this.legacyFilters?.selectedTargetsPerDomain as any)?.targets?.length > 0) {
        return;
      }

      useProfileStore().getCustomerCounts({
        origin: this.origin,
        domains: this.domains,
        filters: this.filtersForApi,
        profile: this.profile,
        locale: this.locale,
      });
    },
    setFilters(filters: Record<string, any>) {
      this.filters = filters;

      if (!this.profile) {
        return;
      }

      useProfileStore().getCustomerCounts({
        origin: this.origin,
        domains: this.domains,
        filters: this.filtersForApi,
        profile: this.profile,
        locale: this.locale,
      });
    },
    setDomainConfigurations(domainConfigurations: DomainConfiguration[]) {
      this.domainConfigurations = domainConfigurations;

      if (!domainConfigurations.length) {
        this.geoStrategy = null;
        this.domains = [];
        return;
      }

      this.geoStrategy = domainConfigurations[0].geoStrategy;
      this.domains = domainConfigurations.map((domainConfiguration) => domainConfiguration.domain);
    },
    setTargetFilterType(targetFilterType: string): void {
      this.legacyFilters = { ...this.legacyFilters, targetFilterType };
    },
    clearSelectedTargetPerDomain() {
      this.legacyFilters.selectedTargetsPerDomain = null;
    },
    async initializeMainPokeData(poke: PokeDocument): Promise<void> {
      if (poke.strategy === POKE_STRATEGY_FOLLOW_UP && poke.followUp?.parentId) {
        await router.push(`/pokes/${poke.locale}/${poke.followUp.parentId}/edit`);
        return;
      }

      this.pokeType = poke.type; // this should be the first change! because we reset this state to default when the type is changed
      const pokeDomainConfigurations = useConfigStore().domains.filter((domainConfiguration: DomainConfiguration) => {
        return poke.domains.includes(domainConfiguration.domain);
      });
      this.setDomainConfigurations(pokeDomainConfigurations);
      this.locale = poke.locale;
      this.id = poke.id;
      this.origin = ORIGIN_TYPE_LEGACY;
      this.action = "update";
      this.originalPoke = poke;
      this.strategy = poke.strategy ?? POKE_STRATEGY_DEFAULT;

      this.mainPokeMessage = {
        ...poke.message,
        attachment: await convertAttachmentToObject(poke.message.attachment, this.domains[0]),
      } as unknown as PokeMessage;

      if (poke.strategy === POKE_STRATEGY_FOLLOW_UP) {
        const followUpPokes = await firebaseRepoManager.dispatch("poke", poke.locale, "getFollowUpPokes", poke.id);

        await this.setFollowUps(followUpPokes);
      }

      await useProfileStore().getPokeEntertainmentProfile({
        entertainmentProfileId: poke.profile.id,
        domain: pokeDomainConfigurations[0].domain,
        entertainmentProfileUuid: poke.profile.uuid,
      });
      const profile = useProfileStore().activeEntertainmentProfile;
      this.setSelectedProfiles([profile]);

      if (poke.createdAt == poke.scheduled) {
        this.mainPokeMessage.scheduledDateTime = null;
      } else {
        const scheduledDate = dayjs(poke.scheduled?.seconds * 1000).tz(poke.profile?.timezone || "UTC");
        this.mainPokeMessage.scheduledDateTime = scheduledDate;
      }

      if (poke.strategy === POKE_STRATEGY_RECURRING && poke.frequency?.day) {
        this.frequency = poke.frequency;
      }

      this.setLegacyFilters(poke.filters);
      useAttachmentsStore().loadAttachments(pokeDomainConfigurations[0], profile);
    },
    async setPlayerFilters(): Promise<void> {
      try {
        const url = "/profile/filters";
        const filters = await http.get(url);
        this.pokePlayerFilters = filters.data.data;
      } catch (error) {
        useNotificationStore().addNotification({
          message: error.toString(),
          type: NotificationTypes.Error,
        });
      }
    },
    async searchPlayers({ domains, query, filters = {}, page = 1, limit = 10 }: any): Promise<Profile[]> {
      const domainConfigs = useConfigStore().domainConfigs(domains);
      const uniqueLocalesFromAllDomains = Array.from(
        new Set(domainConfigs.map((domainConfig: DomainConfiguration) => domainConfig.locale)),
      );

      const commonProfileTypes = getCommonProfileTypes(domainConfigs);
      const haveAllDomainsProfileApiIntegration = domainConfigs.every((domainConfig) => domainConfig.profileApiEnabled);
      const haveCombinedIntegrations = haveAllDomainsProfileApiIntegration
        ? false
        : Object.values(domainConfigs).some((domainsConfiguration) => domainsConfiguration.profileApiEnabled);

      try {
        if (haveCombinedIntegrations) {
          // if combination of profile api and message central filtering is used
          const mcDomains = Object.values(domainConfigs)
            .filter((domainsConfiguration: DomainConfiguration) => !domainsConfiguration.profileApiEnabled)
            .map((domainsConfiguration: DomainConfiguration) => domainsConfiguration.domain);

          const profileApiDomains = Object.values(domainConfigs)
            .filter((domainsConfiguration: DomainConfiguration) => domainsConfiguration.profileApiEnabled)
            .map((domainsConfiguration: DomainConfiguration) => domainsConfiguration.domain);

          const mcSearchedPlayers = await useProfileStore().getEntertainmentProfilesFromMessageCentral({
            locales: uniqueLocalesFromAllDomains,
            domains: mcDomains,
            query: this.query,
            filters: { ...this.searchedFilters },
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
          });

          const uuids = mcSearchedPlayers.map((player: any) => player.uuid);
          const profileApiPlayers = await useProfileStore().getEntertainmentProfilesFromProfileAPI({
            locales: uniqueLocalesFromAllDomains,
            profileTypes: commonProfileTypes,
            domains: profileApiDomains,
            query: this.query,
            filters: { uuids: uuids },
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
          });

          return _intersectionBy(mcSearchedPlayers, profileApiPlayers, (player: any) => player.uuid);
        }

        const getEntertainmentProfiles = haveAllDomainsProfileApiIntegration
          ? useProfileStore().getEntertainmentProfilesFromProfileAPI
          : useProfileStore().getEntertainmentProfilesFromMessageCentral;

        return await getEntertainmentProfiles({
          locales: uniqueLocalesFromAllDomains,
          profileTypes: commonProfileTypes,
          domains,
          query,
          filters,
          page,
          limit,
        });
      } catch (error) {
        return [];
      }
    },
    async setSearchedPlayers() {
      this.searchedPlayers = await this.searchPlayers({
        domains: this.domains,
        query: this.query,
        filters: this.searchedFilters,
        page: this.pagination.page,
        limit: this.pagination.itemsPerPage,
      });
    },
  },
});
