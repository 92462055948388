import { defineStore } from "pinia";
import { resetAllStores } from "@/stores";
import { getApp } from "firebase/app";
import {
  getAuth,
  signOut,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  User,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
  MultiFactorResolver,
} from "firebase/auth";
import { LoginCredentials, Roles, NotificationTypes, UserData, AuthStoreState } from "@/types";
import router from "@/router";
import RoleAccess from "@/plugins/RoleAccess";
import { firebaseRepoManager } from "@/firebase/FirebaseRepo.class";
import { getAuthErrorDescription } from "@/helpers/authCodeErrors";
import {
  ERROR_AUTH_CODE_EXPIRED,
  ERROR_AUTH_RE_LOGIN,
  ERROR_INVALID_MF_SESSION,
  ERROR_TOO_MANY_ATTEMPTS,
} from "@/constants";
import { useNotificationStore } from "@/stores/notifications";
import { useConfigStore } from "@/stores/config";
import { useSocketStore } from "./socket";
import { useAnnouncementsStore } from "@/stores/announcements";
import { DomainConfiguration } from "@/types";

const RESET_COOLDOWN = 15;
const MS_PER_MINUTE = 60000;
const recaptchaElementId = "google-recaptcha-container";
let recaptchaVerifier: RecaptchaVerifier | null = null;

/**
 * Checks to see if a user has at least one of the required roles.
 *
 * @param roles: string[]
 */
function hasAccessToDomain(roles: Roles[]) {
  const requiredRoles = [Roles.Admin, Roles.Manager, Roles.Operator, Roles.Poker, Roles.AbuseChecker];

  return roles.some((role) => {
    return requiredRoles.includes(role);
  });
}

export const useAuthenticatorStore = defineStore("authenticator", {
  state: (): AuthStoreState => ({
    isUserInitialized: false,
    token: "",
    forceMfa: false,
    hasMfaEnabled: false,
    user: {} as UserData,
    password: {
      hasReset: false,
      requestedAt: new Date(),
    },
    mfaVerificationId: "",
    tenant: "",
    resolver: null,
  }),
  getters: {
    isUserSessionAuthorized({ user, forceMfa, hasMfaEnabled }): boolean {
      return user.uid ? (forceMfa ? hasMfaEnabled : true) : false;
    },
    localesFilteredByDomains() {
      if (!useConfigStore().domains) {
        return [];
      }

      return useConfigStore().domains.reduce((locales: string[], domainConfiguration: DomainConfiguration) => {
        if (
          locales.includes(domainConfiguration.locale) ||
          !useAuthenticatorStore().user.geos.includes(domainConfiguration.locale)
        ) {
          return locales;
        }

        locales.push(domainConfiguration.locale);
        return locales;
      }, []);
    },
  },
  actions: {
    setPasswordReset() {
      this.password.hasReset = true;
      this.password.requestedAt = new Date();
      localStorage.setItem("passwordReset", JSON.stringify(this.password));
    },
    clearPasswordReset() {
      this.password.hasReset = false;
      localStorage.removeItem("passwordReset");
    },
    initiatePasswordReset() {
      const password = JSON.parse(localStorage.getItem("passwordReset"));

      if (password) {
        password.requestedAt = new Date(password.requestedAt);
      }

      this.password = password || this.password;
    },
    async initiateUser(user: User): Promise<void> {
      const tokenResult = await user.getIdTokenResult(true);
      const roles: Roles[] = tokenResult.claims.roles || [];

      if (!hasAccessToDomain(roles)) {
        useNotificationStore().addNotification({
          message: "You do not have permission to access this domain.",
          type: NotificationTypes.Error,
        });
        const firebaseApp = getApp();
        const auth = getAuth(firebaseApp);
        signOut(auth);
      }
      const newUser = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoUrl: user.photoURL,
        score: tokenResult.claims?.score,
        roles,
        geos: tokenResult.claims.geos,
        claims: tokenResult.claims,
        companyId: tokenResult.claims.company,
      };

      RoleAccess.setRoles(roles as string[]);

      this.user = newUser;
      this.tenant = user.tenantId;
      this.token = tokenResult.token;
      this.isUserInitialized = true;
      this.forceMfa = tokenResult.claims.forceMfa ?? false;
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      this.hasMfaEnabled = multiFactor(auth.currentUser).enrolledFactors.length > 0;

      await useConfigStore().initAppConfig();
      try {
        await firebaseRepoManager.init();
      } catch (error: any) {
        useNotificationStore().addNotification({
          message: `${getAuthErrorDescription(error.code)}`,
          type: NotificationTypes.Error,
        });
      }
    },
    setResolver(resolver: any) {
      this.resolver = resolver;
    },
    unsetSocketConnection() {
      useSocketStore().disconnect();
    },
    setSocketConnection(isForced = false): void {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      onIdTokenChanged(auth, async (changedUser) => {
        if (!changedUser) {
          useSocketStore().disconnect();
          return;
        }

        const changedToken = await changedUser.getIdTokenResult();
        const changedRoles: string[] = (changedToken.claims?.roles as string[]) ?? [];

        const hasAppropriateRoleToAutoCLaim: boolean =
          changedRoles.includes(Roles.Operator) && !changedRoles.includes(Roles.Admin);
        if (isForced || hasAppropriateRoleToAutoCLaim) {
          useSocketStore().connect(changedToken.token);
          return;
        }

        useSocketStore().disconnect();
      });
    },
    async authenticate(): Promise<void> {
      const user: User | null = await this.getCurrentUser();
      if (!user) {
        return;
      }
      await this.initiateUser(user);
    },
    async login(loginCredentials: LoginCredentials): Promise<User | null> {
      try {
        const firebaseApp = getApp();
        const auth = getAuth(firebaseApp);
        const userCredential = await signInWithEmailAndPassword(
          auth,
          loginCredentials.email,
          loginCredentials.password,
        );
        const user = userCredential.user;
        await this.initiateUser(user);

        if (RoleAccess.hasRole(Roles.Operator)) {
          useAnnouncementsStore().isAnnouncementDialogShown = true;
        }
        localStorage.setItem("selectedGeo", JSON.stringify(null));
        return user;
      } catch (error: any) {
        if (error.code === "auth/multi-factor-auth-required") {
          throw error;
        }
        useNotificationStore().addNotification({
          message: getAuthErrorDescription(error.code),
          type: NotificationTypes.Error,
        });
        return;
      }
    },
    getCurrentUser(): Promise<User | null> {
      return new Promise((resolve, reject) => {
        const firebaseApp = getApp();
        const auth = getAuth(firebaseApp);
        const unsubscribe = onAuthStateChanged(
          auth,
          (user) => {
            unsubscribe();
            resolve(user);
          },
          reject,
        );
      });
    },
    async signOut(): Promise<void> {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);

      this.unsetSocketConnection();

      await signOut(auth);
      firebaseRepoManager.teardown();

      resetAllStores(); // reset Pinia
      this.destroyRecaptchaElement();

      const loginPath = "/login";
      router.currentRoute.path !== loginPath && (await router.push(loginPath));
    },

    async resetPassword(email: string): Promise<boolean> {
      this.initiatePasswordReset();
      const resetDate = new Date(Date.now() - MS_PER_MINUTE * RESET_COOLDOWN);

      if (this.password.hasReset && this.password.requestedAt <= resetDate) {
        this.clearPasswordReset();
      }

      if (this.password.hasReset && this.password.requestedAt > resetDate) {
        useNotificationStore().addNotification({
          message: "You have to wait 15 minutes before another email can be send.",
          type: NotificationTypes.Error,
        });
        return false;
      }

      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      sendPasswordResetEmail(auth, email)
        .then(() => {
          this.setPasswordReset();
          useNotificationStore().addNotification({
            message: "An email will be sent to your address with a password reset option.",
            type: NotificationTypes.Success,
          });
          return true;
        })
        .catch((error: any) => {
          this.clearPasswordReset();
          useNotificationStore().addNotification({
            message: `${getAuthErrorDescription(error.code)}`,
            type: NotificationTypes.Error,
          });
          return false;
        });
    },
    async setMfaPhoneNumber(phoneNumber: string) {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);

      const multiFactorUser = multiFactor(auth.currentUser);
      const multiFactorSession = await multiFactorUser.getSession();
      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      try {
        await this.initiateRecaptchaVerifier();
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);

        this.mfaVerificationId = verificationId;

        useNotificationStore().addNotification({
          message: `Validation code sent to the registered mobile number.`,
          type: NotificationTypes.Success,
        });

        return true;
      } catch (error: any) {
        useNotificationStore().addNotification({
          message: `${getAuthErrorDescription(error.code)}`,
          type: NotificationTypes.Error,
        });

        if (
          [ERROR_AUTH_RE_LOGIN, ERROR_TOO_MANY_ATTEMPTS, ERROR_INVALID_MF_SESSION, ERROR_AUTH_CODE_EXPIRED].includes(
            error.code,
          )
        ) {
          this.signOut();
        }
        return false;
      }
    },
    async setMfaCode(verificationCode: string) {
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      const cred = PhoneAuthProvider.credential(this.mfaVerificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      const multiFactorUser = multiFactor(auth.currentUser);
      await multiFactorUser.enroll(multiFactorAssertion, "Phone");
    },
    async initiateLoginWithMobileMfa(resolver: MultiFactorResolver) {
      const hint = resolver.hints[0];

      const phoneInfoOptions = {
        multiFactorHint: hint,
        session: resolver.session,
      };
      const firebaseApp = getApp();
      const auth = getAuth(firebaseApp);
      const phoneAuthProvider = new PhoneAuthProvider(auth);

      await this.initiateRecaptchaVerifier();
      this.mfaVerificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    },

    async loginWithMfa({ verificationCode, resolver }: { verificationCode: string; resolver: MultiFactorResolver }) {
      const cred = PhoneAuthProvider.credential(this.mfaVerificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);

      await this.initiateUser(userCredential.user);
    },
    async initiateRecaptchaVerifier(): Promise<any> {
      const auth = getAuth(getApp());

      if (recaptchaVerifier) {
        return recaptchaVerifier;
      }

      this.prepareRecaptchaElement();

      try {
        recaptchaVerifier = new RecaptchaVerifier(
          recaptchaElementId,
          {
            size: "invisible",
            "expired-callback": () => {
              this.destroyRecaptchaElement();
            },
          },
          auth,
        );
        return recaptchaVerifier;
      } catch (error: any) {
        useNotificationStore().addNotification({
          message: `${getAuthErrorDescription(error.code)}`,
          type: NotificationTypes.Error,
        });
      }
    },
    prepareRecaptchaElement(): any {
      if (document.getElementById(recaptchaElementId)) {
        return;
      }

      /*
       * In order to avoid the timeout issue we are setting the recaptcha element as global instead of recreating it
       * In order to Recaptcha works well, we need to keep the recaptcha element outside of the vue js components
       */
      document.body.insertAdjacentHTML("beforeend", `<div id="${recaptchaElementId}"></div>`);
    },
    destroyRecaptchaElement() {
      recaptchaVerifier = null;
      document.getElementById(recaptchaElementId)?.remove();
    },
  },
});
