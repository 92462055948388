import { defineStore } from "pinia";
import { PokeQueueStoreState, NotificationTypes } from "@/types";
import { useNotificationStore } from "./notifications";
import http from "@/services/http";

export const usePokeQueueStore = defineStore("pokeQueue", {
  state: (): PokeQueueStoreState => ({
    locations: [],
    queue: [],
    selectedLocation: "",
  }),
  actions: {
    async loadLocations(): Promise<void> {
      try {
        const url = "/poke/queue/statistics/locations";
        const locations = await http.get(url);
        this.locations = Object.keys(locations.data.data);
      } catch (error) {
        useNotificationStore().addNotification({
          message: error.toString(),
          type: NotificationTypes.Error,
        });
      }
    },
    async setQueue(): Promise<void> {
      if (!this.selectedLocation) {
        useNotificationStore().addNotification({
          message: "Please select region and location",
          type: NotificationTypes.Error,
        });
        return;
      }

      try {
        const url = `/poke/queue/statistics/${this.selectedLocation}`;
        const queueItemsResponse = await http.get(url);
        this.queue = queueItemsResponse.data.data;
      } catch (error: any) {
        this.queue = [];
        useNotificationStore().addNotification({
          message:
            error.response.status === 500 || error.response.status === 422
              ? "No data for this location"
              : error.toString(),
          type: NotificationTypes.Error,
        });
      }
    },
  },
});
