import { NonRealtimeRepository, ReportedMessage, ReportedMessagesFilter, NotificationTypes } from "@/types";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  Timestamp,
  startAfter,
  endBefore,
  CollectionReference,
  QuerySnapshot,
  limitToLast,
  Query,
  orderBy,
  limit,
  where,
  updateDoc,
} from "firebase/firestore";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useNotificationStore } from "@/stores/notifications";
import { useReportedMessages } from "@/stores/reportedMessages";

export class ReportedMessagesRepository implements NonRealtimeRepository {
  private _reportedMessagesCollectionRef: CollectionReference<ReportedMessage>;
  private _reportedMessagesUnsubscribeFunction: () => void;

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    const firestore = getFirestore(this._project);
    const reportedMessageCollection = collection(firestore, "geos");
    const reportedMessageDoc = doc(reportedMessageCollection, _locale);
    this._reportedMessagesCollectionRef = collection(
      reportedMessageDoc,
      "reported_messages",
    ) as CollectionReference<ReportedMessage>;
  }

  private setVisibleDocuments(documentSnapshots: QuerySnapshot): void {
    const firstVisible = documentSnapshots.docs[0];
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
    useReportedMessages().firstReportedMessageId = firstVisible.id;
    useReportedMessages().lastReportedMessageId = lastVisible.id;
  }

  private async createReportedMessagesQuery(filters: ReportedMessagesFilter): Promise<Query> {
    let reportedMessagesQuery: Query = this._reportedMessagesCollectionRef;
    const sortBy = filters.sortBy || "report.reportedAt";
    const sortDirection = filters.sortDesc ? "desc" : "asc";
    reportedMessagesQuery = query(reportedMessagesQuery, orderBy(sortBy, sortDirection));

    const limitAmount = 10;
    if (!filters.direction) {
      reportedMessagesQuery = query(reportedMessagesQuery, limit(limitAmount));
    }
    if (filters.direction === "next") {
      const docRef = doc(this._reportedMessagesCollectionRef, useReportedMessages().lastReportedMessageId);
      const lastReportedMessage = await getDoc(docRef);
      reportedMessagesQuery = query(reportedMessagesQuery, startAfter(lastReportedMessage));
      reportedMessagesQuery = query(reportedMessagesQuery, limit(limitAmount));
    }
    if (filters.direction === "previous") {
      const firstReportedMessage = await getDoc(
        doc(this._reportedMessagesCollectionRef, useReportedMessages().firstReportedMessageId),
      );
      reportedMessagesQuery = query(reportedMessagesQuery, endBefore(firstReportedMessage));
      reportedMessagesQuery = query(reportedMessagesQuery, limitToLast(limitAmount));
    }
    if (filters.category) {
      reportedMessagesQuery = query(reportedMessagesQuery, where("report.category", "==", filters.category));
    }

    return reportedMessagesQuery;
  }

  public async dispatch(
    action: "getReportedMessages" | "getReportedMessage" | "setProcessedStatus" | "createReportedMessage",
    params: any,
  ): Promise<void> {
    this[action](params);
  }

  public disableAllListeners(): void {
    this._reportedMessagesUnsubscribeFunction?.();
  }

  public async getReportedMessage(id: string): Promise<void> {
    const docRef = doc(this._reportedMessagesCollectionRef, id);
    const document = await getDoc(docRef);
    if (!document.exists()) {
      useReportedMessages().currentReportedMessage = null;
      return;
    }

    const reportedMessage = {
      id: document.id,
      ...document.data(),
    } as ReportedMessage;
    useReportedMessages().currentReportedMessage = reportedMessage;
  }

  public async getReportedMessages(filters: ReportedMessagesFilter): Promise<void> {
    try {
      const querySnapshot = await getDocs(await this.createReportedMessagesQuery(filters));

      if (querySnapshot.empty) {
        useReportedMessages().reportedMessages = [];
        return;
      }

      this.setVisibleDocuments(querySnapshot);

      const reportedMessages = await Promise.all(
        querySnapshot.docs.map(async (document: any) => {
          const reportedMessage = {
            id: document.id,
            ...document.data(),
          } as ReportedMessage;

          return reportedMessage;
        }),
      );
      useReportedMessages().reportedMessages = reportedMessages;
    } catch (error: any) {
      useNotificationStore().addNotification({
        message: error,
        type: NotificationTypes.Error,
      });
    }
  }

  public async setProcessedStatus({ id, isProcessed }: { id: string; isProcessed: boolean }): Promise<void> {
    const docRef = doc(this._reportedMessagesCollectionRef, id);
    await updateDoc(docRef, { "report.processed": isProcessed } as any);
    const reportedMessageDocument = await getDoc(doc(this._reportedMessagesCollectionRef, id));
    useReportedMessages().updateReportedMessage({
      id: reportedMessageDocument.id,
      ...reportedMessageDocument.data(),
    });
  }

  public async createReportedMessage(reportedMessage: ReportedMessage): Promise<void> {
    await addDoc(this._reportedMessagesCollectionRef, {
      ...reportedMessage,
      locale: this._locale,
      report: {
        ...reportedMessage.report,
        processed: false,
        reportedAt: Timestamp.now(),
        reportedBy: useAuthenticatorStore().user.uid,
      },
    });
  }
}
