import _Vue from "vue";
import pluralize from "pluralize";

function makePlural(value: string, number: number): string {
  return pluralize(value, number, true);
}

export default {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$makePlural = makePlural;
  },
};
