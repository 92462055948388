export const ORIGIN_TYPE_LEGACY = "legacy";
export type OriginType = typeof ORIGIN_TYPE_LEGACY;

export const POKE_STRATEGY_DEFAULT = "default";
export const POKE_STRATEGY_RECURRING = "recurring";
export const POKE_STRATEGY_FOLLOW_UP = "follow-up";
export type PokeStrategyName =
  | typeof POKE_STRATEGY_DEFAULT
  | typeof POKE_STRATEGY_RECURRING
  | typeof POKE_STRATEGY_FOLLOW_UP;

export const ABUSE_TYPE_POKE = "poke";
export const ABUSE_TYPE_MESSAGE = "message";

export const MESSAGE_TYPE_POKE = "poke";
export const MESSAGE_TYPE_NORMAL = "normal";
export type MessageTypeName = PokeTypeName | typeof MESSAGE_TYPE_POKE | typeof MESSAGE_TYPE_NORMAL;

export const CUSTOMER_SENDER_TYPE = "customer";
export const PROFILE_SENDER_TYPE = "entertainmentProfile";
export const OPERATOR_SENDER_TYPE = "agent";

export const TRIGGER_TYPE_STOPPED_CONVERSATION = "stopped_conversation";
export const TRIGGER_TYPE_RE_ENGAGEMENT_CONVERSATION = "re_engagement";

export const NOT_AVAILABLE = "Not available";

export const POKE_REPOSITORY = "poke";

export const POKE_STATUS_NONE = "Done";
export const POKE_STATUS_SCHEDULED = "Scheduled";
export const POKE_STATUS_IN_PROGRESS = "In Progress";
export const POKE_STATUS_DONE = "Done";
export const POKE_STATUS_CANCELLED = "Cancelled";
export const POKE_STATUS_KILLED = "Killed";
export const POKE_STATUS_MIXED = "Mixed";
export const POKE_STATUS_CRASHED = "Crashed";

// Following are color codes and required in lower case
export const POKE_STATUS_COLOR_BLUE = "blue";
export const POKE_STATUS_COLOR_GREEN = "green";
export const POKE_STATUS_COLOR_ORANGE = "orange";
export const POKE_STATUS_COLOR_RED = "red";

export const POKE_TYPE_MESSAGE = "message";
export const POKE_TYPE_FLIRT = "flirt";
export const POKE_TYPE_FAVORITE = "favorite";
export const POKE_TYPE_EVENT_LIKE = "event_like";
export const POKE_TYPE_PROPOSAL = "proposal";
export type PokeTypeName =
  | typeof POKE_TYPE_MESSAGE
  | typeof POKE_TYPE_FLIRT
  | typeof POKE_TYPE_FAVORITE
  | typeof POKE_TYPE_EVENT_LIKE
  | typeof POKE_TYPE_PROPOSAL;

export const ABUSE_STATUS_PENDING = "pending";
export const ABUSE_STATUS_ABUSE = "abuse";
export const ABUSE_STATUS_SAFE = "safe";
export const ABUSE_STATUS_EXPIRED = "expired";

export const ABUSE_STATUS_ABUSE_COLOR = "red";
export const ABUSE_STATUS_SAFE_COLOR = "green";

// reporting categories titles
export const REPORT_CATEGORY_AGENT_CONTACT = "Giving out contact info";
export const REPORT_CATEGORY_CUSTOMER_CONTACT = "Visible contact info";
export const REPORT_CATEGORY_COMPANY_SERVICE = "Abusive message about company services";
export const REPORT_CATEGORY_AGENT_ABUSE = "Operator is abusive to the customer";
export const REPORT_CATEGORY_CUSTOMER_ABUSE = "Customer being abusive";
export const REPORT_CATEGORY_MISLEADING = "Misleading (setting up a date, love, etc)";
export const REPORT_CATEGORY_ILLEGAL = "Illegal: drugs, violence, children, animals, etc.";
export const REPORT_CATEGORY_NEW_CATEGORY = "New category";
export const REPORT_CATEGORY_BAD_QUALITY = "Bad Quality / Answer is not relevant";
export const REPORT_CATEGORY_OTHER = "Other";

// Logbooks variables
export const MAX_ALLOWED_COMMENT_SIZE = 120;
export const OTHER_CATEGORY = "other";

export const HOTJAR_ID = "2923755";

export const RECURRING_POKE_EXECUTION_LIMIT = 0;

export const ATTACHMENT_HASH_REGEX = "[a-f0-9]{16,}";
export const ATTACHMENT_CDN_REGEX = `^${ATTACHMENT_HASH_REGEX}(.webp)?$`;

// Suggestions
export const EXCLUDED_SUGGESTION_TRIGGER_TYPES = new Set<string>(["stopped_conversation", "re_engagement"]);
