import {
  Attachment,
  DomainConfiguration,
  GeoFreeLocationApiResponse,
  Profile,
  ProfileConnector,
  TargetAutoCompleteItem,
  Customer,
  FilterPlatersByNameParams,
} from "@/types";
import { mapLegacyAttachments } from "@/helpers/utility";
import { mapLegacyProfile } from "@/helpers/profileLegacyMapper";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import qs from "qs";
import { buildQueryStringFromObject } from "@/helpers/url";
import _pickBy from "lodash/pickBy";
import _identity from "lodash/identity";
import { useConfigStore } from "@/stores/config";

export default class MessageCentralProfileConnector implements ProfileConnector {
  private repository: AxiosInstance;
  private domain: string;
  static abortControllers = {} as Record<string, AbortController>;

  constructor(domain: string) {
    this.domain = domain;
    this.initializeConnector(domain);
  }

  initializeConnector(domain: string): void {
    const getSignalKey = (config: AxiosRequestConfig): string => config.baseURL + config.url + domain;
    const deleteController = (config: AxiosRequestConfig): void => {
      const signalKey = getSignalKey(config);
      MessageCentralProfileConnector.abortControllers[signalKey]?.abort();
    };

    const domainConfig = useConfigStore().domainConfig(domain) as DomainConfiguration;

    this.repository = axios.create({
      baseURL: domainConfig.messageCentralUrl,
    });

    this.repository.interceptors.request.use(
      async (config) => {
        deleteController(config);

        const signalKey = getSignalKey(config);
        MessageCentralProfileConnector.abortControllers[signalKey] = new AbortController();
        config.signal = MessageCentralProfileConnector.abortControllers[signalKey].signal;
        const firebaseApp = getApp();
        const token = await getAuth(firebaseApp).currentUser?.getIdToken();
        config.headers.Authorization = `Bearer ${token}`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    this.repository.interceptors.response.use(
      (response: AxiosResponse) => {
        deleteController(response.config);

        return response;
      },
      (error) => {
        if (!axios.isCancel(error)) {
          return Promise.reject(error);
        }

        return new Promise(() => {
          //
        });
      },
    );
  }

  async getEntertainmentProfiles({
    query,
    filters,
    page,
    limit,
  }: {
    query: string;
    filters: any;
    page: number;
    limit: number;
  }): Promise<Profile[]> {
    const response = await this.repository.get(
      `/pokes/filterPlayer?site=${this.domain}&query=${query}&limit=${limit}&page=${page}`,
      {
        params: {
          site: this.domain,
          query,
          filters,
          page,
          limit,
        },
        paramsSerializer: (params: any) => qs.stringify(params.filters, { arrayFormat: "brackets" }),
      },
    );

    return response.data.data.map((profileData: any) => {
      return mapLegacyProfile(profileData.profile);
    });
  }

  async getEntertainmentProfilesCount(filters: any): Promise<number> {
    return this.repository.get(`/user/profiles/count-profiles`, filters);
  }

  async getEntertainmentProfile(
    entertainmentProfileId: string | number,
    entertainmentProfileUuid?: string,
  ): Promise<Profile> {
    const path = entertainmentProfileUuid
      ? `/profiles/${entertainmentProfileUuid}/${this.domain}`
      : `/profiles/${entertainmentProfileId}?isComplete=true`;
    const response = await this.repository.get(path);
    return mapLegacyProfile(response.data.data) as Profile;
  }

  async getGeoFreeEntertainmentProfileLocation(
    entertainmentProfileId: string,
    countryCode: string,
    customerLat: string,
    customerLng: string,
  ): Promise<GeoFreeLocationApiResponse> {
    const params = {
      countryCode,
      customerLat,
      customerLng,
    };
    const queryString = buildQueryStringFromObject(params);
    const result = await this.repository.get(`/profiles/${entertainmentProfileId}/geowizard?${queryString}`);
    return result.data.data;
  }

  async getPokeEntertainmentProfile(
    entertainmentProfileId: string,
    domain: string,
    entertainmentProfileUuid: string,
  ): Promise<Profile> {
    const profileId = entertainmentProfileId || entertainmentProfileUuid;
    const {
      data: { data },
    } = await this.repository.get(`/pokes/profiles/${profileId}?siteName=${domain}`);

    return mapLegacyProfile(data) as Profile;
  }

  async getCustomerProfile({
    customerProfileId,
    customerUuid,
  }: {
    customerProfileId: string;
    customerUuid: string;
  }): Promise<Profile> {
    const profileId = customerUuid || customerProfileId;
    const {
      data: { data },
    } = await this.repository.get(`/profiles/${profileId}?isComplete=true`);

    return mapLegacyProfile(data) as Profile;
  }

  async getCustomerProfilesCount({
    domainNames,
    filters,
    profileId,
  }: {
    domainNames: string[];
    filters: Record<string, any>;
    profileId: string;
  }): Promise<Record<string, number> | number> {
    const response = await this.repository.get(`/pokes/countTargets`, {
      params: { sites: domainNames, filters, profileCmsId: profileId },
      paramsSerializer: (params: any) => {
        params.sites = params.sites.map((site: string) => {
          return site.split(".").join("_");
        });

        return qs.stringify(params, {
          arrayFormat: "brackets",
        });
      },
    });
    return response.data.data;
  }

  async getAttachments(profile: Profile): Promise<Attachment[]> {
    const response = await this.repository.get(`/profiles/${profile.id}/attachments`);
    return mapLegacyAttachments(response.data.data) as Attachment[];
  }

  async getCreditsBalance(profile: Profile): Promise<number> {
    // return type is Promise<number> in order to implement ProfileConnector
    return +profile.creditsBalance;
  }

  supportMultiDomainCount(): boolean {
    return true;
  }

  async searchCustomerProfilesByName(filters: FilterPlatersByNameParams): Promise<TargetAutoCompleteItem[]> {
    const paramsSerializer = (params: Record<string, any>) => {
      params.sites = params.sites.map((site: string) => site.split(".").join("_"));
      return qs.stringify(params, { arrayFormat: "brackets" });
    };

    const response = await this.repository.get("/pokes/getTargets", {
      params: filters,
      paramsSerializer,
    });
    return response.data.data.map((item: Customer) => ({
      uuid: item.uuid,
      name: item.name,
    }));
  }
}
