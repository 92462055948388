import { AnnouncementsTopic, ChatDocument, PokeDocument } from "@/types";
import { FirebaseApp } from "firebase/app";
import { getFirestore, collection, doc, CollectionReference, DocumentReference } from "firebase/firestore";

export default class FirestoreReferenceGenerator {
  private _localeDocRef: DocumentReference;

  constructor(project: FirebaseApp, locale: string) {
    const firebase = getFirestore(project);
    this._localeDocRef = doc(firebase, `geos/${locale}`);
  }

  public getChatsCollectionRef(): CollectionReference<ChatDocument> {
    return collection(this._localeDocRef, "chats") as CollectionReference<ChatDocument>;
  }

  public getPokesCollectionRef(): CollectionReference<PokeDocument> {
    return collection(this._localeDocRef, "pokes") as CollectionReference<PokeDocument>;
  }

  public getAnnouncementsTopicsCollectionRef(): CollectionReference<AnnouncementsTopic> {
    return collection(this._localeDocRef, "announcements_topics") as CollectionReference<AnnouncementsTopic>;
  }
}
