import { defineStore } from "pinia";
import { ConfigState, DomainConfiguration } from "@/types";
import vuetify from "@/plugins/vuetify";
import { getLogoImgUrl } from "@/helpers/images";
import http from "@/services/http";

export const useConfigStore = defineStore("config", {
  state: (): ConfigState => ({
    variables: {
      central: null,
      profileBaseUrl: "",
      regional: null,
    },
    initialized: false,
    domains: [] as DomainConfiguration[],
    project: null,
    pokeConfig: null,
    appearance: {
      title: "",
      logo: "",
    },
  }),
  getters: {
    domainConfig:
      (state: ConfigState) =>
      (domainName: string): DomainConfiguration => {
        const domainConfiguration = state.domains?.find(
          (domainConfig: DomainConfiguration) => domainConfig.domain === domainName,
        );

        if (!domainConfiguration) {
          throw new Error(
            `Can't find domainConfiguration with claimedChat.domain: ${domainName} and domains: ${state.domains?.map(
              (domainConfig: DomainConfiguration) => domainConfig.domain,
            )}`,
          );
        }

        return domainConfiguration;
      },
    domainConfigs:
      (state: ConfigState) =>
      (domainNames: string[]): DomainConfiguration[] => {
        return (domainNames || [])
          .map((domainName: string) =>
            state.domains?.find((domainConfig: DomainConfiguration) => domainConfig.domain === domainName),
          )
          .filter(Boolean);
      },
    regions: (state: ConfigState) => {
      return Object.keys(state.variables.regional);
    },
    appTitle: (state: ConfigState) => {
      return state.appearance.title ?? "";
    },
    getLogoImageFilename:
      (state: ConfigState) =>
      (suffix = ""): string => {
        if (!state.appearance.logo) {
          return getLogoImgUrl("fallback-logo");
        }

        try {
          return getLogoImgUrl(`${state.appearance.logo}${suffix}`);
        } catch (error) {
          console.warn("Can't find logo image file.", error); // eslint-disable-line no-console
          try {
            return getLogoImgUrl(`fallback-logo${suffix}`);
          } catch (error) {
            console.warn("Can't find logo image file.", error); // eslint-disable-line no-console
            return getLogoImgUrl("fallback-logo");
          }
        }
      },
    getUniqueDomainsFilteredByLocale: (state: ConfigState) => (locale: string) => {
      if (!state.domains || !locale) {
        return {};
      }
      const filteredDomainByLocale = state.domains.filter(
        (domainConfiguration: DomainConfiguration) => domainConfiguration.locale === locale,
      );
      const uniqDomains: Record<string, DomainConfiguration> = {};
      filteredDomainByLocale.forEach((domain: DomainConfiguration) => {
        uniqDomains[domain.domain] = domain;
      });
      return uniqDomains;
    },
  },
  actions: {
    async initCentralProject() {
      const { data: firebaseCentralProject } = await http.get("/firebase/projects/central");
      this.variables.central = firebaseCentralProject;
    },
    async initRegionalProjects() {
      const { data: firebaseRegionalProjects } = await http.get("/firebase/projects/regional");
      this.variables.regional = firebaseRegionalProjects;
    },
    async initAppConfig() {
      const { data: firebaseRegionalProjects } = await http.get("/firebase/projects/regional");
      const { data: domainConfigs } = await http.get("/domains");

      this.variables.regional = firebaseRegionalProjects;
      this.domains = domainConfigs as DomainConfiguration[];
    },
    setConfigInitialized() {
      this.initialized = true;
    },
    async fetchPokeConfig() {
      const { data: pokeConfig } = await http.get("/domains/config/poke");
      this.pokeConfig = pokeConfig;
    },
    async getTenantInfo(): Promise<void> {
      const hostname = window.location.host.replace(/\.$/, ""); // sometimes, the hostname contains ending "." char
      try {
        const response = await http.get(`/tenant/${hostname}/info`);
        const tenantInfo = response.data.data;
        this.appearance = {
          title: tenantInfo.app_name,
          logo: tenantInfo.app_logo_name,
        };

        if (tenantInfo.app_primary_color) {
          vuetify.framework.theme.currentTheme.primary = tenantInfo.app_primary_color;
        }
      } catch (error) {
        console.warn("Can't get the application appearance data for host:", hostname, " Error message:", error); // eslint-disable-line no-console
        this.appearance = {
          title: "",
          logo: "",
        };
      }
    },
  },
});
