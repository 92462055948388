import { LogPredefinedValues } from "@/types";

export const sexPreferences: LogPredefinedValues[] = [
  {
    name: "action",
    title: "Action",
    values: [
      "Anal Sex / pegging",
      "Anal orgasms",
      "Attention",
      "Bisexuality",
      "Blowjob fellatio",
      "Boobjob",
      "Breeding",
      "Cuddling",
      "Cunnilingus",
      "Deep throat",
      "Deference",
      "Edging",
      "Ejaculation",
      "Face Fucking",
      "Face-sitting",
      "Facials",
      "Fingering",
      "G-Spot Stimulation",
      "Handjob",
      "Heterosexuality",
      "Homosexuality",
      "Hugging",
      "Kissing (mouth or full body)",
      "Massages",
      "Masturbation (mutual or alone)",
      "Masturbation (viewing / doing)",
      "Open relationship",
      "Phone sex / sexting / cyber",
      "Polyamory and multiple relationship",
      "Pornography",
      "Prostate milking",
      "Prostate massage",
      "Quickies",
      "Role Play",
      "Rimming",
      "Romance / Affection",
      "Rough Sex",
      "Slow Sex Play",
      "Snuggling",
      "Swallowing",
      "Teasing",
      "Vaginal creampie",
      "Vaginal fisting",
    ],
  },
  {
    name: "toys",
    title: "Sex toys",
    values: [
      "Butt plug",
      "Canes",
      "Cilice",
      "Clamps",
      "Cock rings",
      "Cock straps",
      "Crop",
      "Dildos",
      "Floggers",
      "Fucking machine",
      "Needles",
      "Paddles",
      "Plugs",
      "Prostate massager",
      "Urethra play / Sounding",
      "Vibrators",
      "Whips",
    ],
  },
];
