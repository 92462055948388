
import { defineComponent } from "vue";
import AppHeader from "@/components/AppHeader.vue";
import AnnouncementsDialog from "@/components/AnnouncementsDialog.vue";
import { mapState } from "pinia";
import { useAnnouncementsStore } from "@/stores/announcements";

export default defineComponent({
  name: "MainLayout",
  components: {
    AppHeader,
    AnnouncementsDialog,
  },
  computed: {
    ...mapState(useAnnouncementsStore, ["isAnnouncementDialogShown"]),
  },
});
