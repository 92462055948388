import { ChatDocument, Log, NonRealtimeRepository } from "@/types";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  Timestamp,
  CollectionReference,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { useChatStore } from "@/stores/chat";

export class LogRepository implements NonRealtimeRepository {
  private _chatsCollectionRef: CollectionReference<ChatDocument>;

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    this._chatsCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getChatsCollectionRef();
  }

  public async dispatch(action: "getLogs" | "saveLog" | "removeLog", params: any) {
    await this[action]?.(params);
  }

  async getLogs(chatId: string): Promise<void> {
    const querySnapshot = await getDocs(this.logsRef(chatId));

    if (querySnapshot.empty) {
      useChatStore().resetCurrentLogs();
      return;
    }

    const logs = await Promise.all(
      querySnapshot.docs.map((document: QueryDocumentSnapshot) => {
        const log = {
          ...document.data(),
          id: document.id,
        } as Log;

        return log;
      }),
    );
    useChatStore().resetCurrentLogs();
    logs.forEach((log: Log) => {
      useChatStore().setLog(log);
    });
  }

  async saveLog({ chatId, log }: { chatId: string; log: Log }): Promise<void> {
    const logPayload = {
      ...log,
      locale: this._locale,
      content: {
        group: log.content.group || "other",
        groupValue: log.content.groupValue || null,
        value: log.content.value || null,
      },
    };

    if (log.id) {
      await setDoc(
        doc(this.logsRef(chatId), log.id),
        {
          ...logPayload,
          updatedAt: Timestamp.now(),
          updatedBy: this._user.uid,
        },
        { merge: true },
      );
      await getDoc(doc(this.logsRef(chatId), log.id));
      this.getLogs(chatId);
    } else {
      delete logPayload.id;
      const logRef = await addDoc(this.logsRef(chatId), {
        ...logPayload,
        createdBy: this._user.uid,
        createdByName: this._user.displayName,
        createdAt: Timestamp.now(),
      });
      const logDoc = await getDoc(logRef);
      const log = { id: logDoc.id, ...logDoc.data() } as Log;
      useChatStore().setLog(log);
    }
  }

  async removeLog({ chatId, id }: { chatId: string; id: string }): Promise<void> {
    const docRef = doc(this.logsRef(chatId), id);
    await deleteDoc(docRef);
    this.getLogs(chatId);
  }

  public logsRef(chatId: string): CollectionReference {
    return collection(doc(this._chatsCollectionRef, chatId), "logs");
  }
}
