import { defineStore } from "pinia";
import { GeoState } from "@/types";
import http from "@/services/http";

export const useGeoStore = defineStore("geo", {
  state: (): GeoState => ({
    regions: [],
    cities: [],
    levels: 1,
  }),
  actions: {
    async fetchRegions(filter: Record<string, any> | string): Promise<void> {
      const response = await http.get(`/geo/places/options`, {
        params: filter,
      });
      this.regions = response.data.options;
    },
    async fetchCities(filter: Record<string, any>): Promise<void> {
      const response = await http.get(`/geo/places/options`, {
        params: {
          ...filter,
          level: this.levels + 1,
        },
      });
      this.cities = response.data.options;
    },
    async fetchLevels(strategy: string): Promise<void> {
      const response = await http.get(`/geo/places/levels`, {
        params: { strategy },
      });
      this.levels = response.data.levels;
    },
  },
});
