import { DailyPokeFilters, FollowUpPoke, PokeDocument, Repository, NotificationTypes } from "@/types";
import { POKE_STRATEGY_FOLLOW_UP, POKE_STRATEGY_RECURRING } from "@/types/constants";
import { mapTake, replaceUnicodeEmojisInString } from "@/helpers/utility";
import { extractAttachmentUrlToSave } from "@/helpers/attachment";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  query,
  where,
  doc,
  getDocs,
  getDoc,
  deleteDoc,
  onSnapshot,
  Timestamp,
  CollectionReference,
  QuerySnapshot,
  Query,
  limit,
  setDoc,
  DocumentData,
  orderBy,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import dayjs, { Dayjs } from "dayjs";
import isToday from "dayjs/plugin/isToday";
import _chunk from "lodash/chunk";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import { usePokeScheduleStore } from "@/stores/pokeSchedule";
import { useNotificationStore } from "@/stores/notifications";
import { useProfileStore } from "@/stores/profile";
import { invertAllFalseValuesInOneFilterCategory } from "@/helpers/poke";
import { removeEmptyElements } from "@/helpers/removeEmptyElements";

dayjs.extend(isToday);

export class PokeRepository implements Repository {
  private _pokesCollectionRef: CollectionReference<PokeDocument>;
  private _pokeUnsubscribeFunction: () => void;
  private _pokeProperties = [
    "agent",
    "company",
    "createdAt",
    "domain",
    "domains",
    "filters",
    "message",
    "profile",
    "scheduled",
    "targets",
    "origin",
    "strategy",
    "locale",
    "type",
    "tenant",
  ];

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    this._pokesCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getPokesCollectionRef();
  }

  private createPokeQuery(collection: CollectionReference, options?: any) {
    return query(
      collection,
      orderBy("scheduled", "desc"),
      where("scheduled", ">", options.dateRange.start),
      where("scheduled", "<", options.dateRange.end),
    );
  }

  public async dispatch(
    action:
      | "enableListeners"
      | "disableAllListeners"
      | "hasScheduledPokeByProfileId"
      | "savePoke"
      | "loadPoke"
      | "getDailyPokes"
      | "getFollowUpPokes",
    params: any,
  ): Promise<any> {
    return await this[action]?.(params);
  }

  public enableListeners(options?: any): void {
    this.disableAllListeners();

    this._pokeUnsubscribeFunction = onSnapshot(
      this.createPokeQuery(this._pokesCollectionRef, options),
      this.constructChangeHandler(),
    );
  }

  public disableAllListeners(): void {
    usePokeScheduleStore().pokes = [];
    usePokeScheduleStore().clearScheduledProfiles();

    if (this._pokeUnsubscribeFunction) {
      this._pokeUnsubscribeFunction();
    }
  }

  public async getPokesByScheduled(payload: { scheduled: Dayjs; domains: string[] }): Promise<PokeDocument[]> {
    const scheduled = Timestamp.fromDate(payload.scheduled.toDate());
    const batches = _chunk(payload.domains, 10);

    const pokes = await Promise.all(
      batches.map(async (chunkOfDomains) => {
        const snapshot = await getDocs(
          query(
            this._pokesCollectionRef,
            where("domains", "array-contains-any", chunkOfDomains),
            where("scheduled", "==", scheduled),
            limit(1),
          ),
        );

        if (snapshot.empty) {
          return;
        }
        return {
          ...snapshot.docs[0].data(),
          id: snapshot.docs[0].id,
        } as PokeDocument;
      }),
    );

    return pokes.filter(Boolean);
  }

  public async getFollowUpPokes(pokeId: string): Promise<FollowUpPoke[]> {
    const snapshot = await getDocs(
      query(
        this._pokesCollectionRef,
        where("strategy", "==", POKE_STRATEGY_FOLLOW_UP),
        where("followUp.parentId", "==", pokeId),
      ),
    );

    if (snapshot.empty) {
      return [];
    }

    const followUps = snapshot.docs.map((documentSnapshot: QueryDocumentSnapshot) => {
      const poke = { ...documentSnapshot.data() } as PokeDocument;
      const scheduledDate = dayjs(poke.scheduled.toDate()).tz(poke.profile?.timezone || "UTC");
      return {
        pokeId: poke.id,
        poke: {
          type: "poke",
          content: poke.message.content,
          messageLength: poke.message.messageLength,
          attachment: poke.message.attachment || null,
          hasAttachment: !!poke.message.attachment,
          attachmentFilenameForLegacy: poke.message.attachmentFilenameForLegacy || null,
          scheduledDateTime: scheduledDate,
        },
        order: poke.followUp.index,
        isOpen: true,
        isValid: true,
        key: poke.followUp.index,
        minDateTime: dayjs(),
        originalDateTime: scheduledDate,
        titleDateTime: scheduledDate.format("HH:mm ddd, MMM D, YYYY"),
      } as FollowUpPoke;
    });

    return followUps.sort((a, b) => {
      return a.order > b.order ? 1 : -1;
    });
  }

  public async savePoke(poke: PokeDocument): Promise<string> {
    const pokeToSave = { ...poke }; // intentionally do a shallow copy, in order to not lose the original types of Firestore Timestamps

    if (pokeToSave.id) {
      delete pokeToSave.createdAt;

      pokeToSave.updatedAt = Timestamp.now();
      pokeToSave.updatedBy = pokeToSave.agent ?? null;
      delete pokeToSave.agent; // to keep the original poke author
    }

    const pokeReference = pokeToSave.id
      ? doc<PokeDocument>(this._pokesCollectionRef, pokeToSave.id)
      : doc<PokeDocument>(this._pokesCollectionRef);
    pokeToSave.id = pokeReference.id as PokeDocument["id"];
    pokeToSave.message.content = replaceUnicodeEmojisInString(pokeToSave.message.content);
    pokeToSave.message.attachment = extractAttachmentUrlToSave(pokeToSave.message.attachment);

    await setDoc(pokeReference, pokeToSave, { merge: true });

    return pokeToSave.id;
  }

  public async addDomainToPoke({ poke, domainName }: { poke: PokeDocument; domainName: string }) {
    const pokeReference = doc<PokeDocument>(this._pokesCollectionRef, poke.id);
    const previousDoc = await getDoc(pokeReference);
    const previousData = previousDoc.data();

    const uniqueDomains = Array.from(new Set([...poke.domains, domainName]));

    const legacyFilters = invertAllFalseValuesInOneFilterCategory(poke.filters);
    const filtersForApi = removeEmptyElements(structuredClone(legacyFilters));
    await useProfileStore().getCustomerCounts({
      origin: poke.origin.type,
      domains: uniqueDomains,
      filters: filtersForApi,
      profile: poke.profile,
      locale: poke.locale,
    });

    const estimationCountForAddedDomain =
      useProfileStore().targetsPerDomain.find((domainTargets) => domainTargets.site === domainName)?.estimate || 0;
    const targetPerDomainForNewDomain: {
      site: string;
      estimate: number;
      reached: number;
    } = {
      estimate: estimationCountForAddedDomain,
      reached: 0,
      site: domainName,
    };
    const totalEstimate =
      previousData.targets.targetsPerDomain.reduce((sum, domainEstimate) => sum + domainEstimate.estimate, 0) +
      estimationCountForAddedDomain;
    const targets = {
      estimate: totalEstimate,
      targetsPerDomain: [...previousData.targets.targetsPerDomain, targetPerDomainForNewDomain],
    };

    try {
      await setDoc(pokeReference, { domains: uniqueDomains, targets }, { merge: true });
      const updatedPoke = await getDoc<PokeDocument>(pokeReference);
      usePokeScheduleStore().updateDailyPoke({ id: updatedPoke.id, ...updatedPoke.data() } as PokeDocument);
      useNotificationStore().addNotification({
        message: `Added domain "${domainName}" to poke ${updatedPoke.id}`,
        type: NotificationTypes.Success,
      });
    } catch (error: any) {
      useNotificationStore().addNotification({
        message: `Can't add domain "${domainName}". Error: ${error}`,
        type: NotificationTypes.Error,
      });
    }
  }

  public async convertPokeToRecurring({
    poke,
    frequency,
    scheduledDateTime,
  }: {
    poke: PokeDocument;
    frequency: { day: number; limit: number };
    scheduledDateTime: Dayjs;
  }): Promise<void> {
    const pokeReference = doc<PokeDocument>(this._pokesCollectionRef, poke.id);
    const docBeforeUpdate = await getDoc(pokeReference);
    const recurringPokeDoc: PokeDocument = {
      ...docBeforeUpdate.data(),
      agent: {
        id: this._user.uid,
        name: this._user.displayName,
      },
      frequency,
      strategy: POKE_STRATEGY_RECURRING,
      scheduled: Timestamp.fromDate(scheduledDateTime.toDate()),
    };
    delete recurringPokeDoc.id; // remove id to save the copied poke as a fresh/new one
    try {
      const recurringPokeId = await this.savePoke(recurringPokeDoc);
      const recurringPokeReference = doc<PokeDocument>(this._pokesCollectionRef, recurringPokeId);
      const recurringPoke = await getDoc<PokeDocument>(recurringPokeReference);
      const takePokeProperties = mapTake(...this._pokeProperties);
      if (scheduledDateTime.isToday()) {
        usePokeScheduleStore().addDailyPoke({
          id: recurringPoke.id,
          ...takePokeProperties(recurringPoke.data()),
        } as PokeDocument);
      }
      useNotificationStore().addNotification({
        message: `Created recurring poke "${recurringPokeId}" based on the original poke "${poke.id}"`,
        type: NotificationTypes.Success,
      });
    } catch (error: any) {
      useNotificationStore().addNotification({
        message: `Can't create recurring poke. Error: ${error}`,
        type: NotificationTypes.Error,
      });
    }
  }

  public async loadPoke(pokeId: string): Promise<PokeDocument> {
    const pokeSnapshot = await getDoc(doc(this._pokesCollectionRef, pokeId));

    if (!pokeSnapshot.exists()) {
      throw new Error(`Poke document with id ${pokeId} not found`);
    }

    return {
      id: pokeSnapshot.id,
      ...pokeSnapshot.data(),
    } as PokeDocument;
  }

  public deletePoke(pokeId: string): void {
    deleteDoc(doc(this._pokesCollectionRef, pokeId));
  }

  public async getDailyPokes(filters: DailyPokeFilters): Promise<void> {
    usePokeScheduleStore().dailyPokes = [];

    const dailyPokeQuery = this.createDailyPokeQuery(filters);

    const pokes = await getDocs(dailyPokeQuery);
    for (const doc of pokes.docs) {
      usePokeScheduleStore().dailyPokes.push({
        id: doc.id as Branded<string, "PokeId">,
        ...(doc.data() as PokeDocument),
      });
    }
  }

  private createDailyPokeQuery(filters: DailyPokeFilters): Query {
    let pokeQuery: Query = query(this._pokesCollectionRef);

    if (filters.operatorId) {
      pokeQuery = query(pokeQuery, where("agent.id", "==", filters.operatorId));
    }

    if (filters.operatorName) {
      pokeQuery = query(pokeQuery, where("agent.name", "==", filters.operatorName));
    }

    const date = filters.date ? dayjs(filters.date) : dayjs();
    const startOfDay = date.startOf("day");
    const endOfDay = date.endOf("day");
    pokeQuery = query(
      pokeQuery,
      where("scheduled", ">=", startOfDay.toDate()),
      where("scheduled", "<=", endOfDay.toDate()),
    );

    return pokeQuery;
  }

  private constructChangeHandler() {
    return (snapshot: QuerySnapshot<DocumentData>) => {
      snapshot.docChanges().forEach((docChange) => {
        const takePokeProperties = mapTake(...this._pokeProperties);
        const document = {
          id: docChange.doc.id,
          ...takePokeProperties(docChange.doc.data()),
        } as PokeDocument;

        switch (docChange.type) {
          case "added":
            usePokeScheduleStore().addPoke(document);
            break;
          case "modified":
            usePokeScheduleStore().updatePoke(document);
            break;
          case "removed":
            usePokeScheduleStore().removePoke(document);
            break;
          default:
            break;
        }
      });
    };
  }

  public async hasSentPokesByProfileId({
    domains,
    profileUuid,
    days,
  }: {
    domains: string[];
    profileUuid: string;
    days: number;
  }) {
    const pokeDate = dayjs().subtract(days, "day").startOf("date").toDate();

    const batches = _chunk(domains, 10);
    await Promise.all(
      batches.map(async (chunkOfDomains) => {
        const snapshot = await getDocs(
          query(
            this._pokesCollectionRef,
            where("domains", "array-contains-any", chunkOfDomains),
            where("profile.uuid", "==", profileUuid),
            where("scheduled", ">=", pokeDate),
            where("scheduled", "<=", Timestamp.now()),
            limit(1),
          ),
        );

        if (snapshot.empty) {
          return;
        }
        const poke = {
          ...(snapshot.docs[0].data() as PokeDocument),
          id: snapshot.docs[0].id as Branded<string, "PokeId">,
        };

        usePokeScheduleStore().addPastScheduledPoke(poke);
      }),
    );
  }

  public async hasScheduledPokeByProfileId({ domains, profileUuid }: { domains: string[]; profileUuid: string }) {
    const batches = _chunk(domains, 10);
    await Promise.all(
      batches.map(async (chunkOfDomains) => {
        const snapshot = await getDocs(
          query(
            this._pokesCollectionRef,
            where("domains", "array-contains-any", chunkOfDomains),
            where("profile.uuid", "==", profileUuid),
            where("scheduled", ">", Timestamp.now()),
            limit(1),
          ),
        );
        if (snapshot.empty) {
          return;
        }
        const poke = {
          ...(snapshot.docs[0].data() as PokeDocument),
          id: snapshot.docs[0].id as Branded<string, "PokeId">,
        };

        usePokeScheduleStore().addFutureScheduledPoke(poke);
      }),
    );
  }
}
